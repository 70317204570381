import React from 'react';
import './Shipping-Delivery.css';

const ShippingDelivery = () => {
    return (
        <div className="shipping-delivery">
            <h2>Shipping & Delivery Not Available</h2>
            <p>We're sorry, but at this moment, we do not offer shipping or delivery services. Please stay tuned for future updates as we work to expand our services. Thank you for understanding.</p>
        </div>
    );
}

export default ShippingDelivery;
