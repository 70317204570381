import React, { useState, useEffect, useRef } from "react";
import "./Solution.css";
import {Link} from 'react-router-dom'
import ThoughtOfTheDay from './ThoughtOfTheDay'
import './SolutionMobile.css'
function Solution() {
    const [currentSection, setCurrentSection] = useState(0);
    const sectionsCount = 6;
    const containerRef = useRef(null);
    const solutionFirstCardRef = useRef(null);
    const solutionSecondCardRef = useRef(null);
    const solutionThirdCardRef = useRef(null);
    const solutionFourthCardRef = useRef(null);
    const solutionFifthCardRef = useRef(null);
    const solutionSixthCardRef = useRef(null);

    
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenBrand, setIsOpenBrand] = useState(false);
    const [isOpenFinance, setIsOpenFinance] = useState(false);
    const [isOpenSales, setIsOpenSales] = useState(false);
    const [isOpenDevelopment, setIsOpenDevelopment] = useState(false);
    const handleScroll = () => {
        const sectionHeight = window.innerHeight;
        const current = Math.round(containerRef.current.scrollTop / sectionHeight);
        setCurrentSection(current);
    };
   
  
    
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
    
        // Set up Intersection Observer for the Solution cards
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const card = entry.target;
                    card.classList.remove('animate');
                    void card.offsetWidth;
                    card.classList.add('animate');
                }
            });
        });

        observer.observe(solutionFirstCardRef.current);
        observer.observe(solutionSecondCardRef.current);
        observer.observe(solutionThirdCardRef.current);
        observer.observe(solutionFourthCardRef.current);
        observer.observe(solutionFifthCardRef.current);
        observer.observe(solutionSixthCardRef.current);
    
        return () => {
            container.removeEventListener("scroll", handleScroll);
            if (solutionFirstCardRef.current) {
                observer.unobserve(solutionFirstCardRef.current);
            }
            if (solutionSecondCardRef.current) {
                observer.unobserve(solutionSecondCardRef.current);
            }
            if (solutionThirdCardRef.current) {
                observer.unobserve(solutionThirdCardRef.current);
            }
            if (solutionFourthCardRef.current) {
                observer.unobserve(solutionFourthCardRef.current);
            }
            if (solutionFifthCardRef.current) {
                observer.unobserve(solutionFifthCardRef.current);
            }
            if (solutionSixthCardRef.current) {
              observer.unobserve(solutionSixthCardRef.current);
          }
        };
    }, []);
   
   

  
    
    
 
    

    

    return (
        <div className="scroll-container" ref={containerRef}>
             <div className="section Section-topmargin" >
             <div className="Solution-cardContainer"  id="SolutionArtifical">
                <div className="Solution-card" ref={solutionFirstCardRef}>
             <div className="Solution-FirstCard">
             <svg  className="Solutionrobotsvg" xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 640 512">
               
               <path className="Solutionrobotdrawsvg" d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"/></svg> 
                <h1 className="Solution-FirstCardHeader">AI: Unlocking tomorrow’s potential today!</h1>
                <svg  className="Solution-FirstCardAI" version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="541.000000pt" height="461.000000pt" viewBox="0 0 541.000000 461.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,461.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path  id="SolutionFillAISVG" className="Solution-FirstCardAIDraw " d="M2467 4316 c-63 -23 -106 -59 -132 -110 -50 -99 -18 -219 71 -276 27
-16 56 -30 66 -30 17 0 18 -22 18 -420 l0 -420 -180 0 -179 0 -3 314 -3 314
-30 43 c-50 71 -87 83 -252 87 l-142 3 -11 35 c-17 51 -58 94 -115 120 -66 31
-118 30 -184 -2 -125 -62 -156 -226 -65 -333 46 -53 106 -75 184 -68 90 8 156
61 184 150 5 15 20 17 137 17 114 0 136 -3 164 -20 64 -40 66 -52 63 -374 l-3
-289 -45 -11 c-121 -31 -190 -125 -190 -258 l0 -48 -343 0 -343 0 -13 35 c-36
101 -142 157 -253 134 -40 -9 -62 -22 -99 -59 -87 -87 -92 -202 -12 -291 52
-57 87 -73 163 -73 53 0 73 5 106 26 49 31 79 67 96 114 l12 34 343 0 343 0 0
-180 0 -180 -80 0 c-70 0 -80 2 -80 18 0 30 -50 98 -93 125 -51 33 -115 43
-173 27 -57 -16 -89 -42 -125 -99 -24 -38 -29 -55 -29 -111 0 -56 5 -73 29
-112 57 -90 181 -128 275 -83 46 22 105 88 113 125 l6 30 78 0 79 0 0 -180 0
-180 -339 0 -339 0 -21 43 c-45 92 -104 131 -201 132 -80 0 -131 -23 -171 -78
-60 -83 -62 -179 -5 -258 100 -137 308 -110 378 48 l19 43 339 0 340 0 0 -52
c0 -72 15 -120 53 -170 34 -45 122 -98 164 -98 l23 0 0 -291 c0 -280 -1 -293
-22 -330 -33 -58 -65 -69 -211 -69 l-125 0 -12 36 c-29 86 -132 151 -222 141
-126 -15 -212 -129 -192 -252 19 -118 144 -203 258 -174 67 17 118 58 146 116
l24 51 142 4 c134 4 145 6 182 31 22 14 53 43 69 63 l28 37 5 316 5 316 178 3
177 2 0 -419 0 -419 -42 -17 c-148 -58 -184 -239 -72 -352 136 -136 364 -41
364 152 0 31 -6 70 -13 88 -18 42 -85 101 -131 117 l-36 12 0 419 0 419 178
-2 177 -3 3 -148 3 -147 -25 -10 c-99 -37 -151 -110 -151 -210 0 -78 26 -131
84 -172 88 -63 205 -54 280 21 48 48 64 96 59 172 -6 81 -43 134 -121 173
l-58 29 3 146 3 146 178 3 177 2 0 -309 0 -309 33 -48 c19 -29 50 -59 77 -74
40 -22 59 -25 182 -28 l136 -4 13 -32 c17 -41 77 -105 109 -118 83 -33 156
-27 222 19 61 42 82 86 83 175 0 69 -3 80 -30 120 -44 63 -122 101 -194 94
-79 -7 -139 -47 -176 -119 l-30 -57 -126 0 c-115 0 -129 2 -160 23 -62 41 -64
52 -67 370 -3 230 -1 288 10 291 82 23 109 36 147 71 54 49 80 105 88 190 l6
65 337 0 338 0 17 -42 c87 -221 415 -160 415 77 0 121 -95 215 -217 215 -89
-1 -176 -62 -203 -144 l-12 -36 -339 0 -339 0 0 180 0 180 73 0 72 0 21 -49
c39 -87 140 -139 239 -120 175 33 233 269 93 378 -33 26 -101 51 -139 51 -77
0 -165 -62 -196 -137 l-18 -43 -72 0 -73 0 0 180 0 180 340 0 340 0 11 -27
c35 -95 107 -146 204 -146 184 0 280 200 168 351 -36 48 -95 74 -169 75 -87 1
-147 -36 -188 -116 l-29 -57 -339 0 -338 0 0 33 c0 136 -88 256 -202 275 l-43
8 -3 249 c-4 324 -1 348 48 397 l38 38 134 0 134 0 16 -34 c46 -96 126 -143
228 -133 106 9 178 82 187 188 10 101 -31 173 -122 215 -58 28 -111 30 -162 9
-60 -25 -98 -60 -124 -114 l-24 -51 -130 -1 c-146 -1 -181 -9 -230 -57 -64
-63 -65 -68 -65 -401 l0 -301 -180 0 -180 0 0 144 c0 152 2 158 50 169 14 4
41 21 59 39 95 92 94 241 -2 325 -86 75 -209 69 -292 -14 -106 -106 -75 -277
63 -343 l52 -25 0 -147 0 -148 -180 0 -180 0 2 417 3 416 33 11 c49 17 102 64
123 110 22 49 25 133 5 180 -21 51 -83 104 -139 121 -61 18 -71 18 -120 1z
m-85 -1658 c9 -7 54 -105 99 -218 44 -113 102 -256 127 -319 50 -124 49 -146
-4 -146 -27 0 -31 6 -69 100 l-41 100 -128 3 c-97 2 -131 -1 -138 -10 -4 -7
-20 -44 -34 -83 -36 -95 -50 -115 -79 -115 -54 0 -50 24 46 266 49 126 100
254 113 284 13 30 29 71 36 90 12 32 35 60 50 60 3 0 14 -6 22 -12z m894 4
c22 -15 24 -42 4 -62 -17 -17 -34 -20 -110 -20 l-90 0 0 -265 0 -264 96 -3
c86 -3 98 -5 111 -25 13 -19 12 -24 -6 -42 -20 -20 -32 -21 -239 -21 -181 0
-221 3 -240 16 -26 18 -28 37 -6 58 12 12 37 16 105 16 l89 0 0 265 0 265 -83
0 c-110 0 -155 30 -111 74 13 14 49 16 242 16 125 0 232 -4 238 -8z"/>
<path className="Solution-FirstCardAIDraw" d="M2316 2398 c-19 -51 -38 -101 -42 -110 -6 -16 2 -18 85 -18 50 0 91
4 91 9 0 5 -18 53 -40 107 -22 54 -40 99 -40 101 0 2 -4 3 -9 3 -5 0 -25 -42
-45 -92z"/>
</g>
</svg>
             </div>
             <div className="Solution-FONTENLARGE">
             <p className="Solution-FirstCardText">
        In the fast-paced realm of modern business, <strong className="Solution-goldcolorfifth" >Artificial Intelligence (AI)</strong>  isn't just a luxury—it's a <strong className="Solution-goldcolorfifth" >game-changer</strong> . 
        Our consultancy harnesses the transformative power of AI to drive innovation, streamline operations, create content, 
        and foster informed decision-making. Dive into the future with us, and witness firsthand how AI can redefine the 
        boundaries of  <strong className="Solution-goldcolorfifth" >your business potential</strong> .
    </p>
    </div>
    <div className="SolutionAIContainer">
    <button  className="SolutionFIRSTFRAMEAIBUTTON" onClick={() => setIsOpen(true)}   rel="noopener noreferrer">
        Get AI Solution for your Business <span className="Solution-AIarrow">&rsaquo;</span>   
    </button>
    {isOpen && (
                <div className="Solution-modal">
                    <div className="Solution-modal-content">
                        <span 
                            className="Solution-close-btn" 
                            onClick={() => setIsOpen(false)}
                        >
                            &times;
                        </span>
                        <div className="Solution-Phone-GmailOnly">
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                            <path  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            <a href="tel:+919990994592">
                                <span>  +919990994592</span>
                                
                            </a>
                        </div>
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:support@codingvisuals.com">
                           
                                <span>  support@codingvisuals.com</span>
                                
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
    
      </div>

            </div>
         
           
        </div>
             </div>

            <div className="section">
            <div className="Solution-cardContainer"  id="SolutionBusinessStrategy">
                <div className="Solution-card" ref={solutionSecondCardRef}>
             <div className="Solution-SecondCard">
             <svg className="Solutionranking-starsvg" xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 640 512">
                <path className="Solutionranking-stardrawsvg" d="M353.8 54.1L330.2 6.3c-3.9-8.3-16.1-8.6-20.4 0L286.2 54.1l-52.3 7.5c-9.3 1.4-13.3 12.9-6.4 19.8l38 37-9 52.1c-1.4 9.3 8.2 16.5 16.8 12.2l46.9-24.8 46.6 24.4c8.6 4.3 18.3-2.9 16.8-12.2l-9-52.1 38-36.6c6.8-6.8 2.9-18.3-6.4-19.8l-52.3-7.5zM256 256c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H256zM32 320c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H160c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zm416 96v64c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V416c0-17.7-14.3-32-32-32H480c-17.7 0-32 14.3-32 32z"/></svg>
                <h1 className="Solution-SecondCardHeader">Business strategy & Brand building</h1>
                <svg className="Solution-SecondCardAI" version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="541.000000pt" height="461.000000pt" viewBox="0 0 519.000000 480.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path className="Solution-SecondCardAIDraw" d="M2500 4774 c-141 -22 -293 -65 -383 -110 -350 -175 -591 -522 -627
-901 -21 -214 10 -412 89 -588 40 -87 126 -222 182 -283 27 -30 49 -61 49 -70
0 -9 -14 -145 -31 -301 l-32 -285 27 -23 c36 -30 46 -29 98 10 24 18 72 53
108 77 54 37 189 135 302 220 26 20 28 20 95 3 52 -13 110 -17 248 -17 163 0
189 3 278 27 375 101 689 393 797 739 78 253 81 456 9 707 -99 341 -385 632
-731 741 -155 50 -356 72 -478 54z m304 -135 c183 -33 375 -131 509 -259 101
-96 157 -170 215 -285 99 -200 132 -401 97 -610 -19 -117 -35 -167 -89 -280
-55 -117 -92 -172 -175 -259 -158 -165 -333 -260 -565 -306 -87 -18 -277 -15
-366 5 -154 34 -141 37 -268 -57 -171 -125 -260 -188 -267 -188 -3 0 -4 19 -1
43 3 23 15 129 27 236 20 188 20 195 2 217 -10 12 -45 54 -78 93 -111 132
-176 266 -216 441 -21 96 -25 275 -9 378 16 94 72 253 121 337 49 86 185 243
255 296 237 178 530 250 808 198z"/>
<path className="Solution-SecondCardAIDraw" d="M2670 4379 c-29 -12 -67 -46 -82 -73 -5 -10 -14 -73 -18 -140 -12
-178 -48 -247 -138 -261 -20 -4 -47 -10 -59 -15 l-23 -10 0 -369 0 -370 22
-15 c19 -14 78 -16 436 -16 326 0 420 3 445 14 69 28 111 112 90 180 -8 26 -7
37 8 55 24 29 34 102 19 131 -10 18 -9 30 5 61 13 27 16 54 13 93 -3 31 -2 56
2 56 12 0 40 71 40 103 0 45 -29 98 -69 124 -33 22 -43 23 -236 23 l-202 0 -5
143 c-5 161 -15 195 -71 244 -53 47 -123 63 -177 42z m113 -153 c8 -14 12 -76
12 -193 0 -128 3 -175 13 -185 10 -10 74 -14 250 -18 232 -5 237 -5 240 -26 3
-18 -3 -22 -38 -27 -98 -13 -108 -92 -17 -126 39 -15 32 -43 -13 -52 -34 -7
-70 -40 -70 -63 0 -23 31 -55 61 -61 24 -5 29 -11 27 -28 -2 -16 -12 -23 -40
-28 -49 -8 -68 -25 -68 -63 0 -35 15 -56 40 -56 9 0 25 -6 35 -14 16 -12 17
-16 6 -30 -12 -14 -56 -16 -382 -16 l-369 0 0 270 0 270 50 25 c62 31 117 86
141 139 20 47 39 170 39 255 0 32 3 60 6 64 11 10 64 -15 77 -37z"/>
<path className="Solution-SecondCardAIDraw" d="M1912 3878 c-9 -9 -12 -106 -12 -374 l0 -363 22 -15 c18 -13 52 -16
165 -16 130 0 145 2 163 20 19 19 20 33 20 373 0 330 -1 355 -18 370 -16 14
-43 17 -173 17 -107 0 -159 -4 -167 -12z m228 -373 l0 -265 -60 0 -60 0 0 265
0 265 60 0 60 0 0 -265z"/>
<path className="Solution-SecondCardAIDraw" d="M795 4349 c-105 -20 -233 -70 -302 -118 -328 -231 -436 -645 -258
-990 90 -175 226 -298 400 -365 117 -44 221 -61 340 -53 l94 6 29 -52 c16 -29
59 -104 97 -167 38 -63 92 -158 120 -210 52 -94 53 -95 93 -98 34 -3 44 1 56
20 14 21 11 51 -29 338 l-44 315 75 76 c63 66 74 82 74 112 0 40 -19 60 -56
60 -18 0 -43 -18 -87 -64 -34 -35 -77 -75 -96 -89 -41 -31 -41 -38 -17 -222
10 -76 16 -142 14 -148 -2 -6 -35 45 -74 112 -92 160 -93 160 -212 140 -84
-14 -102 -14 -189 1 -235 38 -437 204 -515 425 -26 72 -36 250 -19 348 39 227
247 436 491 494 126 31 304 8 425 -52 49 -25 161 -107 194 -142 49 -53 111
-20 101 54 -3 25 -80 99 -160 153 -146 99 -373 147 -545 116z"/>
<path className="Solution-SecondCardAIDraw" d="M4195 4345 c-160 -29 -281 -90 -397 -203 -73 -70 -85 -103 -50 -135
32 -30 70 -21 110 24 69 77 201 155 313 185 94 24 245 22 334 -6 186 -59 325
-175 401 -335 47 -99 64 -174 64 -285 0 -261 -136 -473 -376 -587 -89 -43
-215 -66 -305 -58 -36 3 -73 3 -82 -1 -15 -5 -107 -144 -107 -161 0 -12 -52
-94 -57 -90 -2 3 3 66 13 140 10 73 15 141 12 149 -4 8 -45 41 -92 72 -47 31
-106 81 -132 111 -36 43 -53 55 -75 55 -34 0 -69 -31 -69 -62 0 -21 121 -149
202 -214 l37 -29 -18 -135 c-62 -459 -62 -480 2 -480 20 0 40 7 49 18 13 14
192 320 272 464 l21 38 68 0 c177 0 373 74 506 191 103 91 201 247 237 380 25
89 25 309 0 399 -61 224 -262 445 -470 517 -151 52 -274 63 -411 38z"/>
<path className="Solution-SecondCardAIDraw" d="M640 3944 c-8 -3 -41 -17 -72 -31 -128 -59 -185 -228 -122 -362 19
-43 69 -100 222 -254 108 -109 209 -205 223 -212 22 -12 30 -12 55 1 43 22
421 410 447 458 33 63 31 183 -5 252 -81 155 -281 199 -426 94 l-42 -30 -42
30 c-65 47 -178 73 -238 54z m125 -133 c22 -10 60 -39 85 -65 27 -28 53 -46
67 -46 14 0 46 21 82 54 71 65 118 83 178 67 51 -14 108 -74 117 -124 14 -76
-1 -100 -194 -292 l-180 -180 -180 180 c-192 192 -209 217 -196 290 9 45 68
112 110 124 47 14 67 12 111 -8z"/>
<path className="Solution-SecondCardAIDraw" d="M3880 3875 c-7 -9 -13 -29 -13 -45 0 -47 19 -61 77 -58 43 3 53 7 66
31 13 24 13 30 0 55 -13 23 -23 27 -66 30 -37 2 -54 -1 -64 -13z"/>
<path className="Solution-SecondCardAIDraw" d="M4112 3878 c-16 -16 -16 -80 0 -96 9 -9 97 -12 333 -12 299 0 323 1
338 18 22 24 21 65 -1 85 -17 15 -55 17 -338 17 -236 0 -323 -3 -332 -12z"/>
<path className="Solution-SecondCardAIDraw" d="M3880 3634 c-8 -8 -13 -28 -13 -44 0 -62 -17 -60 464 -60 480 0 469
-1 469 59 0 62 7 61 -472 61 -382 0 -437 -2 -448 -16z"/>
<path className="Solution-SecondCardAIDraw" d="M3894 3402 c-40 -27 -35 -96 8 -107 13 -3 164 -5 336 -3 339 3 332 2
332 59 0 58 -7 59 -351 59 -173 0 -319 -4 -325 -8z"/>
<path className="Solution-SecondCardAIDraw" d="M4677 3392 c-22 -24 -22 -66 0 -87 20 -20 100 -16 114 6 15 23 10 65
-9 82 -25 23 -85 22 -105 -1z"/>
<path className="Solution-SecondCardAIDraw" d="M2485 2304 c-117 -18 -225 -81 -325 -191 -66 -70 -117 -186 -133
-301 -25 -180 77 -420 218 -508 25 -16 45 -31 45 -34 0 -3 -26 -15 -58 -28
-70 -27 -197 -109 -268 -175 -113 -103 -223 -283 -259 -423 -8 -33 -19 -103
-24 -158 -12 -136 3 -192 59 -221 25 -13 136 -15 840 -15 890 0 859 -2 898 59
24 35 20 208 -7 313 -69 268 -274 509 -526 616 l-67 29 60 46 c144 111 228
320 202 503 -16 122 -62 225 -132 300 -126 134 -227 183 -393 189 -55 2 -113
1 -130 -1z m176 -124 c85 -16 193 -75 244 -134 86 -101 120 -201 113 -324 -7
-99 -35 -172 -96 -245 -171 -208 -463 -221 -648 -29 -30 31 -69 86 -87 122
-30 62 -32 71 -32 180 0 110 1 118 33 180 65 130 165 212 298 244 73 18 106
19 175 6z m108 -1015 c152 -36 277 -111 395 -235 121 -130 190 -289 203 -473
l6 -87 -788 0 -788 0 6 88 c8 113 28 185 84 302 40 84 57 106 147 195 84 84
115 107 186 141 101 49 139 62 234 80 83 16 220 11 315 -11z"/>
<path className="Solution-SecondCardAIDraw" d="M1285 2109 c-84 -12 -140 -35 -211 -85 -143 -101 -214 -241 -214
-421 0 -166 76 -319 204 -409 l28 -20 -86 -42 c-48 -23 -113 -62 -146 -87
-190 -146 -320 -401 -320 -630 0 -81 12 -111 59 -142 l34 -23 483 0 c471 0
485 1 504 20 25 25 25 55 0 80 -19 19 -33 20 -485 20 l-465 0 0 54 c0 169 80
348 212 473 231 217 547 258 827 106 72 -39 86 -44 107 -34 13 6 29 22 36 36
19 43 -1 66 -104 117 l-95 48 71 73 c162 167 200 381 105 583 -35 75 -129 178
-200 221 -89 53 -230 79 -344 62z m255 -159 c88 -48 133 -94 172 -176 30 -64
33 -77 33 -169 0 -88 -3 -107 -27 -158 -35 -73 -68 -113 -129 -154 -81 -55
-124 -68 -225 -68 -84 0 -95 3 -166 37 -89 45 -138 95 -181 186 -96 206 8 446
228 525 38 13 72 17 140 14 80 -3 97 -7 155 -37z"/>
<path className="Solution-SecondCardAIDraw" d="M3715 2109 c-166 -24 -309 -131 -384 -288 l-36 -76 0 -140 0 -140 37
-75 c26 -54 57 -95 108 -147 l72 -72 -83 -41 c-46 -23 -92 -49 -101 -58 -23
-20 -23 -64 0 -85 28 -26 65 -21 129 17 431 252 997 -50 1039 -554 l7 -80
-465 0 c-438 0 -466 -1 -481 -18 -22 -24 -21 -65 1 -85 17 -15 66 -17 493 -17
452 0 475 1 506 20 59 36 66 58 60 178 -6 127 -30 210 -95 337 -38 74 -65 108
-147 190 -87 87 -112 106 -198 148 l-97 47 74 74 c87 87 122 154 143 270 11
65 12 98 3 166 -16 119 -54 195 -145 285 -89 90 -165 128 -284 144 -46 6 -87
10 -90 10 -3 -1 -33 -5 -66 -10z m249 -158 c87 -42 135 -90 177 -177 44 -89
53 -208 21 -291 -65 -172 -218 -273 -394 -261 -87 7 -199 59 -253 119 -159
176 -129 455 62 580 85 56 131 69 233 66 74 -2 95 -6 154 -36z"/>
</g>
</svg>

             </div>
             <div className="Solution-FONTENLARGE">
             <p className="Solution-SecondCardText">
             "In the ever-evolving world of <strong className="Solution-goldcolorfifth">business</strong> , a potent strategy and a distinct <strong className="Solution-goldcolorfifth ">brand identity</strong>  are <strong className="Solution-goldcolorfifth">indispensable</strong> . At <strong className="Solution-goldcolorfifth">Coding Visuals</strong>  , we craft bespoke business strategies tailored to <strong className="Solution-goldcolorfifth">your unique vision</strong> , ensuring you remain a step ahead of <strong className="Solution-goldcolorfifth">market dynamics</strong> . Our brand building expertise transforms your company into a memorable narrative, positioning you as a leader in your industry. <strong className="Solution-goldcolorfifth">Partner with us</strong> , and let's co-create a legacy of excellence and innovation that sets you apart."
    </p>
    </div>
    <div className="SolutionAIContainer">
    <button  className="SolutionSECONDFRAMEBUSINESSBUTTON"  onClick={() => setIsOpenBrand(true)} rel="noopener noreferrer">
        Get Business Strategy For Your Business <span className="Solution-BUSINESSarrow">&rsaquo;</span>
    </button>
    {isOpenBrand && (
                <div className="Solution-modal">
                    <div className="Solution-modal-content">
                        <span 
                            className="Solution-close-btn" 
                            onClick={() => setIsOpenBrand(false)}
                        >
                            &times;
                        </span>
                        <div className="Solution-Phone-GmailOnly">
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                            <path  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            <a href="tel:+919990994592">
                                <span>  +919990994592</span>
                                
                            </a>
                        </div>
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:support@codingvisuals.com">
                           
                                <span>  support@codingvisuals.com</span>
                                
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        
      </div>

            </div>
         
           
        </div>
            </div>

            <div className="section" >
            <div className="Solution-cardContainer"  id="SolutionFinance">
                <div className="Solution-card" ref={solutionThirdCardRef}>
             <div className="Solution-ThirdCard">
             <svg className="Solutiondollarbagsvg" xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 512 512">
                <path className="Solutiondollarbagdrawsvg" d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z"/></svg>
                <h1 className="Solution-ThirdCardHeader">Finance & Technology consulting</h1>
                <svg className="Solution-ThirdCardAI" version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="541.000000pt" height="461.000000pt" viewBox="0 0 515.000000 485.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,485.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path className="Solution-ThirdCardAIDraw" d="M2850 4580 c-27 -27 -25 -64 5 -88 l25 -20 0 -721 c0 -634 -2 -721
-15 -721 -12 0 -15 -13 -15 -55 l0 -55 -70 0 -70 0 0 55 c0 49 -2 55 -20 55
-20 0 -20 4 -16 388 2 213 6 405 7 426 4 35 0 42 -22 55 -24 13 -29 13 -53 -2
-42 -28 -28 -107 20 -107 12 0 14 -57 14 -380 l0 -380 -30 0 c-30 0 -30 -1
-30 -55 l0 -55 -65 0 -65 0 0 54 c0 54 0 55 -32 58 l-32 3 2 243 1 242 -184 0
-185 0 0 261 c0 168 4 268 11 281 24 45 -17 96 -67 84 -50 -13 -56 -86 -9
-108 l25 -11 0 -269 0 -268 190 0 190 0 0 -226 c0 -217 -1 -226 -20 -231 -17
-5 -20 -14 -20 -59 l0 -54 -70 0 -70 0 0 54 c0 45 -3 54 -20 59 -19 5 -20 14
-20 141 l0 136 -209 0 c-173 0 -211 3 -221 15 -16 19 -74 19 -89 1 -7 -8 -11
-30 -9 -48 6 -61 78 -71 106 -15 l13 27 185 0 184 0 0 -130 0 -130 -25 0 c-24
0 -25 -3 -25 -55 l0 -55 -70 0 -70 0 0 55 c0 30 -4 55 -10 55 -6 0 -10 27 -10
60 l0 60 -372 3 c-205 2 -386 7 -401 11 -21 6 -33 4 -47 -9 -39 -35 -14 -105
37 -105 21 0 53 29 53 48 0 9 88 12 345 12 l345 0 0 -40 c0 -39 -1 -40 -35
-40 l-35 0 0 -55 0 -55 -80 0 -80 0 0 -115 0 -115 -55 0 c-42 0 -55 -3 -55
-15 0 -13 -46 -15 -356 -15 -294 0 -359 2 -368 14 -22 28 -54 29 -81 1 -26
-26 -27 -52 -3 -82 16 -20 65 -15 86 7 18 20 29 20 368 18 l349 -3 3 -27 c3
-27 6 -28 58 -28 l54 0 0 -70 0 -70 -55 0 c-52 0 -55 -1 -55 -25 l0 -25 -550
0 -550 0 0 319 c0 271 2 320 15 331 20 16 19 64 -1 84 -18 19 -65 21 -82 4
-19 -19 -14 -73 8 -93 19 -17 20 -31 20 -352 l0 -333 570 0 c563 0 570 0 570
-20 0 -18 7 -20 55 -20 l55 0 0 -70 0 -70 -55 0 c-42 0 -55 -3 -55 -15 0 -13
-46 -15 -353 -15 -261 0 -356 -3 -365 -12 -9 -9 -12 -81 -12 -264 l0 -252 -25
-16 c-28 -18 -35 -72 -13 -94 17 -17 64 -15 82 4 18 17 22 69 6 79 -6 4 -10
103 -10 266 l0 259 345 0 345 0 0 -35 0 -35 55 0 55 0 0 -95 0 -95 100 0 100
0 0 -55 c0 -42 3 -55 15 -55 13 0 15 -36 15 -268 0 -195 -3 -271 -12 -280 -9
-9 -73 -12 -228 -12 -204 0 -218 1 -235 20 -20 22 -46 25 -76 10 -25 -14 -26
-71 -1 -93 26 -24 49 -21 78 8 l25 25 220 0 c185 0 224 3 244 16 l25 16 0 279
0 279 30 0 c30 0 30 1 30 55 l0 55 69 0 68 0 6 -82 c4 -46 7 -351 7 -679 0
-535 -2 -597 -16 -603 -9 -3 -91 -6 -183 -6 -157 0 -169 1 -186 20 -27 29 -75
23 -94 -11 -13 -24 -13 -29 2 -53 22 -33 71 -36 89 -6 12 19 24 20 198 20 160
0 188 3 207 18 l23 17 2 625 3 625 38 3 37 3 0 55 0 54 70 0 70 0 0 -55 c0
-42 3 -55 15 -55 13 0 15 -64 15 -515 l0 -516 -26 -23 c-46 -39 -30 -106 26
-106 39 0 65 32 54 67 -4 15 -9 268 -11 561 l-3 532 24 0 c23 0 25 4 28 53 l3
52 68 3 67 3 0 -55 c0 -52 1 -55 28 -58 l27 -3 0 -680 0 -680 -225 -3 c-151
-2 -234 1 -252 9 -40 17 -78 -10 -78 -54 0 -40 15 -57 49 -57 32 0 45 8 60 38
12 22 14 22 235 22 203 0 224 2 239 18 16 17 17 80 17 705 0 604 2 687 15 687
12 0 15 13 15 55 l0 55 70 0 70 0 0 -55 0 -55 65 0 65 0 0 55 0 55 65 0 65 0
0 -55 0 -55 65 0 65 0 0 55 0 55 70 0 70 0 0 -55 0 -55 65 0 65 0 0 55 0 55
130 0 130 0 0 165 0 165 56 0 c47 0 57 3 61 20 5 19 14 20 229 20 l224 0 0
-258 c0 -246 -1 -260 -20 -277 -22 -20 -27 -74 -8 -93 16 -16 70 -16 86 0 20
20 15 76 -8 90 -19 12 -20 24 -20 295 l0 283 -237 2 c-237 3 -238 3 -241 25
-3 19 -10 22 -60 25 l-57 3 0 65 0 65 58 3 c56 3 57 4 57 32 l0 30 273 0 c264
0 273 -1 285 -21 20 -32 83 -23 96 13 15 44 -6 78 -48 78 -18 0 -39 -7 -46
-15 -11 -13 -55 -15 -286 -15 -237 0 -274 2 -274 15 0 12 -13 15 -60 15 l-60
0 0 180 0 180 -149 0 -148 0 -6 83 c-4 45 -7 209 -7 365 l0 282 309 0 c266 0
310 -2 315 -15 3 -8 17 -20 30 -27 55 -24 103 53 60 96 -20 20 -68 21 -84 0
-12 -13 -54 -15 -337 -12 l-323 3 0 -332 0 -333 -45 0 -45 0 0 -55 0 -56 -67
3 -68 3 -3 53 c-2 37 -7 52 -18 52 -12 0 -14 75 -14 540 l0 540 250 0 250 0 0
154 c0 124 3 156 15 166 25 20 19 77 -9 96 -22 13 -29 14 -53 2 -40 -19 -44
-69 -8 -102 25 -24 26 -25 23 -152 l-3 -129 -252 -3 -253 -2 0 -555 0 -555
-24 0 c-23 0 -25 -4 -28 -52 l-3 -53 -67 -3 -68 -3 0 55 c0 52 -1 55 -27 58
l-28 3 -3 715 c-2 590 0 721 12 750 16 40 7 73 -23 89 -28 15 -38 14 -61 -9z
m-239 -1753 c-17 -17 -13 -184 5 -242 17 -57 12 -122 -15 -173 -17 -34 -17
-34 11 -62 28 -28 28 -29 28 -157 0 -97 -4 -134 -15 -149 -8 -12 -11 -24 -8
-28 3 -3 140 -6 303 -6 217 0 299 3 308 12 18 18 17 797 -1 804 -7 2 -141 6
-298 7 -261 2 -284 3 -287 20 -3 16 16 17 291 17 283 0 295 -1 321 -21 l26
-20 0 -405 c0 -391 -1 -405 -20 -424 -19 -19 -33 -20 -343 -20 -226 0 -326 3
-334 11 -9 9 -20 9 -44 0 -48 -16 -208 -14 -260 4 -85 30 -89 40 -89 193 0
110 3 139 16 153 15 16 14 22 -11 73 -52 107 -21 229 74 293 75 51 187 52 253
3 15 -11 30 -20 33 -20 3 0 5 31 5 69 0 64 2 71 28 91 24 20 27 20 30 5 2 -9
-1 -22 -7 -28z m569 -147 l0 -90 -259 0 c-294 0 -271 -8 -271 90 0 98 -23 90
271 90 l259 0 0 -90z m-370 -190 l0 -70 -75 0 -75 0 0 70 0 70 75 0 75 0 0
-70z m185 0 l0 -65 -75 0 -75 0 -3 54 c-5 81 -3 82 80 79 l73 -3 0 -65z m185
0 l0 -70 -75 0 -75 0 0 63 c0 35 3 67 7 70 3 4 37 7 75 7 l68 0 0 -70z m-372
-172 l3 -68 -76 0 -75 0 0 63 c0 35 3 67 7 71 4 4 37 6 73 4 l65 -3 3 -67z
m180 63 c9 -5 12 -26 10 -67 l-3 -59 -75 0 -75 0 -3 59 c-4 70 2 76 78 76 30
0 61 -4 68 -9z m190 -63 l3 -68 -76 0 -75 0 0 63 c0 35 3 67 7 71 4 4 37 6 73
4 l65 -3 3 -67z m-370 -175 l-3 -68 -70 0 -70 0 -3 68 -3 67 76 0 76 0 -3 -67z
m187 -3 l0 -65 -75 0 -75 0 -3 54 c-5 81 -3 82 80 79 l73 -3 0 -65z m183 3
l-3 -68 -70 0 -70 0 -3 68 -3 67 76 0 76 0 -3 -67z"/>
<path className="Solution-ThirdCardAIDraw" d="M2315 2686 c-75 -38 -100 -82 -100 -172 0 -66 3 -76 31 -111 41 -51
87 -73 153 -73 188 0 257 243 100 347 -58 38 -121 41 -184 9z m123 -50 c22
-22 33 -41 30 -55 -4 -28 -20 -29 -33 -2 -13 29 -43 37 -63 17 -22 -21 -6 -47
35 -61 74 -25 85 -80 29 -139 -32 -35 -56 -46 -56 -27 0 5 -14 23 -31 40 -30
31 -35 61 -12 68 7 3 17 -7 23 -21 11 -30 57 -37 67 -10 9 24 -9 47 -45 56
-41 11 -66 49 -57 85 6 28 51 80 70 82 6 0 25 -15 43 -33z"/>
<path className="Solution-ThirdCardAIDraw" d="M2500 2314 c-59 -32 -142 -33 -205 -2 -33 16 -48 19 -56 11 -35 -35
36 -74 146 -81 135 -8 245 37 199 82 -19 20 -29 19 -84 -10z"/>
<path className="Solution-ThirdCardAIDraw" d="M2232 2201 c3 -33 7 -37 53 -54 39 -15 70 -18 145 -15 118 4 163 25
168 77 3 29 1 33 -15 26 -96 -40 -214 -46 -301 -16 l-53 18 3 -36z"/>
<path className="Solution-ThirdCardAIDraw" d="M2230 2095 c0 -33 4 -38 43 -55 61 -26 133 -33 210 -20 79 14 117 40
117 79 l0 28 -57 -20 c-72 -24 -228 -20 -280 7 l-33 17 0 -36z"/>
<path className="Solution-ThirdCardAIDraw" d="M2700 2680 l0 -50 223 0 223 0 0 50 -1 50 -222 0 -223 0 0 -50z m428
12 c4 -25 -15 -55 -29 -46 -5 3 -9 21 -9 40 0 28 4 35 18 32 9 -2 18 -14 20
-26z"/>
<path className="Solution-ThirdCardAIDraw" d="M2700 2490 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M2880 2490 c0 -28 2 -30 40 -30 38 0 40 2 40 30 0 28 -2 30 -40 30
-38 0 -40 -2 -40 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M3070 2490 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M2700 2315 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35
-33 0 -35 -2 -35 -35z"/>
<path className="Solution-ThirdCardAIDraw" d="M2882 2318 c3 -30 6 -33 38 -33 32 0 35 3 38 33 3 31 3 32 -38 32
-41 0 -41 -1 -38 -32z"/>
<path className="Solution-ThirdCardAIDraw" d="M3070 2315 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35
-33 0 -35 -2 -35 -35z"/>
<path className="Solution-ThirdCardAIDraw" d="M2700 2140 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M2880 2140 c0 -28 2 -30 40 -30 38 0 40 2 40 30 0 28 -2 30 -40 30
-38 0 -40 -2 -40 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M3070 2140 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path className="Solution-ThirdCardAIDraw" d="M2930 1237 l0 -464 -25 -11 c-57 -26 -34 -116 28 -110 22 2 36 11 47
31 14 25 13 30 -2 58 -16 28 -18 71 -18 495 0 406 -2 464 -15 464 -13 0 -15
-58 -15 -463z"/>
<path className="Solution-ThirdCardAIDraw" d="M3220 1185 l0 -515 293 0 c281 0 293 -1 305 -20 15 -24 64 -27 86 -4
25 25 20 71 -10 91 -25 16 -28 16 -56 0 -26 -15 -64 -17 -304 -17 l-274 0 0
490 c0 483 0 490 -20 490 -20 0 -20 -7 -20 -515z"/>
<path className="Solution-ThirdCardAIDraw" d="M3470 1335 l0 -365 311 0 312 0 23 -25 c28 -30 57 -32 84 -5 24 24
25 48 4 78 -19 28 -65 29 -89 2 -17 -19 -31 -20 -317 -20 l-298 0 0 350 c0
304 -2 350 -15 350 -13 0 -15 -47 -15 -365z"/>
</g>
</svg>
             </div>
             <div className="Solution-FONTENLARGE">
             <p className="Solution-ThirdCardText">
             In today's fast-paced <strong className="Solution-goldcolorfifth">business environment</strong> , mastering <strong className="Solution-goldcolorfifth">finance</strong>  and  <strong className="Solution-goldcolorfifth">technology</strong>  is <strong className="Solution-goldcolorfifth">paramount</strong> . At Coding Visuals , we not only guide clients through the intricacies of financial management but also assist in crafting a diverse investment portfolio to safeguard and grow their assets. Coupled with this, our expertise ensures your business seamlessly integrates the latest technologies, streamlining operations and positioning you at the forefront of innovation. Trust us to be your financial beacon and technological ally, propelling your business into a prosperous future.


    </p>
    </div>
    <div className="SolutionAIContainer">
    <button  className=" SolutionTHIRDFRAMEFINANCEBUTTON "  onClick={() => setIsOpenFinance(true)} rel="noopener noreferrer">
        Get Finance & Technology consulting  <span className="Solution-FINANCEarrow">&rsaquo;</span>
    </button>
    {isOpenFinance && (
                <div className="Solution-modal">
                    <div className="Solution-modal-content">
                        <span 
                            className="Solution-close-btn" 
                            onClick={() => setIsOpenFinance(false)}
                        >
                            &times;
                        </span>
                        <div className="Solution-Phone-GmailOnly">
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                            <path  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            <a href="tel:+919990994592">
                                <span>  +919990994592</span>
                                
                            </a>
                        </div>
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:support@codingvisuals.com">
                           
                                <span>  support@codingvisuals.com</span>
                                
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        
      </div>

            </div>
         
           
        </div>
            </div>

            <div className="section">
            <div className="Solution-cardContainer"  id="SolutionSalesCoaching">
                <div className="Solution-card" ref={solutionFourthCardRef}>
             <div className="Solution-FourthCard">
             <svg className="SolutionBusinessbagsvg" xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 640 512">
                <path className="SolutionBusinessbagdrawsvg" d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 352h8.2c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM320 352H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H360.2C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z"/></svg>
                <h1 className="Solution-FourthCardHeader">Sales Coaching & Employee <br/> Coaching</h1>
                <svg className="Solution-FourthCardAI" version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="541.000000pt" height="461.000000pt" viewBox="0 0 535.000000 467.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,467.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path className="Solution-FourthCardAIDraw" d="M580 4479 c-55 -25 -95 -62 -123 -114 l-22 -40 0 -1090 0 -1090 22
-40 c28 -52 68 -89 123 -114 44 -20 63 -21 641 -21 588 0 597 0 623 21 35 27
36 86 3 117 -23 21 -25 22 -615 24 l-591 3 -23 23 -23 23 0 1055 0 1056 28 24
28 24 2060 0 2061 0 29 -29 29 -29 0 -1047 0 -1047 -29 -29 -29 -29 -591 0
-590 0 -20 -26 c-28 -36 -27 -79 4 -110 l25 -24 607 2 c574 3 610 4 646 22 49
24 92 67 120 120 l22 41 3 1073 2 1072 -21 51 c-26 59 -91 121 -147 137 -30 9
-554 12 -2123 12 -2075 0 -2084 -1 -2129 -21z"/>
<path className="Solution-FourthCardAIDraw" d="M1090 4010 c-29 -29 -27 -90 3 -118 l23 -22 591 0 592 0 20 26 c27
34 27 74 0 108 l-20 26 -595 0 c-581 0 -594 0 -614 -20z"/>
<path className="Solution-FourthCardAIDraw" d="M3103 4015 c-42 -30 -34 -121 12 -139 9 -3 279 -6 600 -6 557 0 584
1 606 19 33 27 33 95 0 122 -22 18 -49 19 -610 19 -505 0 -590 -2 -608 -15z"/>
<path className="Solution-FourthCardAIDraw" d="M1113 3468 c-46 -22 -48 -114 -3 -138 12 -6 192 -10 463 -10 l444 0
21 23 c32 34 30 89 -4 116 -26 21 -36 21 -463 20 -286 0 -444 -4 -458 -11z"/>
<path className="Solution-FourthCardAIDraw" d="M3396 3459 c-33 -26 -37 -88 -6 -119 19 -19 33 -20 464 -20 244 0
452 3 461 6 8 4 23 20 31 36 12 24 13 36 4 64 -6 19 -20 39 -30 44 -12 6 -187
10 -458 10 -429 0 -440 0 -466 -21z"/>
<path className="Solution-FourthCardAIDraw" d="M2575 3231 c-112 -40 -217 -138 -256 -239 -41 -108 -36 -258 13 -356
l19 -38 -54 -52 c-65 -61 -99 -113 -127 -195 -17 -53 -20 -86 -20 -264 0 -189
1 -205 19 -221 29 -26 87 -25 114 2 21 21 22 32 27 225 5 190 7 205 30 250 13
26 46 68 73 93 45 43 49 45 70 31 12 -7 33 -17 47 -20 14 -3 52 -12 85 -21 74
-19 124 -19 195 -2 69 17 89 25 121 47 26 18 27 18 68 -11 43 -31 79 -81 101
-140 8 -22 14 -106 18 -233 4 -196 4 -197 30 -217 28 -23 50 -25 86 -9 43 20
49 53 44 251 -4 161 -7 190 -28 243 -30 79 -90 164 -144 204 l-44 33 30 63
c104 223 -21 501 -259 575 -82 25 -190 26 -258 1z m226 -156 c103 -35 169
-129 169 -240 -1 -144 -111 -257 -252 -257 -74 0 -173 44 -211 94 -60 78 -73
179 -33 263 60 126 196 184 327 140z"/>
<path className="Solution-FourthCardAIDraw" d="M1123 2933 c-52 -10 -73 -102 -32 -139 20 -18 43 -19 358 -22 185 -2
346 0 359 3 58 14 82 88 43 136 l-19 24 -344 1 c-188 1 -353 0 -365 -3z"/>
<path className="Solution-FourthCardAIDraw" d="M3601 2929 c-73 -30 -66 -135 11 -154 13 -3 175 -5 360 -3 334 3 338
3 360 25 30 30 30 86 0 116 -22 22 -26 22 -365 24 -208 1 -351 -2 -366 -8z"/>
<path className="Solution-FourthCardAIDraw"  d="M732 1689 c-97 -16 -196 -80 -264 -171 -84 -111 -102 -286 -42 -417
l27 -63 -45 -38 c-59 -51 -112 -131 -138 -208 -18 -56 -20 -83 -18 -265 l3
-202 69 -3 c49 -2 72 1 77 10 5 7 9 92 9 189 0 212 10 254 76 328 64 72 77 77
120 51 105 -65 304 -65 410 0 l34 22 34 -21 c40 -25 77 -71 102 -127 14 -32
19 -78 23 -244 l6 -205 69 -3 c49 -2 72 1 77 10 12 19 11 364 -2 417 -17 75
-62 153 -128 223 l-64 68 29 63 c26 56 29 73 29 167 0 97 -2 110 -31 171 -86
181 -269 279 -462 248z m193 -188 c91 -40 144 -125 145 -232 0 -144 -111 -259
-251 -259 -89 0 -201 66 -239 140 -111 217 118 450 345 351z"/>
<path className="Solution-FourthCardAIDraw" d="M2014 1690 c-77 -11 -158 -52 -223 -115 -128 -122 -164 -299 -96
-469 l26 -65 -60 -60 c-44 -42 -72 -81 -98 -137 l-38 -79 0 -220 0 -220 72 -3
c68 -3 73 -2 77 20 3 13 7 106 8 208 3 178 4 187 30 235 23 45 106 135 124
135 4 0 22 -9 41 -21 104 -64 303 -64 409 1 l34 22 36 -23 c20 -12 49 -40 65
-62 49 -68 59 -121 59 -316 0 -97 4 -182 9 -189 5 -9 28 -12 77 -10 l69 3 3
180 c1 99 -2 202 -8 230 -18 85 -65 172 -131 241 l-62 64 29 62 c26 57 29 73
29 168 0 97 -2 110 -32 172 -83 177 -261 274 -449 248z m183 -196 c189 -91
184 -360 -9 -460 -75 -39 -194 -23 -276 36 -86 62 -120 192 -75 291 67 149
214 203 360 133z"/>
<path className="Solution-FourthCardAIDraw" d="M3272 1690 c-71 -10 -151 -51 -217 -112 -131 -119 -169 -327 -90
-492 l24 -49 -27 -21 c-81 -62 -141 -152 -167 -251 -16 -65 -22 -409 -6 -433
5 -9 28 -12 77 -10 l69 3 5 200 c5 160 9 208 23 239 23 52 59 99 102 132 l35
26 37 -22 c108 -64 324 -64 414 1 15 10 31 19 36 19 22 0 96 -76 121 -124 26
-50 27 -56 30 -241 1 -104 5 -200 7 -213 5 -22 10 -23 78 -20 l72 3 0 225 0
225 -34 70 c-22 46 -56 92 -97 133 l-63 63 24 52 c140 306 -116 644 -453 597z
m195 -196 c64 -31 109 -82 128 -148 19 -62 19 -90 0 -152 -40 -135 -180 -210
-316 -170 -121 35 -189 123 -189 243 0 76 16 117 64 171 82 91 197 111 313 56z"/>
<path className="Solution-FourthCardAIDraw" d="M4542 1690 c-76 -11 -159 -55 -223 -119 -101 -102 -144 -241 -115
-378 8 -39 24 -89 35 -110 l20 -40 -68 -69 c-72 -74 -111 -144 -130 -232 -13
-61 -15 -391 -2 -410 5 -9 28 -12 77 -10 l69 3 6 195 c4 121 11 208 19 230 19
52 66 116 106 146 l35 27 40 -23 c117 -65 318 -64 412 2 25 18 32 19 54 7 41
-22 100 -96 117 -146 12 -35 16 -89 16 -222 0 -97 3 -186 6 -198 6 -21 12 -23
79 -23 89 0 85 -9 85 190 0 237 -39 357 -150 471 l-59 60 25 53 c140 307 -116
643 -454 596z m173 -186 c174 -73 207 -302 61 -431 -99 -87 -260 -79 -355 19
-191 195 40 518 294 412z"/>
</g>
</svg>
      </div>
      <div className="Solution-FONTENLARGE">
             <p className="Solution-FourthCardText">
             At <strong className="Solution-goldcolorfifth">Coding Visuals</strong> , we believe that exceptional performance is birthed from exceptional understanding. Our <strong className="Solution-goldcolorfifth">coaching programs</strong>  delve deep into <strong className="Solution-goldcolorfifth">sales</strong>  and  <strong className="Solution-goldcolorfifth">consumer psychology</strong> , arming your team with insights that transcend conventional tactics. Whether it's elevating your sales force's acumen or fostering a culture of continual learning among employees, our tailored coaching sessions empower individuals to act with purpose and precision. Transform your team's potential into palpable results with Coding Visuals, where expertise meets empowerment.
       

    </p>
    </div>
    <div className="SolutionAIContainer">
         <button className="SolutionFOURTHFRAMESALESBUTTON"    onClick={() => setIsOpenSales(true)}rel="noopener noreferrer">
        Book Your Session Now <span className="Solution-SALESarrow">&rsaquo;</span>
        </button>
        {isOpenSales && (
                <div className="Solution-modal">
                    <div className="Solution-modal-content">
                        <span 
                            className="Solution-close-btn" 
                            onClick={() => setIsOpenSales(false)}
                        >
                            &times;
                        </span>
                        <div className="Solution-Phone-GmailOnly">
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                            <path  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            <a href="tel:+919990994592">
                                <span>  +919990994592</span>
                                
                            </a>
                        </div>
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:support@codingvisuals.com">
                           
                                <span>  support@codingvisuals.com</span>
                                
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        
      </div>

            </div>
         
           
        </div>
</div>
<div className="section" >
<div className="Solution-cardContainer"  id="SolutionMarketingDevelopment"> 
                <div className="Solution-card" ref={solutionFifthCardRef}>
             <div className="Solution-FifthCard">
             <svg className="SolutionCodingsvg" xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 640 512">
                <path className="SolutionCodingdrawsvg" d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
                <h1 className="Solution-FifthCardHeader">  MARKETING/Development  SERVICES</h1>
                
                <svg   className='Solution-FifthCardAI' version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="541.000000pt" height="461.000000pt" viewBox="0 0 546.000000 457.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,457.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path className="Solution-FifthCardAIDraw" d="M3015 3400 c-17 -17 -63 -48 -103 -67 -69 -35 -79 -37 -203 -42 -130
-6 -131 -6 -154 -35 -52 -66 -33 -151 41 -178 l34 -12 0 -83 c0 -77 2 -86 26
-109 19 -20 31 -24 52 -19 15 3 32 11 37 16 6 6 12 51 15 102 l5 92 39 -2 c51
-4 149 -50 205 -97 24 -20 49 -35 55 -33 6 2 12 37 14 85 2 72 6 85 27 106 16
16 25 36 25 56 0 20 -9 40 -25 56 -21 21 -25 34 -27 106 -3 96 -16 107 -63 58z
m11 -378 c-3 -2 -19 6 -37 19 -17 12 -57 33 -88 45 l-56 23 0 71 0 72 59 24
c33 13 73 35 90 48 l31 24 3 -161 c1 -88 0 -162 -2 -165z m-236 158 l0 -70
-85 0 c-78 0 -88 2 -110 25 -27 26 -31 46 -14 76 15 29 44 38 132 38 l77 1 0
-70z m302 -12 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m-382
-183 c0 -69 -3 -85 -15 -85 -12 0 -15 15 -15 78 0 43 3 82 7 85 18 18 23 0 23
-78z"/>
<path className="Solution-FifthCardAIDraw" d="M3178 3289 c-38 -30 -47 -52 -28 -64 15 -9 101 56 98 74 -5 26 -31
23 -70 -10z"/>
<path className="Solution-FifthCardAIDraw" d="M3146 3195 c-7 -20 24 -35 71 -35 42 0 65 14 56 36 -8 20 -119 19
-127 -1z"/>
<path className="Solution-FifthCardAIDraw" d="M2457 3164 c-16 -16 -7 -44 14 -44 15 0 20 5 17 22 -3 22 -19 33 -31
22z"/>
<path className="Solution-FifthCardAIDraw" d="M2381 3141 c-10 -7 -11 -13 -2 -29 13 -24 46 -19 37 5 -3 8 -6 19 -6
24 0 11 -11 11 -29 0z"/>
<path className="Solution-FifthCardAIDraw" d="M3140 3131 c0 -24 76 -82 97 -74 12 5 14 1 8 -15 -3 -12 -2 -22 3
-22 14 0 25 34 13 38 -6 2 -11 10 -11 17 0 17 -73 75 -94 75 -9 0 -16 -8 -16
-19z"/>
<path className="Solution-FifthCardAIDraw" d="M2312 3112 c-15 -10 -5 -42 13 -42 18 0 26 15 19 34 -7 18 -13 19
-32 8z"/>
<path className="Solution-FifthCardAIDraw" d="M2241 3081 c-18 -12 3 -53 24 -45 16 7 18 14 9 38 -7 18 -13 19 -33
7z"/>
<path className="Solution-FifthCardAIDraw" d="M2173 3039 c-19 -19 6 -50 29 -38 15 8 16 13 5 29 -14 23 -19 24 -34
9z"/>
<path className="Solution-FifthCardAIDraw" d="M3293 3012 c-9 -15 -7 -21 8 -30 15 -10 20 -9 28 7 7 12 6 22 -1 29
-16 16 -21 15 -35 -6z"/>
<path className="Solution-FifthCardAIDraw" d="M2112 2998 c-15 -15 3 -49 23 -42 19 8 19 28 2 43 -10 8 -17 7 -25
-1z"/>
<path className="Solution-FifthCardAIDraw" d="M3360 2965 c-10 -12 -10 -18 0 -30 12 -14 15 -14 31 1 14 13 16 20 8
31 -15 17 -24 17 -39 -2z"/>
<path className="Solution-FifthCardAIDraw" d="M2052 2948 c-7 -9 -7 -18 3 -31 11 -15 14 -16 25 -2 11 13 11 19 -2
31 -12 12 -17 13 -26 2z"/>
<path className="Solution-FifthCardAIDraw"  d="M3420 2915 c-10 -12 -10 -18 0 -30 12 -14 15 -14 31 1 14 13 16 20 8
31 -15 17 -24 17 -39 -2z"/>
<path className="Solution-FifthCardAIDraw" d="M1991 2897 c-8 -11 -7 -18 8 -31 15 -14 20 -14 30 -3 8 11 7 18 -8
31 -15 14 -20 14 -30 3z"/>
<path className="Solution-FifthCardAIDraw" d="M3480 2855 c-10 -12 -10 -18 0 -31 11 -13 15 -13 27 -1 13 13 8 47
-7 47 -4 0 -13 -7 -20 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1941 2836 c-8 -10 -7 -17 4 -26 13 -11 19 -11 32 2 14 15 13 17 -5
27 -15 8 -23 7 -31 -3z"/>
<path className="Solution-FifthCardAIDraw" d="M3530 2795 c-10 -12 -10 -18 0 -31 12 -13 15 -13 28 0 15 15 10 46
-6 46 -5 0 -15 -7 -22 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1892 2778 c-13 -13 2 -38 23 -38 18 0 26 25 12 39 -14 14 -20 14
-35 -1z"/>
<path className="Solution-FifthCardAIDraw" d="M3581 2731 c-14 -9 -9 -41 6 -41 19 1 35 19 30 34 -7 18 -16 20 -36
7z"/>
<path className="Solution-FifthCardAIDraw" d="M1849 2714 c-6 -8 -10 -16 -8 -17 2 -1 12 -8 21 -15 14 -11 18 -10
27 5 13 26 -21 50 -40 27z"/>
<path className="Solution-FifthCardAIDraw" d="M3622 2662 c-12 -8 -7 -42 7 -42 17 1 33 20 28 34 -7 18 -16 20 -35
8z"/>
<path className="Solution-FifthCardAIDraw" d="M1806 2645 c-8 -21 33 -42 45 -24 6 9 3 18 -8 27 -22 16 -30 15 -37
-3z"/>
<path className="Solution-FifthCardAIDraw" d="M2157 2603 c-4 -3 -7 -206 -7 -449 l0 -444 223 -2 222 -3 3 -57 3
-58 -71 0 c-56 0 -72 -3 -77 -16 -3 -9 0 -20 8 -25 8 -5 136 -9 285 -9 226 0
274 2 284 15 21 25 1 35 -71 35 l-69 0 0 60 0 60 223 2 222 3 0 445 0 445
-586 3 c-322 1 -589 -1 -592 -5z m1133 -368 l0 -325 -545 0 -545 0 0 325 0
325 545 0 545 0 0 -325z m-2 -422 l3 -53 -545 0 -546 0 0 55 0 55 543 -2 542
-3 3 -52z m-448 -163 l0 -60 -95 0 -95 0 0 60 0 60 95 0 95 0 0 -60z"/>
<path className="Solution-FifthCardAIDraw" d="M2628 2503 c-36 -14 -38 -18 -38 -58 0 -46 -17 -69 -44 -59 -34 13
-62 -4 -76 -47 -14 -40 -13 -42 13 -61 34 -25 34 -48 2 -78 -29 -27 -31 -40
-10 -80 13 -25 21 -30 54 -30 46 0 58 -13 63 -65 3 -35 7 -41 42 -54 38 -14
39 -14 62 12 29 34 51 34 85 1 27 -26 29 -26 66 -11 35 14 38 18 43 63 l5 49
46 3 c38 2 48 7 58 28 17 38 14 58 -14 84 -14 13 -25 29 -25 35 0 6 11 22 25
35 29 27 31 49 9 91 -13 26 -20 30 -47 28 -45 -4 -57 6 -57 51 0 34 -4 42 -30
55 -41 21 -63 19 -90 -10 -30 -32 -37 -31 -75 4 -29 29 -31 29 -67 14z m112
-83 c25 0 40 7 55 27 26 32 45 24 45 -21 0 -47 38 -86 84 -86 46 0 56 -18 23
-45 -36 -28 -37 -82 -3 -114 32 -30 23 -51 -22 -51 -50 0 -82 -32 -82 -82 0
-45 -11 -47 -52 -13 -16 14 -41 25 -54 25 -14 0 -35 -11 -48 -25 -35 -37 -56
-33 -56 12 0 51 -32 83 -82 83 -31 0 -38 4 -38 20 0 10 9 24 20 30 15 8 20 21
20 54 0 30 -6 48 -20 61 -31 28 -25 45 17 45 51 0 83 32 83 81 0 46 17 54 51
23 16 -16 37 -24 59 -24z"/>
<path className="Solution-FifthCardAIDraw" d="M2654 2327 c-72 -66 -50 -174 42 -206 52 -18 88 -9 131 34 27 27 33
41 33 74 0 82 -49 131 -130 131 -32 0 -48 -7 -76 -33z m140 -33 c16 -16 26
-38 26 -55 0 -79 -109 -113 -150 -46 -27 44 -25 63 9 98 38 37 79 38 115 3z"/>
<path className="Solution-FifthCardAIDraw" d="M3658 2589 c-18 -10 -14 -39 6 -39 22 0 37 17 30 33 -7 19 -12 20
-36 6z"/>
<path className="Solution-FifthCardAIDraw" d="M1777 2583 c-13 -13 -7 -31 14 -36 24 -7 39 21 18 34 -17 11 -24 11
-32 2z"/>
<path className="Solution-FifthCardAIDraw" d="M3626 2522 c-50 -2 -96 -8 -103 -14 -18 -14 -19 -358 -1 -376 7 -7
36 -12 65 -12 l52 0 3 -67 c3 -76 15 -87 54 -48 24 24 27 25 39 9 7 -10 19
-15 29 -11 23 9 20 34 -5 42 -19 6 -18 9 13 41 33 34 34 34 124 34 56 0 94 4
102 12 18 18 17 362 -1 376 -13 11 -218 19 -371 14z m334 -202 l0 -150 -89 0
-90 0 -43 -50 -43 -50 -5 47 -5 48 -62 3 -63 3 0 149 0 150 200 0 200 0 0
-150z"/>
<path className="Solution-FifthCardAIDraw" d="M3661 2398 c-43 -114 -61 -174 -57 -185 9 -21 32 -15 44 12 16 34 54
33 81 -2 17 -24 23 -26 39 -17 12 8 24 9 35 2 10 -5 36 -8 60 -6 l42 3 3 108
c3 101 2 107 -17 107 -16 0 -20 -8 -23 -42 -3 -41 -5 -43 -38 -48 -27 -4 -39
-12 -51 -37 l-16 -32 -28 80 c-21 61 -31 79 -46 79 -11 0 -23 -10 -28 -22z
m39 -99 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
-16z m157 -25 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z"/>
<path  className="Solution-FifthCardAIDraw" d="M1627 2510 c-126 -5 -129 -6 -153 -33 -24 -28 -24 -31 -24 -223 0
-203 7 -243 45 -258 9 -3 59 -6 110 -6 65 0 95 -4 97 -12 4 -10 10 -10 32 0
15 6 63 12 107 12 72 0 82 3 104 25 25 24 25 25 25 233 0 262 3 257 -150 264
-36 2 -123 1 -193 -2z m291 -255 c2 -179 1 -201 -15 -212 -21 -16 -355 -18
-384 -3 -18 10 -19 23 -19 209 0 109 3 201 7 205 4 3 97 5 207 4 l201 -3 3
-200z"/>
<path className="Solution-FifthCardAIDraw" d="M1530 2405 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1610 2405 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1690 2405 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1770 2405 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1840 2405 c0 -8 9 -15 19 -15 10 0 21 7 25 15 4 11 -1 15 -19 15
-16 0 -25 -6 -25 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1634 2337 c-9 -24 1 -190 11 -194 13 -4 168 89 172 104 4 12 -141
102 -165 103 -7 0 -15 -6 -18 -13z m81 -69 c32 -14 32 -20 -5 -41 l-30 -18 0
35 c0 40 0 40 35 24z"/>
<path className="Solution-FifthCardAIDraw" d="M1530 2090 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path className="Solution-FifthCardAIDraw" d="M1610 2090 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path className="Solution-FifthCardAIDraw" d="M1690 2091 c0 -15 6 -21 21 -21 25 0 22 33 -4 38 -12 2 -17 -3 -17
-17z"/>
<path className="Solution-FifthCardAIDraw" d="M1770 2091 c0 -15 6 -21 21 -21 25 0 22 33 -4 38 -12 2 -17 -3 -17
-17z"/>
<path className="Solution-FifthCardAIDraw" d="M1847 2103 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
<path className="Solution-FifthCardAIDraw" d="M3717 1950 c-3 -11 -1 -20 3 -20 5 0 15 -3 23 -6 9 -3 16 2 20 15 3
12 -1 22 -10 25 -24 10 -30 7 -36 -14z"/>
<path className="Solution-FifthCardAIDraw"  d="M1721 1931 c-8 -5 -11 -16 -8 -24 8 -22 47 -10 47 14 0 20 -16 24
-39 10z"/>
<path className="Solution-FifthCardAIDraw" d="M3700 1871 c0 -23 36 -35 44 -14 8 20 -1 33 -25 33 -12 0 -19 -7 -19
-19z"/>
<path className="Solution-FifthCardAIDraw" d="M1747 1862 c-23 -5 -18 -36 6 -40 19 -4 35 25 21 37 -5 4 -17 6 -27
3z"/>
<path className="Solution-FifthCardAIDraw" d="M3675 1802 c-6 -16 10 -32 33 -32 6 0 12 8 12 19 0 24 -36 35 -45 13z"/>
<path className="Solution-FifthCardAIDraw" d="M1777 1789 c-23 -13 -22 -39 2 -39 24 0 35 15 24 34 -6 11 -13 12
-26 5z"/>
<path className="Solution-FifthCardAIDraw" d="M3645 1733 c-8 -23 25 -50 37 -30 12 18 10 25 -11 36 -15 8 -20 7
-26 -6z"/>
<path className="Solution-FifthCardAIDraw" d="M1806 1717 c-14 -10 -16 -18 -9 -30 8 -15 12 -15 27 -4 14 10 16 18
9 30 -8 15 -12 15 -27 4z"/>
<path className="Solution-FifthCardAIDraw" d="M3606 1655 c-3 -9 3 -19 14 -25 15 -8 21 -6 29 9 8 15 7 20 -5 25
-24 9 -31 7 -38 -9z"/>
<path className="Solution-FifthCardAIDraw" d="M1837 1647 c-15 -10 -16 -15 -5 -29 12 -15 15 -15 33 -2 17 13 17 16
4 29 -12 13 -18 13 -32 2z"/>
<path className="Solution-FifthCardAIDraw" d="M3566 1593 c-5 -11 -1 -22 10 -30 14 -10 19 -10 30 4 10 14 9 20 -5
30 -22 17 -28 16 -35 -4z"/>
<path className="Solution-FifthCardAIDraw" d="M1881 1581 c-14 -9 -9 -41 7 -41 15 0 34 28 27 40 -8 12 -16 13 -34
1z"/>
<path className="Solution-FifthCardAIDraw" d="M3517 1526 c-9 -23 19 -41 38 -26 12 10 13 16 4 26 -15 18 -35 18
-42 0z"/>
<path className="Solution-FifthCardAIDraw" d="M3630 1502 c-74 -36 -75 -38 -89 -89 -20 -77 -58 -84 -86 -16 -8 20
-20 33 -27 30 -7 -2 -30 4 -51 15 -51 26 -142 26 -199 0 -81 -36 -131 -107
-144 -203 -8 -56 -7 -81 5 -89 4 -3 19 -26 35 -53 76 -132 261 -153 371 -42
52 51 77 124 73 206 l-3 54 30 -1 c17 0 46 -8 66 -17 35 -16 37 -15 112 20 43
20 77 43 77 50 0 8 -22 23 -49 34 l-49 21 14 46 c14 45 13 72 -3 72 -4 0 -42
-17 -83 -38z m40 -44 c0 -7 -4 -18 -8 -25 -10 -16 -82 -46 -82 -34 0 24 16 44
44 57 39 17 46 17 46 2z m-287 -68 c52 -33 68 -57 46 -71 -9 -5 -22 0 -39 15
-38 36 -64 46 -118 46 -181 0 -213 -262 -37 -309 108 -28 223 75 200 179 -5
22 -2 28 14 33 15 4 20 -2 25 -28 25 -133 -114 -260 -245 -225 -130 35 -192
180 -125 295 57 96 183 126 279 65z m322 -14 c18 -13 17 -15 -20 -30 -27 -12
-46 -14 -64 -8 l-26 9 40 21 c48 26 47 25 70 8z m-358 -60 c24 -21 26 -25 11
-31 -9 -4 -25 -2 -37 4 -58 31 -121 -2 -121 -64 0 -95 135 -107 155 -14 13 57
50 47 39 -10 -4 -18 -19 -45 -35 -60 -75 -76 -199 -22 -199 86 0 38 5 50 34
79 29 29 41 34 79 34 34 0 53 -6 74 -24z m-71 -71 c-25 -19 -14 -45 16 -38 18
4 20 3 12 -6 -17 -18 -58 -9 -62 12 -6 29 6 47 30 47 22 -1 22 -1 4 -15z"/>
<path className="Solution-FifthCardAIDraw" d="M1921 1516 c-9 -10 -8 -16 4 -26 19 -15 47 3 38 26 -7 18 -27 18 -42
0z"/>
<path className="Solution-FifthCardAIDraw" d="M1964 1477 c-2 -7 -3 -120 -2 -252 l3 -240 194 -3 c107 -1 198 1 203
6 5 5 9 93 9 197 l0 188 -58 59 -57 58 -144 0 c-106 0 -144 -3 -148 -13z m264
-79 l3 -48 50 0 49 0 0 -165 0 -165 -165 0 -165 0 0 215 0 216 113 -3 112 -3
3 -47z m57 2 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z"/>
<path className="Solution-FifthCardAIDraw" d="M2047 1324 c-4 -4 -7 -13 -7 -21 0 -10 28 -13 126 -13 114 0 125 2
122 18 -3 15 -18 17 -119 20 -63 1 -118 -1 -122 -4z"/>
<path className="Solution-FifthCardAIDraw" d="M2042 1233 c3 -16 18 -18 126 -21 117 -2 122 -2 122 18 0 19 -6 20
-126 20 -114 0 -125 -2 -122 -17z"/>
<path className="Solution-FifthCardAIDraw" d="M2053 1174 c-21 -9 -15 -33 10 -39 12 -3 67 -5 122 -3 92 3 100 5
100 23 0 19 -7 20 -110 22 -60 1 -116 -1 -122 -3z"/>
<path className="Solution-FifthCardAIDraw" d="M2047 1094 c-4 -4 -7 -13 -7 -21 0 -10 28 -13 126 -13 114 0 125 2
122 18 -3 15 -18 17 -119 20 -63 1 -118 -1 -122 -4z"/>
<path className="Solution-FifthCardAIDraw" d="M3464 1469 c-3 -6 1 -18 9 -27 13 -13 19 -13 32 -2 12 10 13 16 4 26
-14 17 -35 18 -45 3z"/>
<path className="Solution-FifthCardAIDraw" d="M2417 1193 c-15 -14 -7 -43 12 -43 23 0 35 36 14 44 -18 7 -18 7 -26
-1z"/>
<path  className="Solution-FifthCardAIDraw"  d="M2487 1160 c-3 -14 0 -23 10 -27 16 -6 35 15 30 32 -7 19 -34 16 -40
-5z"/>
<path className="Solution-FifthCardAIDraw" d="M2950 1154 c0 -33 33 -30 38 4 3 17 -2 22 -17 22 -16 0 -21 -6 -21
-26z"/>
<path className="Solution-FifthCardAIDraw"  d="M2566 1153 c-10 -25 -7 -33 13 -33 25 0 35 37 10 43 -10 3 -20 -2
-23 -10z"/>
<path className="Solution-FifthCardAIDraw" d="M2881 1161 c-20 -13 -6 -41 20 -41 15 0 19 4 15 16 -3 9 -6 20 -6 25
0 11 -11 11 -29 0z"/>
<path className="Solution-FifthCardAIDraw" d="M2646 1153 c-14 -15 -5 -43 14 -43 13 0 20 7 20 19 0 19 -23 35 -34
24z"/>
<path className="Solution-FifthCardAIDraw" d="M2807 1154 c-16 -16 -7 -44 14 -44 15 0 20 5 17 22 -3 22 -19 33 -31
22z"/>
<path className="Solution-FifthCardAIDraw" d="M2720 1130 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
</g>
</svg>


      </div>
            <div className="Solution-FONTENLARGE">
             <p className="Solution-FifthCardText">
             In the digital era, a brand's online presence is its most potent asset, and at Coding Visuals, we're dedicated to amplifying that presence. Our holistic suite of <strong className="Solution-goldcolorfifth">marketing and development services</strong> ensures that every touchpoint with your audience is meaningful and impactful. From crafting a robust <strong className="Solution-goldcolorfifth">digital marketing strategy </strong> to setting up a seamless <strong className="Solution-goldcolorfifth">digital commerce platform</strong>, we've got you covered. Our <strong className="Solution-goldcolorfifth">web and app development expertise</strong> ensures your digital real estate is both functional and captivating. Dive into the <strong className="Solution-goldcolorfifth">Influencer marketing </strong> realm, amplify your <strong className="Solution-goldcolorfifth"> social media growth</strong> , and tap into the power of <strong className="Solution-goldcolorfifth">LinkedIn networking</strong>  with our tailored solutions. And when it's time to tell your brand's story, our <strong className="Solution-goldcolorfifth">production services for ads</strong> and social media platforms encapsulate your essence with unrivaled creativity. With Coding Visuals by your side, every digital step you take is a leap towards unparalleled success.
           


    </p>
      </div>
    <div className="SolutionAIContainer">
    <button className="SolutionFIFTHFRAMEDEVBUTTON" onClick={() => setIsOpenDevelopment(true)}rel="noopener noreferrer">
        Get AI powered Marketing/Development Services <span className="Solution-DEVarrow">&rsaquo;</span>
    </button>
    {isOpenDevelopment && (
                <div className="Solution-modal">
                    <div className="Solution-modal-content">
                        <span 
                            className="Solution-close-btn" 
                            onClick={() => setIsOpenDevelopment(false)}
                        >
                            &times;
                        </span>
                        <div className="Solution-Phone-GmailOnly">
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                            <path  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            <a href="tel:+919990994592">
                                <span>  +919990994592</span>
                                
                            </a>
                        </div>
                        <div className="Solution-contact-option">
                        <svg className="Solution-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:support@codingvisuals.com">
                           
                                <span>  support@codingvisuals.com</span>
                                
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
       
      </div>

            </div>
         
           
        </div>
    
    
</div>

<div className="section"  ref={solutionSixthCardRef}>


<div className="Solution-footer-container">
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
               <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
           </svg>
           <a href="tel:+919990994592">+919990994592</a>
       </div>
       <div className="Solution-footer-item Ghazibadadresfootersolution">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z"/></svg>
           <a href="https://maps.app.goo.gl/c7aDLXXXbeVLsPRM7?g_st=iw" target="_blank">B-Block, Swaran Jyanti Puram, Govindpuram, Ghaziabad, UP - 201013, IN</a>
       </div>
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
           <a href="mailto:support@codingvisuals.com">support@codingvisuals.com</a>
       </div>
   </div>
   <div className="Solution-secondary-container">
       <div className="Solution-mission">
           <h3>Our Mission</h3>
           <p className="OurMission-text">At CODING VISUALS, we believe that every individual and business has untapped potential waiting to be unleashed in the global realm. Our mission is to empower you to not only establish a strong digital presence but also to grow and thrive in the ever-expanding modern landscape. Let us be your trusted partner, guiding you towards success.</p>
       </div>
       
       <div className="SolutionFooterSocialMediaLinks">
       <h3>Connect With Us</h3>
       
   <Link to='https://twitter.com/Coding_Visuals' target="_blank"  className="SolutionTwitter">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg> <span>@Coding_Visuals</span>
   </Link>
   <Link to='https://www.instagram.com/coding_visuals/' target="_blank" className="SolutionInstagram">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg> <span>@coding_visuals</span>


   </Link>
   <Link to='https://www.youtube.com/@CodingVisuals' target="_blank" className="SolutionYoutube">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
     <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg> <span>@CodingVisuals</span>
   </Link>
   <Link to='https://www.linkedin.com/company/99880813/admin/feed/posts/' target="_blank" className="SolutionLinkedin">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg> <span></span><span>@Coding Visuals</span>
   </Link>
   <Link to='https://www.facebook.com/people/Coding-Visuals/61550245559154/' target="_blank" className="SolutionFB">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
     <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg> <span>@Coding-Visuals</span>
   </Link>
   </div>
       
   </div>
  <div className="ThoughtoFTHEDAY">
   <ThoughtOfTheDay/>
  </div>
 
  <div className="Solution-copyright-section">
   <p>Copyright © 2023 CodingVisuals | AI Powered |
     <Link to="/Home/TermsOfServices">Terms</Link> |
     <Link to="/Home/PrivacyPolicy">Privacy</Link> |
     <Link to="/Home/Shipping-Delivery">Shipping & Delivery</Link> |
     <Link to="/Home/CancellationPolicy">Cancellation</Link>| Image by rawpixel.com on Freepik
   </p>
 </div>
</div>



            <div className="pagination">
                {Array(sectionsCount)
                    .fill()
                    .map((_, idx) => (
                        <span
                            key={idx}
                            className={`pagination-dot ${
                                idx === currentSection ? "active" : ""
                            }`}
                        ></span>
                    ))}
            </div>
        
            
        </div>
    );
}

export default Solution;