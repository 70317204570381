import React from 'react';
import './App.css'
import Informative from './Informative.js'; // Assuming `informative.js` is in the same directory as App.js
import Header from './Header'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home'
import BusinessConsultancy from './BusinessConsultancy';
import OurPlans from './OurPlans.js';
import Solution from './Solution'
import Insights from './Insights'

import Resources from './Resources'
import FirstBlog from './FirstBlog'
import SecondBlog from './SecondBlog'
import ThirdBlog from './ThirdBlog'
import FourthBlog from './FourthBlog'
import FifthBlog from './FifthBlog'
import SixthBlog from './SixthBlog'
import SeventhBlog from './SeventhBlog'
import EightBlog from './EightBlog'
import NineBlog from './NineBlog'
import TenBlog from './TenBlog'
import Blogs from'./Blog'
import ContactUS from './ContactUS';
import AboutUS from './AboutUS'
import Career from './Career'
import TermsOfServices from './TermsOfServices'
import PrivacyPolicy from './PrivacyPolicy'
import CancellationPolicy from './CancellationPolicy.js'

import WordPressEcomCasestudy from './WordPressEcomCasestudy'
import ContentMarketingTravelCaseStudy from './ContentMarketingTravelCaseStudy'
import ReactFitnessTrackerCaseStudy from './ReactFitnessTrackerCaseStudy'
import EWalletAppCaseStudy from './E-WalletAppCaseStudy'
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ShippingDelivery from './Shipping-Delivery'
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 2000, // time the alert will be visible
  offset: '30px',
  transition: transitions.SCALE
};


function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
    <div className="App">
      
    <BrowserRouter>
    <Informative/>
    <Header />
  
    <Routes>
    <Route path='/' Component={Home } />
    <Route path='/BusinessConsultancy' Component={BusinessConsultancy } />
    <Route path='/OurPlans' Component={OurPlans } />
    <Route path='/Solution' Component={Solution } />
    <Route path='/Insights' Component={Insights } />
  
    <Route path='/Insights/Resources' Component={Resources } />
    <Route path='/Insights/Blogs' Component={Blogs } />
    <Route path='/Insights/Blog/FirstBlog' Component={FirstBlog } />
    <Route path='/Insights/Blog/SecondBlog' Component={SecondBlog } />
    <Route path='/Insights/Blog/ThirdBlog' Component={ThirdBlog } />
    <Route path='/Insights/Blog/FourthBlog' Component={FourthBlog } />
    <Route path='/Insights/Blog/FifthBlog' Component={FifthBlog } />
    <Route path='/Insights/Blog/SixthBlog' Component={SixthBlog } />
    <Route path='/Insights/Blog/SeventhBlog' Component={SeventhBlog } />
    <Route path='/Insights/Blog/EightBlog' Component={EightBlog } />
    <Route path='/Insights/Blog/NineBlog' Component={NineBlog } />
    <Route path='/Insights/Blog/TenBlog' Component={TenBlog } />
    <Route path='/ContactUS' Component={ContactUS } />
    <Route path='/AboutUS' Component={AboutUS } />
    <Route path='/Career' Component={Career } />
    <Route path='/TermsOfServices' Component={TermsOfServices } />
    <Route path='/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/Home/TermsOfServices' Component={TermsOfServices } />
    <Route path='/Home/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/Home/CancellationPolicy' Component={CancellationPolicy } />
    <Route path='/Home/Shipping-Delivery' Component={ShippingDelivery } />
   

    <Route path='/BusinessConsultancy/TermsOfServices' Component={TermsOfServices } />
    <Route path='/BusinessConsultancy/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/BusinessConsultancy/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/Solution/TermsOfServices' Component={TermsOfServices } />
    <Route path='/Solution/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/Solution/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/Insights/TermsOfServices' Component={TermsOfServices } />
    <Route path='/Insights/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/Insights/CancellationPolicy' Component={CancellationPolicy } />
    
    
    <Route path='/Career/TermsOfServices' Component={TermsOfServices } />
    <Route path='/Career/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/Career/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/AboutUS/TermsOfServices' Component={TermsOfServices } />
    <Route path='/AboutUS/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/AboutUS/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/ContactUS/TermsOfServices' Component={TermsOfServices } />
    <Route path='/ContactUS/PrivacyPolicy' Component={PrivacyPolicy } />
    <Route path='/ContactUS/CancellationPolicy' Component={CancellationPolicy } />

    <Route path='/Home/WordPressEcomCasestudy' Component={WordPressEcomCasestudy } />
    <Route path='/Home/ContentMarketingTravelCaseStudy' Component={ContentMarketingTravelCaseStudy } />
    <Route path='/Home/ReactFitnessTrackerCaseStudy' Component={ReactFitnessTrackerCaseStudy } />
    <Route path='/Home/E-WalletAppCaseStudy' Component={EWalletAppCaseStudy } />
      </Routes>
      
    </BrowserRouter>
    
    
   
   
    </div>
    </AlertProvider>
  );
}

export default App;
