import React, { useRef, useEffect } from 'react';
import './FourthBlog.css';
import AIvsHumanFourth from './images/AIvsHuman.png'
const FourthBlog = () => {
    const contentRef = useRef(null);
    const scrollThumbFourthBlogRef = useRef(null);

    const handleScrollFourthBlog = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbFourthBlogRef.current.style.height = `${thumbHeight}px`;
        scrollThumbFourthBlogRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * (clientHeight - thumbHeight)}px)`;
    };

    // Set the initial height of the scroll thumb when the component mounts
    useEffect(() => {
        handleScrollFourthBlog();
    }, []);

    return (
        <div className="FourthBlog-wrapper">
            <div className="FourthBlog-container" onScroll={handleScrollFourthBlog} ref={contentRef}>
            <h1>AI vs. Human: Understanding the Dance of Coexistence</h1>
            <h5 className='Fourth-Blog-Date-Time'> Written on 10 October,2023   |      Reading Time : 2min </h5>
            <img src={AIvsHumanFourth} alt="Description of Aivshuman" />
            <h2>Introduction:</h2>
            <p className='FourthblogContext'>The age-old debate of  <strong> "AI vs. Human"</strong>    paints a picture of competition. But what if we reframed this not as a rivalry but a relationship? In this journey, we'll delve deep into the capabilities, limits, and harmonious possibilities between artificial intelligence and human beings.
</p>

            <h2>Unraveling the Strengths:</h2>
            <h2>1. Artificial Intelligence:</h2>
            <ul>
                <li> <strong>➡ Processing Power:</strong> AI systems can crunch vast amounts of data at speeds incomprehensible to humans. Whether it's analyzing stock markets or predicting weather patterns, their computational power is unmatched.
</li>
                <li><strong>➡ Consistency: </strong> Machines don't tire. An AI system can perform a task consistently, without the ebbs and flows of human energy or mood.

</li>
                <li><strong>➡ Scalability: </strong>  Once an AI model is trained, it can be duplicated across various platforms or tasks without additional training time.
</li>
               
            </ul>

            <h2>2. Humans:</h2>
            <ul>
                <li> <strong>➡ Emotional Intelligence:</strong> Humans can perceive emotions, context, and subtle social cues in ways machines can't replicate.</li>
                <li><strong>➡ Creativity & Innovation:</strong> While AI can generate content based on existing data, the spark of genuine innovation and abstract thinking remains a human stronghold.

</li>
                <li className='FourthblogContext'><strong>➡ Moral Reasoning:  </strong>  Humans navigate the world with a moral compass, making decisions based on ethical, cultural, or personal beliefs.</li>
                
            </ul>

            <h2>Beyond the Hype: AI's Limitations
</h2>   
<p>While AI's advances, from deep learning to neural networks, are impressive, they have constraints:
 </p>
            <ul>
            <li><strong>➡ Learning Dependency:   </strong> AI learns from data. Without comprehensive data or in situations with ambiguous information, it might falter.

</li>
<li><strong>➡ Lack of Generalization: </strong>  An AI trained in one domain typically can't transfer its knowledge seamlessly to a completely different domain.


</li>
<li className='FourthblogContext'><strong>➡ Ethical Concerns:   </strong>  AI's decisions lack empathy. Instances where AI systems demonstrated biases underline the importance of human oversight.



</li>

            </ul>

            <h2>The Symphony of Collaboration:</h2>
              <p>Instead of focusing on "AI vs. Human", the magic lies in "AI & Human".</p>
              <ul>
            <li><strong>➡ Medical Diagnostics:   </strong> AI can analyze medical scans with precision, but human doctors factor in patient history, emotional well-being, and holistic care.


</li>
<li><strong>➡ Artistic Endeavors: </strong>  Platforms like DALL·E create art based on algorithms, but human artists infuse soul, narrative, and emotion into their creations.


</li>
<li className='FourthblogContext'><strong>➡ Decision Making:   </strong>  AI can provide data-driven insights, but human leaders factor in ethical, societal, and long-term implications.



</li>

            </ul>
            <h2>Preparing for a Shared Future:</h2>
              
              <ul>
            <li><strong>➡ Lifelong Learning:  </strong>  Embrace a mindset of continuous learning. As AI tools evolve, our understanding and skills should too.



</li>
<li><strong>➡ Ethical Considerations: </strong>  As we integrate AI into various sectors, it's crucial to establish ethical guidelines and ensure human oversight.


</li>
<li className='FourthblogContext'><strong>➡ Empathy & Soft Skills:  </strong>  In a tech-driven world, human-centric skills become even more valuable. Focus on enhancing empathy, leadership, and interpersonal skills.
</li>

            </ul>
            

            <h2>Conclusion:</h2>
            <p>The narrative shouldn't be AI vs. Humans, but how AI can amplify human potential. Our future isn't about choosing between human intuition and AI's data-driven approach. It's about intertwining the strengths of both to forge a future that's brighter, more efficient, and deeply humane. As we stand on the cusp of this AI-augmented era, let's embrace the dance of coexistence and coevolution.

</p>
            </div>
            <div className="faux-scrollbar">
                <div className="faux-thumb" ref={scrollThumbFourthBlogRef}></div>
            </div>
        </div>
    );
}

export default FourthBlog;
