import React, { useRef, useEffect } from 'react';
import './Blog.css';
import {Link} from 'react-router-dom'

const Blog = () => {
    const contentRef = useRef(null);
    const scrollThumbNineRef = useRef(null);

    const handleScrollNine = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbNineRef.current.style.height = `${thumbHeight}px`;
        scrollThumbNineRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * (clientHeight - thumbHeight)}px)`;
    };

    // Set the initial height of the scroll thumb when the component mounts
    useEffect(() => {
        handleScrollNine();
    }, []);

    return (
        <>
         <div className="wrapper-Insights">
            <div className="container-Insights" onScroll={handleScrollNine} ref={contentRef}>
           <div className="InsightsTest-card">
            
            <Link to='/Insights/Blog/FirstBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-card-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content">
            <h3> Will AI Take Over Jobs? Navigating the Nuances </h3>
            <p>The whispers of "Will AI take over jobs?" reverberate through conference rooms, classrooms, and coffee shops. While dystopian novels and movies prophesy a world dominated by machines, the true picture has shades of both challenge and promise. This enriched exploration promises you not just an overview, but a roadmap for the AI-driven job market.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Secondcard">
            
            <Link to='/Insights/Blog/FifthBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Secondcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-secondcard-hover-content">
            <h3> Unraveling Consumer Behavior: The Deep Dive into Purchase Decisions </h3>
            <p>Behind every purchase, from the tiniest trinket to the grandest gadget, lies a complex web of decisions, emotions, and influences. Welcome to the multifaceted world of consumer behavior. Let's delve deeper to unravel the intricate nuances that drive our buying choices and shape market trends.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Thirdcard">
            
            <Link to='/Insights/Blog/SixthBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3> Is Influencer Marketing Worth It? Navigating the Hype and Reality</h3>
            <p>"Influencer marketing" – two words that have taken the digital realm by storm. With dazzling Instagram posts, unboxing videos, and picturesque tweets, is this modern marketing method a goldmine or just glitter? Dive in as we debunk myths, weigh pros against cons, and uncover the real value of influencer marketing.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Fourthcard">
            
            <Link to='/Insights/Blog/SeventhBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>Holding Onto Brilliance: The Hidden Power of Employee Retention</h3>
            <p>In the bustling marketplace where businesses jostle for a foothold, there lies a silent lever of success – employee retention.  This article uncovers the myriad reasons why nurturing and retaining talent can be the linchpin for organizational growth.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Fifthcard">
            
            <Link to='/Insights/Blog/SecondBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>AI Businesses to Start with Zero Dollar</h3>
            <p>The intersection of AI and entrepreneurship is dynamic and exciting. Many believe that entering this realm requires deep pockets, but in reality, there are avenues within AI that require more intellect than initial investment. Let's journey through the AI business landscapes you can navigate with minimal capital.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Sixthcard">
            
            <Link to='/Insights/Blog/EightBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>The Imperative of Employee Retention: Nurturing Talent for Organizational Success</h3>
            <p>In the vibrant tapestry of organizational success, one strand often stands out but is commonly overlooked: employee retention. While the quest for new talent is relentless, retaining the existing talent pool is equally, if not more, critical. </p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Seventhcard">
            
            <Link to='/Insights/Blog/TenBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>The Future Fusion: Digital Marketing Meets Artificial Intelligence</h3>
            <p>We're on the cusp of a revolutionary shift in digital marketing, as it dances to the rhythm of Artificial Intelligence (AI). AI, with its vast capabilities, is no longer confined to robotics or data. This article dives deep into the exhilarating confluence of digital marketing and AI.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Eightcard">
            
            <Link to='/Insights/Blog/NineBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>Digital Marketing on a Dime: Mastering Promotion Without Investment</h3>
            <p>The digital realm offers a plethora of opportunities for businesses to reach vast audiences. But what if you're just starting out or don't have a big budget for marketing campaigns? Fear not. The beauty of digital marketing lies in its versatility. </p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Ninecard">
            
            <Link to='/Insights/Blog/FourthBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>AI vs. Human: Understanding the Dance of Coexistence</h3>
            <p>The age-old debate of "AI vs. Human" paints a picture of competition. But what if we reframed this not as a rivalry but a relationship? In this journey, we'll delve deep into the capabilities, limits, and harmonious possibilities between artificial intelligence and human beings.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
        <div className="InsightsTest-Tencard">
            
            <Link to='/Insights/Blog/ThirdBlog' className="InsightsTest-card-link">
      
        <div className="InsightsTest-Thirdcard-header">
            Blog
        </div>
        <div className="InsightsTest-card-hover-content InsightsTest-Thirdcard-hover-content">
            
            <h3>Business Psychology: The Deep Dive into Organizational Mastery</h3>
            <p>In the vast ocean of business dynamics, understanding the underlying currents of human behavior is paramount. Business psychology, while often overlooked, is the compass guiding organizations through these uncharted waters. By marrying the scientific understanding of human behavior with business strategies, it provides an invaluable framework for organizational success.</p>
            <button className="InsightsTest-read-more-button">Read More <span className="InsightsTest-arrow">→</span></button>
        </div>
       
    </Link>
           
            
            

        </div>
            
            
            
                
                
                
            
           
                
                
                
           
         
          
        
                
                
            
            
            
           
            </div>
            <div className="faux-scrollbar-Insights">
                <div className="faux-thumb-Insights" ref={scrollThumbNineRef}></div>
            </div>
        </div>
       
        </>
    );
}

export default Blog;
