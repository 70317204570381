import React, { useRef, useEffect } from 'react';
import './SixthBlog.css';
import InfluencerMarketingSixth from './images/InfluencerMarketingSixth.png'
const SixthBlog = () => {
    const contentRef = useRef(null);
    const scrollThumbSixthBlogRef = useRef(null);

    const handleScrollSixthBlog = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbSixthBlogRef.current.style.height = `${thumbHeight}px`;
        scrollThumbSixthBlogRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * (clientHeight - thumbHeight)}px)`;
    };

    // Set the initial height of the scroll thumb when the component mounts
    useEffect(() => {
        handleScrollSixthBlog();
    }, []);

    return (
        <div className="SixthBlog-wrapper">
            <div className="SixthBlog-container" onScroll={handleScrollSixthBlog} ref={contentRef}>
            <h1>Is Influencer Marketing Worth It? Navigating the Hype and Reality</h1>
            <h5 className='Sixth-Blog-Date-Time'> Written on 30 September, 2023  |      Reading Time : 2min </h5>
            <img src={InfluencerMarketingSixth} alt="Description of Aivshuman" />
            <h2>Introduction:</h2>
            <p className='SixthblogContext'> <strong>"Influencer marketing"</strong>   – two words that have taken the digital realm by storm. With dazzling Instagram posts, unboxing videos, and picturesque tweets, is this modern marketing method a goldmine or just glitter? Dive in as we debunk myths, weigh pros against cons, and uncover the real value of influencer marketing.
</p>

            <h2>The Rise of the Digital Titans:</h2>
            <p className='SixthblogContext'>Today's digital natives don't just watch TV or read newspapers. They scroll, they like, they share. In this ever-connected ecosystem, influencers have risen as the new-age celebrities. But is their influence genuinely influential for businesses?</p>
            

            <h2>The Power of Influencer Marketing:
</h2>   

            <ul>
            <li><strong>➡ Trust & Credibility:   </strong> Unlike traditional advertisements, an endorsement from a trusted influencer can feel more organic and trustworthy.


</li>
<li><strong>➡ Targeted Reach:</strong>  Influencer content often garners higher engagement rates compared to brand-generated content. These interactions can translate into brand visibility and loyalty.



</li>
<li ><strong>➡ Engagement: </strong>  This field dissects the irrationalities in consumer decisions. Why do we splurge on sales? Why does 'free shipping' allure us? It's all anchored in our psychological makeup.



</li>
<li className='SixthblogContext'><strong>➡ Storytelling: </strong>  Influencers excel in weaving stories around products. Instead of a blatant sales pitch, you get a narrative – making products or services more relatable.



</li>

            </ul>

            <h2>The Other Side of the Coin: The Pitfalls</h2>
              
              <ul>
            <li><strong>➡ Inauthenticity Risks:  </strong>  Society, knowingly or unknowingly, sets standards. The car you drive, the fashion you adopt—all might be reflections of societal expectations.


</li>
<li><strong>➡ ROI Uncertainty:</strong>  In today's digital era, technology plays a pivotal role. Algorithms predict what we might like, while virtual reality enables 'try before you buy' experiences.


</li>
<li><strong>➡ Volatile Reputation:</strong> An influencer's misstep can reflect poorly on associated brands. The very nature of their job keeps them in the spotlight, for better or worse.


</li>
<li className='SixthblogContext'><strong>➡ Cost:    </strong>  Big names come with hefty price tags. Is the investment in a celebrity influencer worth it when a micro-influencer might offer a more engaged, niche audience?



</li>

            </ul>
            <h2>Making Influencer Marketing Work for YOU:</h2>
              
              <ul>
            <li><strong>➡ Research is Key: </strong>  Today's consumer is informed, often armed with extensive research. Brands need to be transparent, genuine, and value-driven.




</li>
<li><strong>➡ Set Clear Goals: </strong>  Modern consumers are environmentally and ethically conscious. Brands with sustainable practices and ethical frameworks resonate deeper.


</li>
<li><strong>➡ Forge Genuine Partnerships: </strong>   Instead of one-off posts, cultivate a relationship with influencers. When they genuinely resonate with your brand, it's palpable to their audience.


</li>
<li className='SixthblogContext'><strong>➡ Micro-Influencers & Nano-Influencers:    </strong> They might not have a million followers, but their audience is often more engaged, niche, and loyal. Plus, they're more budget-friendly!
</li>

            </ul>
            
            <h2>In Conclusion: To Influence or Not?</h2>
            <p>Influencer marketing, stripped of all its glamour, is a tool. Its effectiveness hinges on how you wield it. When approached with research, genuine partnerships, and clear goals, it can be a game-changer. But tread without strategy, and you might find it a costly misadventure. <br/>
In the dynamic dance of digital marketing, influencers are potent partners – but only when you both lead and follow with intention. Whether or not influencer marketing is worth it ultimately boils down to how authentically and strategically you can play the game. Ready to take the plunge?


</p>
            </div>
            <div className="faux-scrollbar">
                <div className="faux-thumb" ref={scrollThumbSixthBlogRef}></div>
            </div>
        </div>
    );
}

export default SixthBlog;
