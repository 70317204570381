import React, { useState } from 'react';
import './Header.css';
import LOGO from './images/logo_small2nd.png'; 
import './HeaderMobile.css';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [selectedLink, setSelectedLink] = useState("");
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileNavOpen(prevState => !prevState);
  };
  return (
    <div className="HeaderMain">
      <header className="header-container">
      <div className="logo-container">
        <NavLink to="/" onClick={() => setSelectedLink("")}>
          <img src={LOGO} alt="Company Logo" className="company-logo" />
          <div className="coding-visuals">
    <span className="coding">Coding</span><span className="visuals">Visuals</span>
  </div>
        </NavLink>
        </div>
       <NavLink to='/BusinessConsultancy' className='SLogan-link-BC'> <h1 className="company-slogan">
          <span className="achieve-color">Achieve</span>
          <span className="more-color"> More!</span>
        </h1></NavLink>
        <div className={`mobile-nav ${isMobileNavOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        </div>

        <nav className="navbar">

          <ul>
            <li>
              <NavLink 
                to="/BusinessConsultancy" 
                className={selectedLink === "BusinessConsultancy" ? "active" : ""}
                onClick={() => setSelectedLink("BusinessConsultancy")}
                activeClassName="active"
              >
                Business Consultancy
              </NavLink>
            </li>
            <li >
              <NavLink 
                to="/OurPlans" 
                className={selectedLink === "OurPlans" ? "active" : ""}
                onClick={() => setSelectedLink("OurPlans")}
                activeClassName="active"
              >
                Our Plans
              </NavLink>
            </li>
            <li >
              <NavLink 
                to="/Solution" 
                className={selectedLink === "Solution" ? "active" : ""}
                onClick={() => setSelectedLink("Solution")}
                activeClassName="active"
              >
                Solutions
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/Insights" 
                className={selectedLink === "Insights" ? "active" : ""}
                onClick={() => setSelectedLink("Insights")}
                activeClassName="active"
              >
                Insights
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/Career" 
                className={selectedLink === "Career" ? "active" : ""}
                onClick={() => setSelectedLink("Career")}
                activeClassName="active"
              >
                Career
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/AboutUs" 
                className={selectedLink === "AboutUs" ? "active" : ""}
                onClick={() => setSelectedLink("AboutUs")}
                activeClassName="active"
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/ContactUs" 
                className={selectedLink === "ContactUs" ? "active" : ""}
                onClick={() => setSelectedLink("ContactUs")}
                activeClassName="active"
              >
                Contact Us
              </NavLink>
            </li>
           
          </ul>
        </nav>
        <div className={`mobile-nav-menu ${isMobileNavOpen ? 'open' : ''}`}>
          <nav>
            <ul>
              {/* The mobile navbar links */}
              <li>
                <NavLink to="/BusinessConsultancy" onClick={() => {setSelectedLink("BusinessConsultancy"); toggleMobileMenu();}}>Business Consultancy</NavLink>
              </li>
              <li>
                <NavLink to="/OurPlans" onClick={() => {setSelectedLink("OurPlans"); toggleMobileMenu();}}>Our Plans</NavLink>
              </li>
              <li>
                <NavLink to="/Solution" onClick={() => {setSelectedLink("Solutions"); toggleMobileMenu();}}>Solutions</NavLink>
              </li>
              <li>
                <NavLink to="/Insights" onClick={() => {setSelectedLink("Insights"); toggleMobileMenu();}}>Insights</NavLink>
              </li><li>
                <NavLink to="/Career" onClick={() => {setSelectedLink("Career"); toggleMobileMenu();}}>Career</NavLink>
              </li><li>
                <NavLink to="/AboutUS" onClick={() => {setSelectedLink("About Us"); toggleMobileMenu();}}>About Us</NavLink>
              </li><li>
                <NavLink to="/ContactUS" onClick={() => {setSelectedLink("Contact Us"); toggleMobileMenu();}}>Contact Us</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
