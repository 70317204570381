import React, { useState, useEffect, useRef } from 'react';
import './ThoughtOfTheDay.css';

const thoughts = [
  "1. In business, growth is not just about numbers; it's about understanding and serving your customers better.",
"2. Sales psychology: Understand your customers' needs and emotions to sell solutions, not just products.",
"3. Your business's success is directly proportional to your understanding of your customers.",
"4. To grow your business, focus on building relationships, not just transactions.",
"5. Sales psychology is about empathy: Listen to your customers to truly understand their pain points.",
"6. In the world of business, adaptability is the key to growth.",
"7. Your sales strategy should always start with 'How can I help?'",
"8. To grow your business, think beyond selling; think about solving problems.",
"9. Sales psychology tip: People buy from those they trust and connect with.",
"10. Business growth begins with a deep understanding of your target market.",
"11. Sales psychology: It's not about convincing; it's about showing how your product fulfills a need.",
"12. In business, success is a journey, not a destination.",
"13. To succeed in sales, create value in every interaction.",
"14. Business growth is about finding opportunities in challenges.",
"15. Sales psychology: Your passion for your product is contagious.",
"16. In the pursuit of business growth, don't forget to nurture existing customer relationships.",
"17. Sales psychology: The art of persuasion is rooted in empathy.",
"18. Your business's reputation is your most valuable asset.",
"19. To grow your business, be open to feedback and continuous improvement.",
"20. In sales, ask questions that lead to 'Yes' by understanding customer needs.",
"21. Sales psychology: Consistency builds trust.",
"22. Business growth is fueled by innovation and adaptation.",
"23. To succeed in sales, show genuine interest in solving problems, not just making a sale.",
"24. In business, the customer experience is your competitive advantage.",
"25. Sales psychology tip: Don't just talk; listen actively.",
"26. Business growth requires a mindset of continuous learning and evolution.",
"27. Sales psychology: Seek to understand, not to convince.",
"28. To thrive in business, focus on delivering exceptional value.",
"29. In sales, authenticity trumps persuasion.",
"30. Remember, it's not about selling more; it's about serving better.",
"31.In business, the art of persuasion lies in making customers feel understood and valued.",
"32.Sales psychology: Your enthusiasm can be your most persuasive tool.",
"33.Business growth is a journey that rewards perseverance and innovation.",
"34.To excel in sales, ask questions that uncover your customers' hidden needs.",
"35.Success in business often comes to those who embrace change.",
"36.Sales psychology: Be a problem solver, not just a product pusher.",
"37.Business growth is fueled by customer loyalty and word-of-mouth referrals.",
"38.To thrive in sales, focus on building long-term relationships, not just closing deals.",
"39.In business, adaptability is the key to surviving and thriving in a changing landscape.",
"40.Sales psychology: People buy from people they like and trust.",
"41.Business growth is a testament to your ability to meet market demands.",
"42.To succeed in sales, become a trusted advisor to your customers.",
"43.In the world of business, creativity often leads to innovation and growth.",
"44.Sales psychology: It's not about being the loudest voice; it's about being the most helpful one.",
"45.Business growth requires a combination of strategy, execution, and resilience.",
"46.To excel in sales, address objections with empathy and solutions.",
"47.In business, opportunities often come disguised as challenges.",
"48.Sales psychology: Understand the emotions behind your customers' decisions.",
"49.Business growth is a reflection of your ability to adapt to customer needs.",
"50.To thrive in sales, master the art of active listening.",
"51.In the journey of business growth, persistence is your greatest ally.",
"52.Sales psychology: Be the guide that helps customers navigate their challenges.",
"53.Business growth is the result of consistent effort and a customer-centric approach.",
"54.To succeed in sales, provide value before asking for anything in return.",
"55.In business, staying ahead means staying relevant.",
"56.Sales psychology: The more you understand, the better you can serve.",
"57.Business growth is not a sprint; it's a marathon.",
"58.To excel in sales, inspire trust by delivering on promises.",
"59.In the world of business, adapt or become obsolete.",
"60.Sales psychology: The power of persuasion is in demonstrating value.",
"61.In business, growth is the result of seeing obstacles as opportunities.",
"Sales psychology: The most persuasive message is one that aligns with the customer's story.",
"Your business's success is a reflection of your ability to evolve with your customers.",
"To cultivate business growth, focus on planting the seeds of exceptional service.",
"Sales psychology: The true art of selling is making customers feel like heroes of their stories.",
"In business, adaptability is the compass that guides you through uncharted territories.",
"Business growth flourishes when you nurture a culture of continuous improvement.",
"To master sales, dance to the rhythm of your customers' needs.",
"In the world of business, innovation is the wind beneath the wings of growth.",
"Sales psychology: Connect with hearts before you connect with wallets.",
"Business growth is the sweet fruit of strategic planning and diligent execution.",
"To thrive in sales, listen to your customers' silences; they often speak the loudest.",
"In business, the most resilient trees bear the sweetest fruits of success.",
"Sales psychology: Your customers are the heroes; your product is the magic wand.",
"Business growth is about creating a symphony of satisfied customers.",
"To succeed in sales, be the compass that guides customers toward their aspirations.",
"In the journey of business growth, courage is your steadfast companion.",
"Sales psychology: The art of influence is grounded in understanding, not manipulation.",
"Business growth thrives on a nourishing soil of innovation and customer-centricity.",
"To excel in sales, paint a canvas of possibilities for your customers.",
"In business, opportunities are like stars—they're always there, but sometimes hidden by clouds.",
"Sales psychology: The melody of persuasion is played on the strings of empathy.",
"Business growth is a reflection of your ability to turn customer needs into solutions.",
"To succeed in sales, be the bridge that connects dreams to reality.",
"In business, staying ahead is a dance of anticipation and agility.",
"Sales psychology: Trust is the currency; authenticity is the vault.",
"Business growth is not a destination; it's a journey of continuous reinvention.",
"To thrive in sales, see objections as stepping stones to understanding.",
"In the world of business, adaptation is the secret ingredient to the recipe of success.",
"Sales psychology: Illuminate the path to 'yes' by understanding the darkness of doubt.",
"In business, growth is the art of crafting solutions to the puzzles your customers present.",
"Sales psychology: The best salespeople are skilled storytellers who paint pictures of possibilities.",
"Your business's success is a symphony composed of customer satisfaction and innovation.",
"To foster business growth, be the architect of extraordinary experiences for your clients.",
"Sales psychology: The key to persuasion is making customers feel seen, heard, and valued.",
"In the world of business, adaptability is the compass that guides you through turbulent seas.",
"Business growth thrives when you embrace change as an invitation to evolve.",
"To master sales, cultivate a garden of trust and nourish it with authenticity.",
"In business, innovation is the heartbeat that keeps growth alive and thriving.",
"Sales psychology: The most potent sales pitch is the one that speaks to the heart's desires.",
"Business growth is a tapestry woven with threads of perseverance and vision.",
"To succeed in sales, be the bridge between customers' challenges and their aspirations.",
"In the journey of business growth, resilience is the companion that never leaves your side.",
"Sales psychology: The alchemy of persuasion is turning empathy into action.",
"Business growth is a garden; your customers are the seeds, and your service is the sunlight.",
"To excel in sales, learn to read the unspoken words between the lines of your customers' needs.",
"In business, courage is the fuel that propels you forward on the path of growth.",
"Sales psychology: The essence of selling is turning problems into opportunities.",
"Business growth thrives in the fertile soil of innovation and customer delight.",
"To succeed in sales, be the guide who leads customers to the treasure chest of solutions.",
"In business, opportunities often wear disguises; it's our job to unmask them.",
"Sales psychology: The language of persuasion is spoken fluently by those who truly listen.",
"Business growth is the canvas where your dreams become the brushstrokes of reality.",
"To thrive in sales, remember that trust is the bridge between hesitation and commitment.",
"In business, staying ahead is a dance of intuition and adaptation.",
"Sales psychology: The strongest connections are formed when you genuinely care.",
"Business growth is the music composed by your ability to orchestrate change.",
"To succeed in sales, be the translator who turns customer needs into a symphony of solutions.",
"In the world of business, adaptation is the cloak of invincibility.",
"Sales psychology: Persuasion is the art of turning desires into decisions.",
"In business, growth is the masterpiece painted with the brush of innovation.",
"Sales psychology: The magic of persuasion is revealing how your product can change lives.",
"Your business's success is the tapestry woven with threads of purpose and customer value.",
"To nurture business growth, be the architect of experiences that leave lasting impressions.",
"Sales psychology: The true art of selling is helping customers write their success stories.",
"In the world of business, adaptability is the compass guiding you through uncharted waters.",
"Business growth thrives when you embrace change as the catalyst for evolution.",
"To master sales, cultivate a garden of trust where authenticity is the water of life.",
"In business, innovation is the bridge that connects the present to the future.",
"Sales psychology: The most powerful sales pitches are symphonies of empathy.",
"Business growth is a symphony composed of strategy, dedication, and a customer-centric melody.",
"To succeed in sales, be the bridge that connects customer challenges to solutions.",
"In the journey of business growth, resilience is the guide through the valley of setbacks.",
"Sales psychology: The alchemy of persuasion is the transformation of empathy into action.",
"Business growth is a garden of opportunity where exceptional service is the harvest.",
"To excel in sales, learn to read between the lines of customer needs and desires.",
"In business, courage is the fuel that propels you forward on the highway to growth.",
"Sales psychology: The essence of selling is turning problems into pathways.",
"Business growth flourishes in the fertile soil of innovation and exceptional customer experiences.",
"To succeed in sales, be the guide who leads customers through the labyrinth of solutions.",
"In business, opportunities are treasures waiting to be uncovered in the sands of change.",
"Sales psychology: The art of persuasion is spoken fluently by those who genuinely listen.",
"Business growth is the canvas where your dreams are painted with the colors of determination.",
"To thrive in sales, remember that trust is the currency of lasting relationships.",
"In business, staying ahead is a dance of anticipation and adaptation.",
"Sales psychology: The strongest bonds are formed when you truly care about your customers.",
"Business growth is the symphony orchestrated by your ability to adapt and innovate.",
"To succeed in sales, be the translator who turns customer needs into the language of solutions.",
"In the world of business, adaptation is the armor that shields you from obscurity.",
"Sales psychology: Persuasion is the magic that turns desires into reality.",
"In business, growth is a mosaic crafted from the pieces of innovation.",
"Sales psychology: The secret to persuasion lies in revealing the transformative power of your product.",
"Your business's success is the symphony of customer satisfaction conducted by your team.",
"To nurture business growth, be the chef who serves up memorable experiences.",
"Sales psychology: The most compelling sales pitch is the one that writes a brighter future for your customers.",
"In the world of business, adaptability is the lantern guiding you through the darkest nights.",
"Business growth blossoms when change is embraced as an ally of evolution.",
"To master sales, cultivate a garden of trust where authenticity is the soil.",
"In business, innovation is the bridge to uncharted territories.",
"Sales psychology: The most impactful sales conversations are heart-to-heart.",
"Business growth is a mural painted with the strokes of strategy and dedication.",
"To succeed in sales, be the bridge that connects customer challenges to tailored solutions.",
"In the journey of business growth, resilience is the compass in the storm of setbacks.",
"Sales psychology: The alchemy of persuasion is the transformation of empathy into results.",
"Business growth is a thriving ecosystem nourished by exceptional service.",
"To excel in sales, listen not just to words but to the symphony of unspoken desires.",
"In business, courage is the fuel that ignites the engine of progress.",
"Sales psychology: The essence of selling is sculpting solutions from obstacles.",
"Business growth thrives in the fertile soil of innovation and customer delight.",
"To succeed in sales, be the guide who leads customers to their 'aha' moments.",
"In business, opportunities are hidden gems waiting to be uncovered.",
"Sales psychology: The art of persuasion is a dance of empathy and influence.",
"Business growth is the canvas where your aspirations meet the brush of determination.",
"To thrive in sales, remember that trust is the adhesive of lasting relationships.",
"In business, staying ahead is a symphony of anticipation and adaptation.",
"Sales psychology: The strongest bonds are formed when you genuinely care about your customers.",
"Business growth is the tapestry woven from the threads of adaptability and innovation.",
"To succeed in sales, be the translator who turns needs into tailored solutions.",
"In the world of business, adaptation is the key that unlocks the door to progress.",
"Sales psychology: Persuasion is the art of turning desires into decisions.",
"Business growth is the phoenix rising from the ashes of innovation.",
"Sales psychology: The melody of persuasion is crafted from notes of empathy and authenticity.",
"In business, success is a bridge built with the stones of determination and resilience.",
"To excel in sales, be the compass that guides customers through the maze of options.",
"Business growth is the fruit of constant innovation and customer satisfaction.",
"Sales psychology: The best conversations are those where you truly understand your customers.",
"In the journey of business growth, courage is the fuel that powers your dreams.",
"To succeed in sales, be the lighthouse guiding customers through the storm of choices.",
"Business growth thrives in the fertile soil of adaptability and customer-focused solutions.",
"Sales psychology: The most persuasive messages are those that resonate with the heart.",
"In business, opportunities are like stars—always present, sometimes hidden by clouds.",
"Business growth is the art of sculpting success from the marble of challenges.",
"To thrive in sales, be the guide who helps customers navigate the jungle of options.",
"Sales psychology: The most memorable salespeople are those who genuinely care.",
"In business, staying ahead is a dance of anticipation and adaptation.",
"Business growth is the symphony conducted by the conductor of change.",
"To succeed in sales, be the translator who turns needs into tailored solutions.",
"In the world of business, adaptation is the key that unlocks the door to progress.",
"Sales psychology: Persuasion is the magic that turns desires into decisions.",
"Business growth is the phoenix rising from the ashes of innovation.",
"In the world of business consultancy, success is measured by the solutions you provide, not the problems you find.",
"Business consultancy is the art of guiding clients from uncertainty to clarity.",
"Consultants are architects of change, designing blueprints for better businesses.",  
"In the realm of business consultancy, knowledge is your toolbox, and wisdom is your craft.",
"Consultancy is about uncovering hidden treasures in an organization's processes.",
"Consultants are the navigators helping businesses sail through turbulent waters.",
"Effective consultancy is a blend of expertise, empathy, and execution.",
"In business consultancy, your best investment is the trust you build with your clients.",
"Consultants are the bridge between where a business is and where it aspires to be.",
"Consultancy is a journey from problems to possibilities.",
"The consultancy process is a symphony of analysis, insight, and transformation.",
"In the world of business consultancy, curiosity is the compass guiding you to innovative solutions.",
"Consultants don't just solve problems; they ignite the sparks of innovation.",
"Consultancy is about translating challenges into stepping stones for growth.",
"In business consultancy, the key to success is turning 'I don't know' into 'I can help.'",
"Consultants are the detectives uncovering the mysteries of business improvement.",
"Consultancy is the art of painting a clear vision on the canvas of complexity.",
"In the realm of business consultancy, every challenge is an opportunity in disguise.",
"Consultants are the architects of organizational evolution.",
"Effective consultancy is a fusion of analytical thinking and human understanding.",
"In business consultancy, trust is the currency that drives lasting partnerships.",
"Consultants are the dreamweavers of corporate success.",
"Consultancy is the symphony where data, insights, and strategy harmonize.",
"In the world of business consultancy, change is the only constant.",
"Consultants are the magicians who transform obstacles into growth opportunities.",
"Consultancy is the journey from complexity to clarity.",
"In business consultancy, every challenge is a puzzle waiting to be solved.",
"Consultants are the puzzle solvers in the maze of business challenges.",
"Consultancy is the bridge connecting today's problems to tomorrow's solutions.",
"In the realm of business consultancy, every question is a doorway to innovation.",
"In the world of business, remember, the consumer is king, and their satisfaction should be your crown.",
"Consumer satisfaction is the North Star guiding businesses through the darkest of nights.",
"Treating consumers like royalty isn't just good service; it's good business.",
"The throne of success in business is reserved for those who prioritize the consumer.",
"In the kingdom of commerce, consumer trust is the most valuable treasure.",
"A satisfied consumer is your best marketing strategy.",
"In the eyes of the consumer, your product is only as good as the experience it delivers.",
"The most successful businesses build their empires on the foundation of happy consumers.",
"Never forget that in the business kingdom, the consumer holds the scepter of choice.",
"Consumer loyalty is the true crown jewel of any successful business.",
"In the age of information, a single consumer can sway the opinions of many. Treat each one like a king.",
"The consumer's voice is the heartbeat of your business; listen closely.",
"In the world of commerce, the consumer's smile is the ultimate currency.",
"Consumer-centricity isn't a trend; it's the key to long-term success.",
"In business, remember that consumers don't just buy products; they invest in experiences.",
"The most prosperous businesses are those that constantly seek to enhance the consumer's reign.",
"In the kingdom of commerce, the throne is only as strong as the loyalty of its subjects.",
"A satisfied consumer is a loyal ambassador for your brand.",
"In business, never underestimate the power of a delighted consumer to shape your destiny.",
"The consumer's journey is the map to business success; follow it closely.",
"Consumer satisfaction is the foundation upon which great empires of business are built.",
"In the realm of commerce, the consumer's verdict is the final judgment.",
"The consumer's experience should be at the heart of every business decision.",
"In business, the throne is only as secure as the loyalty of the consumers.",
"The consumer's trust is the most valuable asset any business can possess.",
"In the kingdom of commerce, the consumer's happiness is the ultimate victory.",
"A satisfied consumer is the greatest defender of your brand.",
"In business, the consumer isn't just a king; they are the kingdom.",
"Remember, in the grand story of commerce, the consumer is the protagonist.",
"In the world of finance consulting, wisdom is the currency of value.",
"Finance consultants are the navigators in the complex sea of financial decisions.",
"Success in finance consulting comes from turning numbers into narratives of growth.",
"The true essence of finance consulting lies in helping clients secure their financial future.",
"In the realm of finance, consultants are the architects of fiscal well-being.",
"Effective finance consulting is about illuminating the path to financial prosperity.",
"Finance consultants are the financial doctors, diagnosing and curing financial ailments.",
"In the world of finance consulting, trust is the cornerstone of every solid financial plan.",
"Consultants in finance are the bridge between financial chaos and financial clarity.",
"Finance consultants don't just manage money; they manage dreams and aspirations.",
"In finance consulting, knowledge is power, and understanding is the key.",
"Consultants in finance are the architects who design financial stability.",
"In the realm of finance consulting, every decision is a step toward financial freedom.",
"Finance consultants are the guides who lead clients to the summit of financial success.",
"Success in finance consulting is about turning financial challenges into opportunities.",
"In the world of finance, consultants are the storytellers of financial success stories.",
"Consultants in finance are the custodians of financial peace of mind.",
"In finance consulting, the journey from financial uncertainty to financial confidence begins.",
"Finance consultants are the conductors of the financial symphony of life.",
"In the realm of finance consulting, financial clarity is the destination.",
"Finance consultants are the translators who decode the language of finance for their clients.",
"In the world of finance, consultants are the architects of financial security.",
"Consultants in finance are the builders of financial legacies.",
"In finance consulting, every financial decision should align with long-term financial goals.",
"Finance consultants are the guides who help clients navigate the financial landscape.",
"In the realm of finance consulting, financial success is the result of strategic decisions.",
"Consultants in finance are the craftsmen who sculpt financial futures.",
"In finance consulting, the path to financial success is paved with informed decisions.",
"Finance consultants are the allies who empower clients to take control of their finances.",
"In the world of finance, consultants are the guardians of financial well-being.",
"A well-crafted business strategy is the roadmap to success.",
"In business, a clear strategy is the anchor in a sea of uncertainty.",
"Strategy is not just about where you want to go; it's about how you'll get there.",
"A great business strategy turns dreams into achievable goals.",
"The heart of business success beats with a well-executed strategy.",
"Strategy is the compass that keeps a business heading in the right direction.",
"In the world of business, strategy separates the leaders from the followers.",
"A solid strategy is the foundation upon which great businesses are built.",
"Strategy is the bridge between vision and execution.",
"In business, a well-thought-out strategy is a competitive advantage.",
"Strategy is the art of making deliberate choices to create a winning path.",
"A strong business strategy is like a puzzle; each piece contributes to the bigger picture.",
"In business, strategy is not a one-time event; it's a continuous journey.",
"Strategy is about focusing on what matters most to achieve your goals.",
"A sound strategy can turn challenges into stepping stones.",
"In the world of business, strategy is the rudder that steers the ship.",
"Strategy is not just about being better; it's about being different.",
"A well-executed strategy can turn weaknesses into strengths.",
"In business, strategy is the secret sauce that fuels growth.",
"Strategy is the guide that helps businesses navigate through complexity.",
"A successful strategy is the result of thoughtful planning and relentless execution.",
"Strategy is about making choices that create a sustainable competitive advantage.",
"In the world of business, strategy is the art of outthinking the competition.",
"A winning strategy is a blend of vision, creativity, and discipline.",
"Strategy is about turning ideas into actions.",
"In business, a robust strategy is a shield against uncertainty.",
"Strategy is the North Star that guides a business toward its vision.",
"A well-communicated strategy aligns teams and empowers them to achieve greatness.",
"Strategy is the catalyst for innovation and progress in business.",
"In the world of business, strategy is the heartbeat of sustainable success.",
"Artificial Intelligence: where human ingenuity meets machine learning.",
"In the world of technology, Artificial Intelligence is the game-changer.",
"AI is the canvas upon which the future of innovation is painted.",
"Artificial Intelligence: turning data into insights, and insights into action.",
"In the age of AI, the possibilities are as limitless as the human imagination.",
"AI is not just a tool; it's a revolution reshaping industries.",
"Artificial Intelligence is the bridge between the present and the future.",
"In the world of technology, AI is the master key unlocking new realms of possibility.",
"AI is the magician that turns data into meaningful solutions.",
"Artificial Intelligence: where data-driven decisions become a competitive advantage.",
"Innovation and AI go hand in hand, transforming industries one algorithm at a time.",
"AI is the fuel powering the engines of automation and efficiency.",
"Artificial Intelligence is not a threat; it's a tool for empowerment.",
"In the age of AI, human creativity becomes the architect of intelligent systems.",
"AI is the compass that guides us through the vast ocean of data.",
"Artificial Intelligence: turning science fiction into reality.",
"AI is the silent partner in the evolution of technology.",
"In the world of innovation, AI is the north star showing us the way.",
"AI is the flashlight illuminating the dark corners of big data.",
"Artificial Intelligence: where human intelligence meets machine precision.",
"In the age of AI, data is the currency and algorithms are the traders.",
"AI is the orchestra conductor turning data into a symphony of insights.",
"Artificial Intelligence is not about replacing humans; it's about enhancing human capabilities.",
"AI is the storyteller weaving narratives from data.",
"In the world of technology, AI is the catalyst for transformation.",
"AI is the lens through which we see the hidden patterns in data.",
"Artificial Intelligence: where innovation and intelligence converge.",
"In the age of AI, the future is not a mystery; it's a prediction.",
"AI is the magician's wand turning data into actionable insights.",
"Artificial Intelligence: where the possible becomes probable and the probable becomes reality.",
"Employee coaching is not just about improving skills; it's about nurturing potential.",
"In the world of leadership, coaching is the bridge to empowerment.",
"Coaching is the art of unlocking greatness in every team member.",
"In the realm of professional development, coaching is the compass guiding growth.",
"Effective coaching is a journey from where you are to where you want to be.",
"Coaching is about helping employees discover their strengths and overcome their challenges.",
"In the workplace, coaching is the magic wand that turns potential into performance.",
"Coaching is not a one-time event; it's a continuous investment in growth.",
"In the world of leadership, great coaches build great teams.",
"Coaching is the spark that ignites motivation and self-improvement.",
"Coaching is the language of progress in the workplace.",
"In the realm of employee development, coaching is the key to unlocking excellence.",
"Coaching is about transforming 'I can't' into 'I can.'",
"Coaching is the bridge between goals and achievement.",
"In the world of leadership, great coaches inspire greatness in others.",
"Coaching is the art of turning potential into performance.",
"Coaching is not just about teaching; it's about helping employees become the best version of themselves.",
];

const ThoughtOfTheDay = () => {
  const [thought, setThought] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  
  const ThoughtcontainerRef = useRef(null);

  useEffect(() => {
    const now = new Date();
    const start = new Date(2023, 9, 15); 
    const diffInDays = Math.floor((now - start) / (1000 * 60 * 60 * 24));
    const dayIndex = diffInDays % 365;

    const thoughtText = thoughts[dayIndex].replace(/^\d+\.\s*/, "");
    setThought(thoughtText);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setTypingIndex(0);
      }
    });

    if (ThoughtcontainerRef.current) {
      observer.observe(ThoughtcontainerRef.current);
    }

    return () => {
      if (ThoughtcontainerRef.current) {
        observer.unobserve(ThoughtcontainerRef.current);
      }
    };
  }, [ThoughtcontainerRef]);

  useEffect(() => {
    if (typingIndex < thought.length) {
      const interval = setInterval(() => {
        setTypingIndex(prevIndex => prevIndex + 1);
      }, 100);

      return () => clearInterval(interval);
    }
  }, [typingIndex, thought]);

  return (
    <div className="Thought-of-day" ref={ThoughtcontainerRef}>
      <h2>Thought Of The Day</h2>
      <p>{thought.substring(0, typingIndex)}</p>
    </div>
  );
};

export default ThoughtOfTheDay;
