import React, { useRef, useEffect } from 'react';
import './FirstBlog.css';
import AIJOB from './images/AIjob.png'
const FirstBlog = () => {
    const contentRef = useRef(null);
    const scrollThumbRef = useRef(null);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbRef.current.style.height = `${thumbHeight}px`;
        scrollThumbRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * (clientHeight - thumbHeight)}px)`;
    };

    // Set the initial height of the scroll thumb when the component mounts
    useEffect(() => {
        handleScroll();
    }, []);

    return (
        <div className="firstBlog-wrapper">
            <div className="firstBlog-container" onScroll={handleScroll} ref={contentRef}>
            <h1>Will AI Take Over Jobs? Navigating the Nuances</h1>
            <h5 className='First-Blog-Date-Time'> Written on 25 October,2023   |      Reading Time : 2min </h5>
            <img src={AIJOB} alt="Description of AIjob" />
            <h2>Introduction:</h2>
            <p className='firstblogContext'>The whispers of <strong>"Will AI take over jobs?"</strong>  reverberate through conference rooms, classrooms, and coffee shops. While dystopian novels and movies prophesy a world dominated by machines, the true picture has shades of both challenge and promise. This enriched exploration promises you not just an overview, but a roadmap for the AI-driven job market.</p>

            <h2>A Journey Through Time:</h2>
            <p className='firstblogContext'>Every transformative phase in history, from the invention of the wheel to the digital revolution, has ignited fears of obsolescence. But with each shift, society didn't just survive; it evolved. The key has always been adaptability.
</p>

            <h2>Unpacking AI's Impact on Jobs:</h2>
            <ul>
                <li> <strong>➡ Jobs at Immediate Risk:</strong> AI thrives on repetition. Tasks like data entry or basic inventory management, which follow a predictable pattern, are low-hanging fruits for automation. For instance, cashier roles are being transformed with automated checkout systems.</li>
                <li><strong>➡ Jobs in the Gray Zone:</strong> Consider diagnostic medicine. While AI can analyze medical images, the physician's holistic understanding of a patient's health is irreplaceable. Here, AI augments, not replaces.</li>
                <li className='firstblogContext'><strong>➡ The Resilient Roles:</strong>  Creativity, empathy, and intricate human interaction are arenas where AI has limited reach. Be it the nuanced creativity of a musician or the empathetic touch of a nurse, humans reign supreme.</li>
            </ul>

            <h2>Busting the AI Takeover Myth:</h2>
            <ul>
            <li> <strong>➡ AI's Contextual Limitations:</strong> Recent gaffes by AI chatbots missing social nuances underline that understanding context is complex.
</li>
                <li><strong>➡ Ethical Boundaries:</strong> Imagine law enforcement. While AI can assist with data analysis, ethical decisions about, say, arrest and use of force remain deeply human.
</li>
                <li className='firstblogContext'><strong >➡ The Resilient Roles:</strong>  Many sectors thrive on genuine human interaction. Would you trust an AI mediator during a sensitive business merger or an AI counselor during a personal crisis?
</li>
            </ul>

            <h2>The Bright Horizon - Opportunities Galore:</h2>
            <ul>
            <li> <strong>➡ New Career Pathways:</strong> The AI revolution is birthing careers like AI ethics manager, machine learning trainer, or AI integration specialist.
</li>
                <li><strong>➡ Boosting Efficiency: </strong> Mundane tasks can be AI's domain, freeing us to focus on innovation. For instance, AI-driven data analytics can provide insights, but humans strategize the business direction.

</li>
                <li className='firstblogContext'><strong>➡ The Learning Curve: </strong>  Modern careers will center around adaptability. Platforms like Coursera or Udemy already offer AI-centric courses, emphasizing the demand for continuous learning.

</li>
            </ul>

            <h2>Charting a Course in the AI Landscape:</h2>
            <ul>
            <li> <strong>➡ Stay Proactive:</strong> Regularly attending seminars or workshops related to AI in your industry can give you foresight.
</li>
                <li><strong>➡ Soft Skills are Gold: </strong> A study by McKinsey suggests that by 2030, soft skills will be in high demand due to AI's growth. Enhancing skills like leadership, emotional intelligence, and collaboration can be pivotal.


</li>
                <li className='firstblogContext'><strong>➡ Collaborate with AI: </strong>  Embrace AI tools that can enhance your job. For writers, there's Grammarly; for salespeople, there's chatbots, and the list goes on.


</li>
            </ul>

            <h2>Conclusion:</h2>
            <p>The AI-driven future isn't a bleak landscape of machines replacing humans, but a canvas of collaboration. It's about co-evolution. By harnessing the strengths of both AI and human ingenuity, we can craft a future of unparalleled progress and shared prosperity. Embrace, evolve, and thrive in this exciting era!</p>
            </div>
            <div className="faux-scrollbar">
                <div className="faux-thumb" ref={scrollThumbRef}></div>
            </div>
        </div>
    );
}

export default FirstBlog;
