import React, { useRef, useEffect } from 'react';
import './ReactFitnessTrackerCaseStudy.css';
import ReactFitnessTrackerCaseStudyImage from './images/ReactNativeFitnessTracker.png';
import EcomCaseStudyImage from './images/SEO2ndcasestudy.png'; // Make sure to use the correct path and file name

const ReactFitnessTrackerCaseStudy = () => {
    const contentRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const ReactFitnessTrackerCaseStudysolutionRef= useRef(null);
    const ReactFitnessTrackerCaseStudychallengesRef= useRef(null);
    const ReactFitnessTrackerCaseStudybrandRef= useRef(null);
    const ReactFitnessTrackerCaseStudyobjectiveRef= useRef(null);
    const ReactFitnessTrackerCaseStudydetailsRef= useRef(null);
    const EcomCaseStudyImageRef= useRef(null);
    const ReactFitnessTrackerCaseStudyResultRef= useRef(null);

    
    const headingRef = useRef(null); 
    const solutionImageRef = useRef(null);
    const  ReactFitnessTrackerCaseStudyintroRef= useRef(null);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbRef.current.style.height = `${thumbHeight}px`;
        scrollThumbRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * 100}%)`;
    };
    useEffect(() => {
        // ... other useEffect contents

        // Intersection Observer for scroll animations
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is in the viewport
            }
        );

        const headingEl = headingRef.current;
        if (headingEl) {
            observer.observe(headingEl);
        }
        const EcomCaseStudyImage = EcomCaseStudyImageRef.current;
        if (EcomCaseStudyImage) {
            observer.observe(EcomCaseStudyImage);
        }
        const ReactFitnessTrackerCaseStudyintro = ReactFitnessTrackerCaseStudyintroRef.current;
        if (ReactFitnessTrackerCaseStudyintro) {
            observer.observe(ReactFitnessTrackerCaseStudyintro);
        }
        const ReactFitnessTrackerCaseStudydetails = ReactFitnessTrackerCaseStudydetailsRef.current;
        if (ReactFitnessTrackerCaseStudydetails) {
            observer.observe(ReactFitnessTrackerCaseStudydetails);
        }
        const ReactFitnessTrackerCaseStudybrand = ReactFitnessTrackerCaseStudybrandRef.current;
        if (ReactFitnessTrackerCaseStudybrand) {
            observer.observe(ReactFitnessTrackerCaseStudybrand);
        }
        const ReactFitnessTrackerCaseStudyobjective = ReactFitnessTrackerCaseStudyobjectiveRef.current;
        if (ReactFitnessTrackerCaseStudyobjective) {
            observer.observe(ReactFitnessTrackerCaseStudyobjective);
        }
        const ReactFitnessTrackerCaseStudychallenges = ReactFitnessTrackerCaseStudychallengesRef.current;
        if (ReactFitnessTrackerCaseStudychallenges) {
            observer.observe(ReactFitnessTrackerCaseStudychallenges);
        }
        const ReactFitnessTrackerCaseStudysolution = ReactFitnessTrackerCaseStudysolutionRef.current;
        if (ReactFitnessTrackerCaseStudysolution) {
            observer.observe(ReactFitnessTrackerCaseStudysolution);
        }
        const solutionImg = solutionImageRef.current;
        if (solutionImg) {
            observer.observe(solutionImg);
        }
        const ReactFitnessTrackerCaseStudyResult = ReactFitnessTrackerCaseStudyResultRef.current;
        if (ReactFitnessTrackerCaseStudyResult) {
            observer.observe(ReactFitnessTrackerCaseStudyResult);
        }

        // Cleanup observer on component unmount
        return () => {
            if (headingEl) {
                observer.unobserve(headingEl);
            }
            if (EcomCaseStudyImage) {
                observer.unobserve(EcomCaseStudyImage);
            }
            if (ReactFitnessTrackerCaseStudyintro) {
                observer.unobserve(ReactFitnessTrackerCaseStudyintro);
            }
            if (ReactFitnessTrackerCaseStudydetails) {
                observer.unobserve(ReactFitnessTrackerCaseStudydetails);
            }
            if (ReactFitnessTrackerCaseStudybrand) {
                observer.unobserve(ReactFitnessTrackerCaseStudybrand);
            }
            if (ReactFitnessTrackerCaseStudyobjective) {
                observer.unobserve(ReactFitnessTrackerCaseStudyobjective);
            }
            if (ReactFitnessTrackerCaseStudychallenges) {
                observer.unobserve(ReactFitnessTrackerCaseStudychallenges);
            }
            if (ReactFitnessTrackerCaseStudysolution) {
                observer.unobserve(ReactFitnessTrackerCaseStudysolution);
            }
            if (solutionImg) {
                observer.unobserve(solutionImg);
            }
            if (ReactFitnessTrackerCaseStudyResult) {
                observer.unobserve(ReactFitnessTrackerCaseStudyResult);
            }
        };
    }, []);
   
    useEffect(() => {
        handleScroll();
        // Adding event listener for the cleanup
        return () => contentRef.current?.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="ReactFitnessTrackerCaseStudy-wrapper">
      
            <div className="ReactFitnessTrackerCaseStudy-container"  onScroll={handleScroll} ref={contentRef}>
            <h1 className="ReactFitnessTrackerCaseStudy-heading"  ref={headingRef} >React Native Case Study: Fitness Tracker Mobile App
</h1>
            <img src={ReactFitnessTrackerCaseStudyImage} alt="E-Commerce Case Study" ref={EcomCaseStudyImageRef} className="ReactFitnessTrackerCaseStudy-image"  data-aos="fade-up"/>
            <p className="ReactFitnessTrackerCaseStudy-intro" ref={ReactFitnessTrackerCaseStudyintroRef}>
            Unlocking the power of tech to help people stay fit with our Fitness tracker app.

            </p>
              {/* Project Details Section */}
            <div className="ReactFitnessTrackerCaseStudy-details" ref={ReactFitnessTrackerCaseStudydetailsRef}>
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">Project Overview</h2>
                <p><strong>Industry:</strong> Healthcare</p>
                <p><strong>Services:</strong> Mobile App Design, App Development (Android & iOS)

</p>
                <p><strong>Technologies:</strong> React Native, Node JS, Kotlin, Swift 
</p>
                <p><strong>Project Team:</strong> MERN Developer, Tester, Designer
</p>
                <p><strong>Country:</strong> Canada</p>
                <p><strong>Duration:</strong> 3 Months</p>
            </div>
            {/* About the Brand Section */}
            <div className="ReactFitnessTrackerCaseStudy-brand" ref={ReactFitnessTrackerCaseStudybrandRef}>
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">About the Brand</h2>
                <p>
                The client is a Canadian fitness-savvy company headquartered in Canada with hundreds of fitness clubs, gyms, and clinics. They were looking to develop a custom fitness app for their brand.
                </p> 
            </div>
            {/* Business Objective/Project Background Section */}
            <div className="ReactFitnessTrackerCaseStudy-objective" ref={ReactFitnessTrackerCaseStudyobjectiveRef}>
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">Business Objective/Project Background</h2>
                <ul>
                Initially, we had a discussion with the IT department to understand the requirements and goals. The client wanted to develop a fitness app that allows users to track fitness data like activity tracking data, track footsteps, get rewards, manage profiles and integrate data with smartwatches. The prime objectives were: <br/><br/>

                    <li>Conduct hybrid mobile app development for Android and iOS platforms with React Native.</li>
                    <li>To create a new design for the application.</li>
                    <li>Perform Integration with third-party applications.</li>
                    <li>An intuitive application that enables users to monitor and manage all fitness data in one place.</li>
                    
                    
                </ul>
            </div>
            {/* Challenges Section */}
            <div className="ReactFitnessTrackerCaseStudy-challenges" ref={ReactFitnessTrackerCaseStudychallengesRef}>
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">Challenges</h2>
                <ul>
                    <li>We had a strict timeline of 3 months to develop the app.</li>
                    <li>There was no design specification, we had to start from scratch.</li>
                    <li>Integrating multiple applications with the app.</li>
                    <li>Develop a scalable app, so it is easy to maintain and expand in the future.</li>
                    <li>Cross-platform development to deliver the app for both iOS and Android.</li>
                    
                </ul>
            </div>
            {/* Our Solution Section */}
            <div className="ReactFitnessTrackerCaseStudy-solution" ref={ReactFitnessTrackerCaseStudysolutionRef} >
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">Our Solution</h2>
                <p>As we were under a rigid deadline, our team after long hours of research decided to choose a react native platform to develop the app for the client, we reused almost 90% of the code for iOS and Android app development. Here’s how we developed the mobile for the client:</p>
                <p><strong className='ReactFitnessTrackerCaseStudy-strong'  >Wireframing & Prototyping:</strong> This first step was to understand the requirements and UX analysis. After collecting all the information, our team designed interactive prototypes and wireframes.</p>
                <p><strong className='ReactFitnessTrackerCaseStudy-strong'  >Design:</strong> Our UI/UX designer selected the primary/secondary colours & typography after the client’s approval for the app design. We designed a visually stunning, modern, and clean interface for the app.</p>
                <p><strong className='ReactFitnessTrackerCaseStudy-strong'  >Development:</strong> Our experienced React Native developers implemented all the required features in the app and seamlessly integrated APIs. Additionally, developed reusable components, code, and layout with React Native.</p>
                <p><strong className='ReactFitnessTrackerCaseStudy-strong'  >Testing & Delivery:</strong> We allocated our skilled testers on the project to ensure the app is thoroughly tested. They analysed the functionalities, usability, and consistency of the app. We finally deployed the app at Google Play Store and Apple store.</p>
                
            </div>
            {/* Result */}
            <div className="ReactFitnessTrackerCaseStudy-solution" ref={ReactFitnessTrackerCaseStudyResultRef} >
                <h2 className="ReactFitnessTrackerCaseStudy-subheading">Results</h2>
                <p>The fitness tracking app has been made to cover activity tracking by presenting insightful data analytics on activities. Also, to earn some rewards for the efforts that the app users have put in every activity and their entire performance.</p>
                <ul>
                    <li>We developed a robust and intuitive application with a captivating UI and covered tiny details that ensure the best UX.</li>
                    <li>We were able to deliver a high-performance and feature-rich app within the defined budget and timeline.</li>
                    <li>90% of the code is reusable and can be used in future enhancements.
</li>
                    <li>The apps were successfully published on the Android and iOS platforms.
</li>
                    <li>We continued to optimise the application and upgraded it with new features.
</li>
                    
                </ul>
                
            </div>
           
            </div>
            <div className="ReactFitnessTrackerCaseStudy-custom-scrollbar">
                <div className="ReactFitnessTrackerCaseStudy-custom-thumb" ref={scrollThumbRef}></div>
            </div>
        </div>
    );
}

export default ReactFitnessTrackerCaseStudy;
