import React, { useRef, useEffect } from 'react';
import './WordPressEcomCasestudy.css';
import WordPressEcomCasestudyImage from './images/SEO1.png';
import EcomCaseStudyImage from './images/SEO1stcasestudy.png'; // Make sure to use the correct path and file name
import AOS from 'aos';
const WordPressEcomCasestudy = () => {
    const contentRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const WordPressEcomCasestudysolutionRef= useRef(null);
    const WordPressEcomCasestudychallengesRef= useRef(null);
    const WordPressEcomCasestudybrandRef= useRef(null);
    const WordPressEcomCasestudyobjectiveRef= useRef(null);
    const WordPressEcomCasestudydetailsRef= useRef(null);
    const EcomCaseStudyImageRef= useRef(null);
    const headingRef = useRef(null); 
    const solutionImageRef = useRef(null);
    const  WordPressEcomCasestudyintroRef= useRef(null);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbRef.current.style.height = `${thumbHeight}px`;
        scrollThumbRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * 100}%)`;
    };
    useEffect(() => {
        // ... other useEffect contents

        // Intersection Observer for scroll animations
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is in the viewport
            }
        );

        const headingEl = headingRef.current;
        if (headingEl) {
            observer.observe(headingEl);
        }
        const EcomCaseStudyImage = EcomCaseStudyImageRef.current;
        if (EcomCaseStudyImage) {
            observer.observe(EcomCaseStudyImage);
        }
        const WordPressEcomCasestudyintro = WordPressEcomCasestudyintroRef.current;
        if (WordPressEcomCasestudyintro) {
            observer.observe(WordPressEcomCasestudyintro);
        }
        const WordPressEcomCasestudydetails = WordPressEcomCasestudydetailsRef.current;
        if (WordPressEcomCasestudydetails) {
            observer.observe(WordPressEcomCasestudydetails);
        }
        const WordPressEcomCasestudybrand = WordPressEcomCasestudybrandRef.current;
        if (WordPressEcomCasestudybrand) {
            observer.observe(WordPressEcomCasestudybrand);
        }
        const WordPressEcomCasestudyobjective = WordPressEcomCasestudyobjectiveRef.current;
        if (WordPressEcomCasestudyobjective) {
            observer.observe(WordPressEcomCasestudyobjective);
        }
        const WordPressEcomCasestudychallenges = WordPressEcomCasestudychallengesRef.current;
        if (WordPressEcomCasestudychallenges) {
            observer.observe(WordPressEcomCasestudychallenges);
        }
        const WordPressEcomCasestudysolution = WordPressEcomCasestudysolutionRef.current;
        if (WordPressEcomCasestudysolution) {
            observer.observe(WordPressEcomCasestudysolution);
        }
        const solutionImg = solutionImageRef.current;
        if (solutionImg) {
            observer.observe(solutionImg);
        }

        // Cleanup observer on component unmount
        return () => {
            if (headingEl) {
                observer.unobserve(headingEl);
            }
            if (EcomCaseStudyImage) {
                observer.unobserve(EcomCaseStudyImage);
            }
            if (WordPressEcomCasestudyintro) {
                observer.unobserve(WordPressEcomCasestudyintro);
            }
            if (WordPressEcomCasestudydetails) {
                observer.unobserve(WordPressEcomCasestudydetails);
            }
            if (WordPressEcomCasestudybrand) {
                observer.unobserve(WordPressEcomCasestudybrand);
            }
            if (WordPressEcomCasestudyobjective) {
                observer.unobserve(WordPressEcomCasestudyobjective);
            }
            if (WordPressEcomCasestudychallenges) {
                observer.unobserve(WordPressEcomCasestudychallenges);
            }
            if (WordPressEcomCasestudysolution) {
                observer.unobserve(WordPressEcomCasestudysolution);
            }
            if (solutionImg) {
                observer.unobserve(solutionImg);
            }
        };
    }, []);
    useEffect(() => {
        AOS.init({
            duration: 1200, // values from 0 to 3000, with step 50ms
            once: true, // whether animation should happen only once - while scrolling down
            // More options can be added here
        });
    }, []);
    useEffect(() => {
        handleScroll();
        // Adding event listener for the cleanup
        return () => contentRef.current?.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="WordPressEcomCasestudy-wrapper">
      
            <div className="WordPressEcomCasestudy-container"  onScroll={handleScroll} ref={contentRef}>
            <h1 className="WordPressEcomCasestudy-heading"  ref={headingRef} >WordPress Case Study: E-Commerce Web Development</h1>
            <img src={EcomCaseStudyImage} alt="E-Commerce Case Study" ref={EcomCaseStudyImageRef} className="WordPressEcomCasestudy-image"  />
            <p className="WordPressEcomCasestudy-intro" ref={WordPressEcomCasestudyintroRef}>
                How our team developed a modern, intuitive and scalable e-commerce website for a UAE-based client on WordPress CMS.
            </p>
              {/* Project Details Section */}
            <div className="WordPressEcomCasestudy-details" ref={WordPressEcomCasestudydetailsRef}>
                <h2 className="WordPressEcomCasestudy-subheading">Project Overview</h2>
                <p><strong>Industry:</strong> Eyewear</p>
                <p><strong>Services:</strong> Website Design, Website Development, WooCommerce Integration, SEO Optimization</p>
                <p><strong>Technologies:</strong> WordPress, HTML, CSS, WooCommerce, Google Tag Manager</p>
                <p><strong>Plugins:</strong> WooCommerce, Elementor, Google Maps API, Yoast SEO</p>
                <p><strong>Project Team:</strong> Designer, WordPress Developer, SEO Specialist</p>
            </div>
            {/* About the Brand Section */}
            <div className="WordPressEcomCasestudy-brand" ref={WordPressEcomCasestudybrandRef}>
                <h2 className="WordPressEcomCasestudy-subheading">About the Brand</h2>
                <p>
                    Our customer is one of the leading eyewear brands based in the UAE. The company offers a broad range of sunglasses, designer glasses, and optical solutions catered to a diverse clientele. With a commitment to quality and customer satisfaction, the brand has established itself as a trusted provider of fashionable and functional eyewear.
                </p>
            </div>
            {/* Business Objective/Project Background Section */}
            <div className="WordPressEcomCasestudy-objective" ref={WordPressEcomCasestudyobjectiveRef}>
                <h2 className="WordPressEcomCasestudy-subheading">Business Objective/Project Background</h2>
                <ul>
                    <li>Develop a custom E-commerce website.</li>
                    <li>Create a better user experience to generate more revenue via the website.</li>
                    <li>Responsive website development for a mobile-friendly user experience.</li>
                    <li>Incorporate advanced search and filtering.</li>
                    <li>Integration of WordPress with WooCommerce.</li>
                    <li>Admin Interface for marketers and operations manager.</li>
                </ul>
            </div>
            {/* Challenges Section */}
            <div className="WordPressEcomCasestudy-challenges" ref={WordPressEcomCasestudychallengesRef}>
                <h2 className="WordPressEcomCasestudy-subheading">Challenges</h2>
                <ul>
                    <li>The biggest challenge was the project delivery timeline. We had only 2 months to complete the website.</li>
                    <li>Designing responsive and modern web design for the website.</li>
                    <li>Developing a custom admin panel to allow easy content updates and additions.</li>
                    <li>Development of an inventory and order management system.</li>
                    <li>Third-party payment integration.</li>
                </ul>
            </div>
            {/* Our Solution Section */}
            <div className="WordPressEcomCasestudy-solution" ref={WordPressEcomCasestudysolutionRef} >
                <h2 className="WordPressEcomCasestudy-subheading">Our Solution</h2>
                <p><strong>✓ Design & WordPress Development</strong></p>
                <p>
                    Our team spent the initial days understanding the brand's personality to design the website. Our team developed multiple prototypes for the client, with one finally being selected. The site was developed using Elementor, designing multiple pages with attention to detail.
                </p>
                <p><strong>✓ WooCommerce Implementation</strong></p>
                <p>
                    The online store was powered by WooCommerce, enabling easy management and addition of products. We included shipping features, flexible payment gateways, a custom dashboard, and inventory synchronization.
                </p>
                <p><strong>✓ Google Analytics</strong></p>
                <p>
                    Our team installed and configured Google Analytics to track conversions and goals, allowing the client to analyze web traffic and optimize ad spending effectively.
                </p>
                <p><strong>✓ SEO Optimization</strong></p>
                <p>
                    Pages were optimized for SEO using Yoast SEO. This involved generating XML sitemaps, creating custom Meta Headers/Descriptions, optimizing URLs, and enhancing page speed.
                </p>
            </div>
            {/* Image after Our Solution Section */}
            <img 
                src={WordPressEcomCasestudyImage} 
                alt="Detailed view of the solution" 
                className="WordPressEcomCasestudy-solutionImage"
                ref={solutionImageRef}
            />
            </div>
            <div className="WordPressEcomCasestudy-custom-scrollbar">
                <div className="WordPressEcomCasestudy-custom-thumb" ref={scrollThumbRef}></div>
            </div>
        </div>
    );
}

export default WordPressEcomCasestudy;
