import React, { useRef, useEffect } from 'react';
import './E-WalletAppCaseStudy.css';
import EWalletAppCaseStudyImage from './images/E-WalletCaseStudy.png';
import EcomCaseStudyImage from './images/SEO2ndcasestudy.png'; // Make sure to use the correct path and file name

const EWalletAppCaseStudy = () => {
    const contentRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const EWalletAppCaseStudysolutionRef= useRef(null);
    const EWalletAppCaseStudychallengesRef= useRef(null);
    const EWalletAppCaseStudybrandRef= useRef(null);
    const EWalletAppCaseStudyobjectiveRef= useRef(null);
    const EWalletAppCaseStudydetailsRef= useRef(null);
    const EcomCaseStudyImageRef= useRef(null);
    const headingRef = useRef(null); 
    const solutionImageRef = useRef(null);
    const  EWalletAppCaseStudyintroRef= useRef(null);
    const   EWalletAppCaseStudyKeyFeaturesRef= useRef(null);
    const   EWalletAppCaseStudyResultRef= useRef(null);
    
   

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbRef.current.style.height = `${thumbHeight}px`;
        scrollThumbRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * 100}%)`;
    };
    useEffect(() => {
        // ... other useEffect contents

        // Intersection Observer for scroll animations
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is in the viewport
            }
        );

        const headingEl = headingRef.current;
        if (headingEl) {
            observer.observe(headingEl);
        }
        const EcomCaseStudyImage = EcomCaseStudyImageRef.current;
        if (EcomCaseStudyImage) {
            observer.observe(EcomCaseStudyImage);
        }
        const EWalletAppCaseStudyintro = EWalletAppCaseStudyintroRef.current;
        if (EWalletAppCaseStudyintro) {
            observer.observe(EWalletAppCaseStudyintro);
        }
        const EWalletAppCaseStudydetails = EWalletAppCaseStudydetailsRef.current;
        if (EWalletAppCaseStudydetails) {
            observer.observe(EWalletAppCaseStudydetails);
        }
        const EWalletAppCaseStudybrand = EWalletAppCaseStudybrandRef.current;
        if (EWalletAppCaseStudybrand) {
            observer.observe(EWalletAppCaseStudybrand);
        }
        const EWalletAppCaseStudyobjective = EWalletAppCaseStudyobjectiveRef.current;
        if (EWalletAppCaseStudyobjective) {
            observer.observe(EWalletAppCaseStudyobjective);
        }
        const EWalletAppCaseStudychallenges = EWalletAppCaseStudychallengesRef.current;
        if (EWalletAppCaseStudychallenges) {
            observer.observe(EWalletAppCaseStudychallenges);
        }
        const EWalletAppCaseStudysolution = EWalletAppCaseStudysolutionRef.current;
        if (EWalletAppCaseStudysolution) {
            observer.observe(EWalletAppCaseStudysolution);
        }
        const solutionImg = solutionImageRef.current;
        if (solutionImg) {
            observer.observe(solutionImg);
        }
        const EWalletAppCaseStudyKeyFeatures = EWalletAppCaseStudyKeyFeaturesRef.current;
        if (EWalletAppCaseStudyKeyFeatures) {
            observer.observe(EWalletAppCaseStudyKeyFeatures);
        }
        const  EWalletAppCaseStudyResult =  EWalletAppCaseStudyResultRef.current;
        if ( EWalletAppCaseStudyResult) {
            observer.observe( EWalletAppCaseStudyResult);
        }

        // Cleanup observer on component unmount
        return () => {
            if (headingEl) {
                observer.unobserve(headingEl);
            }
            if (EcomCaseStudyImage) {
                observer.unobserve(EcomCaseStudyImage);
            }
            if (EWalletAppCaseStudyintro) {
                observer.unobserve(EWalletAppCaseStudyintro);
            }
            if (EWalletAppCaseStudydetails) {
                observer.unobserve(EWalletAppCaseStudydetails);
            }
            if (EWalletAppCaseStudybrand) {
                observer.unobserve(EWalletAppCaseStudybrand);
            }
            if (EWalletAppCaseStudyobjective) {
                observer.unobserve(EWalletAppCaseStudyobjective);
            }
            if (EWalletAppCaseStudychallenges) {
                observer.unobserve(EWalletAppCaseStudychallenges);
            }
            if (EWalletAppCaseStudysolution) {
                observer.unobserve(EWalletAppCaseStudysolution);
            }
            if (solutionImg) {
                observer.unobserve(solutionImg);
            }
            if (EWalletAppCaseStudyKeyFeatures) {
                observer.unobserve(EWalletAppCaseStudyKeyFeatures);
            }
            if ( EWalletAppCaseStudyResult) {
                observer.unobserve( EWalletAppCaseStudyResult);
            }
        };
    }, []);
   
    useEffect(() => {
        handleScroll();
        // Adding event listener for the cleanup
        return () => contentRef.current?.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="EWalletAppCaseStudy-wrapper">
      
            <div className="EWalletAppCaseStudy-container"  onScroll={handleScroll} ref={contentRef}>
            <h1 className="EWalletAppCaseStudy-heading"  ref={headingRef} >E-Wallet App Case Study: USA-based Online Payment System
</h1>
            <img src={EWalletAppCaseStudyImage} alt="E-Commerce Case Study" ref={EcomCaseStudyImageRef} className="EWalletAppCaseStudy-image"  data-aos="fade-up"/>
            <p className="EWalletAppCaseStudy-intro" ref={EWalletAppCaseStudyintroRef}>
            Our team made a highly customized app that impeccably provides scalability, rich features, and an intuitive user interface to drive more users to the E-wallet app.


            </p>
              {/* Project Details Section */}
            <div className="EWalletAppCaseStudy-details" ref={EWalletAppCaseStudydetailsRef}>
                <h2 className="EWalletAppCaseStudy-subheading">Project Overview</h2>
                <p><strong>Industry:</strong> eCommerce</p>
                <p><strong>Services:</strong> Mobile App Design, App Development (Android & iOS)

</p>
                <p><strong>Technologies:</strong> NodeJS(Backend), Angular(Frontend)
 
</p>
                <p><strong>Platforms:</strong> iOS, Android
</p>
                <p><strong>Country:</strong> USA</p>
                <p><strong>Duration:</strong> 7.5 Months</p>
            </div>
            {/* About the Brand Section */}
            <div className="EWalletAppCaseStudy-brand" ref={EWalletAppCaseStudybrandRef}>
                <h2 className="EWalletAppCaseStudy-subheading">About the Brand</h2>
                <p>
                The client is a well-known online payment system provider based in Austin. CodingVisuals helped them to come up with their own online mobile payment application. In recent years, the mobile payment industry in Austin has boomed.
                </p> 
            </div>
            {/* Business Objective/Project Background Section */}
            <div className="EWalletAppCaseStudy-objective" ref={EWalletAppCaseStudyobjectiveRef}>
                <h2 className="EWalletAppCaseStudy-subheading">Business Objective/Project Background</h2>
                <ul>
                

                    <li>Develop a mobile wallet app for Android and iOS to facilitate mobile financial transactions.
</li>
                    <li>The application should operate successfully on various devices and lessen illegal, unwanted, and non-traceable payments, by reducing the use of cash.</li>
                    <li>The solution required to have an interactive and intuitive user interface with a secure transaction platform for processing several mobile payments.</li>
                    <li>The implementation of tough functionalities and offering a superb user interface.
</li>
                    
                    
                </ul>
            </div>
            {/* Challenges Section */}
            <div className="EWalletAppCaseStudy-challenges" ref={EWalletAppCaseStudychallengesRef}>
                <h2 className="EWalletAppCaseStudy-subheading">Challenges</h2>
                <ul>
                    <li>Since the e-wallet mobile app comprises gathering sensitive individual bank details, data encryption was of the greatest significance.</li>
                    <li>Hybrid application development for various platforms such as Android and iOS.
</li>
                    <li>Design a visually attractive and minimalistic interface.
</li>
                    <li>The client required that people can simply download the app without any hurdles.</li>
                    
                    
                </ul>
            </div>
              {/* Key Features */}
              <div className="EWalletAppCaseStudy-KeyFeatures" ref={EWalletAppCaseStudyKeyFeaturesRef}>
                <h2 className="EWalletAppCaseStudy-subheading">Key Features</h2>
                <ul>
                    <li>Complete payments</li>
                    <li>Monitor e-balance
</li>
                    <li>Assessment of payments history



</li>
                    <li>Make favourite payments lists</li>
                    <li>Receive payment reminders</li>
                    
                    
                </ul>
            </div>
            {/* Our Solution Section */}
            <div className="EWalletAppCaseStudy-solution" ref={EWalletAppCaseStudysolutionRef} >
                <h2 className="EWalletAppCaseStudy-subheading">Our Solution</h2>
                <ul>
                <li>Our team developed an E-wallet mobile app that permits users to operate their e-wallet right from their mobile phones.</li>
                <li>Applied strict encryption standards to safeguard the bank transaction process.
</li>
                <li>Our team gained the rest of API security by encrypting all payload data of all API calls.
</li>
                <li>Our team carried out a minimalistic design interface for simpler and faster navigation throughout the android application.</li>
                </ul>
                
            </div>
            {/* Result */}
            <div className="EWalletAppCaseStudy-solution" ref={EWalletAppCaseStudyResultRef} >
                <h2 className="EWalletAppCaseStudy-subheading">Results</h2>
                <p>In Austin, mobile payment applications like E-wallet can set the trend for e-payment systems. It will bring several perks to users by eliminating intermediate steps like signing papers, commuting, withdrawing cash, etc. With these features like quick to set reminders, add to favourites, and payment history, the e-wallet makes around 3000 payment types possible with just a tap.

</p>
                
            </div>
           
            </div>
            <div className="EWalletAppCaseStudy-custom-scrollbar">
                <div className="EWalletAppCaseStudy-custom-thumb" ref={scrollThumbRef}></div>
            </div>
        </div>
    );
}

export default EWalletAppCaseStudy;
