import React, { useState, useEffect, useRef } from "react";
import "./TermsOfServices.css";
import ThoughtOfTheDay from './ThoughtOfTheDay';
import { Link  } from 'react-router-dom';




function TermsOfServices() {
    
    const [currentSection, setCurrentSection] = useState(0);
    const sectionsCount = 4;
    const containerRef = useRef(null);
  

    

    const handleScroll = () => {
        const sectionHeight = window.innerHeight;
        const current = Math.round(containerRef.current.scrollTop / sectionHeight);
        setCurrentSection(current);
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
    }, []);
    document.addEventListener("DOMContentLoaded", function() {
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('TermsOfServices-animate');
              observer.unobserve(entry.target);
            }
          });
        }, { rootMargin: '0px', threshold: 0.1 });
      
        document.querySelectorAll('.TermsOfServices-section').forEach(section => {
          observer.observe(section);
        });
      });
    
  

    

    return (
        <div className="scroll-container" ref={containerRef}>
            <div className="section">
            <div className="TermsOfServices-container">
      <h1 className="TermsOfServices-heading">Terms of Service for CodingVisuals</h1>
      <div className="TermsOfServices-introduction TermsOfServices-section">
        <h2 className="TermsOfServices-subheading">Introduction</h2>
        <p className="TermsOfServices-paragraph">
          Welcome to CodingVisuals. These Terms of Service ("Terms") constitute a binding legal agreement between you 
          (the "Client" or "you") and CodingVisuals ("we," "us," or "our"), governing your use of our consultancy 
          services, website, and any associated services provided by CodingVisuals, located in the Delhi NCR region 
          (collectively, the "Services"). By accessing or using any part of our Services, you agree to be bound by these 
          Terms.
        </p>
      </div>
      <div className="TermsOfServices-acceptance TermsOfServices-section">
        <h2 className="TermsOfServices-subheading">1. Acceptance of Terms</h2>
        <p className="TermsOfServices-paragraph">
          1.1. By engaging with the Services offered by CodingVisuals, you acknowledge that you have read, understood, 
          and agreed to be legally bound by these Terms and our Privacy Policy, which is hereby incorporated by reference.
        </p>
        <p className="TermsOfServices-paragraph">
          1.2. If you are entering into these Terms on behalf of a business or entity, you represent that you have the 
          authority to bind that business or entity to these Terms and that your acceptance of these Terms will be treated 
          as acceptance by that business or entity.
        </p>
      </div>
      <div className="TermsOfServices-description TermsOfServices-section" >
        <h2 className="TermsOfServices-subheading">2. Description of Service</h2>
        <p className="TermsOfServices-paragraph">
          2.1. CodingVisuals provides a suite of business consultancy services, which include:
        </p>
        <ul className="TermsOfServices-list">
          <li><strong className="TermsOfServices-Strong">Business Strategy and Brand Building :</strong> We help businesses formulate strategic plans that align with their vision, create a unique brand identity, and improve market presence.</li>
          <li> <strong className="TermsOfServices-Strong">Sales Coaching and Employee Training :</strong> Our tailored coaching programs are designed to increase sales performance and enhance employee skills for professional growth and productivity.</li>
          
        </ul>
        
      </div>
    </div>
   
            </div>

            <div className="section">
            <div className="TermsOfServices-description TermsOfServices-section" >
            <ul className="TermsOfServices-list">
            <li> <strong className="TermsOfServices-Strong">AI Integration : </strong>  We offer advisory services and practical solutions for integrating AI technologies into business processes for enhanced efficiency and data-driven decision-making.</li>
          <li> <strong className="TermsOfServices-Strong">Marketing and Development Services : </strong>Our comprehensive marketing strategies cover both digital and conventional platforms. We also provide technical services such as web and app development to support business infrastructure.</li>
          <li> <strong className="TermsOfServices-Strong">Finance and Technology Consulting :</strong> We consult on financial management optimization and the deployment of innovative technologies essential for business scaling and modernization.</li>
          </ul>
          <p className="TermsOfServices-paragraph">
          2.2. CodingVisuals reserves the right to change, suspend, or discontinue any aspect of the Services at any time, including hours of operation or availability of the Service or any Service feature, without notice and without liability.
        </p>
        </div>
        <div className="TermsOfServices-userObligations TermsOfServices-section " >
        <h2 className="TermsOfServices-subheading">3. User Obligations</h2>
        <p className="TermsOfServices-paragraph">
          3.1. You agree to utilise our Services in a responsible manner consistent with the nature of the consultancy services provided, and only in a way that complies with all applicable laws and regulations.
        </p>
        <p className="TermsOfServices-paragraph">
          3.2. The information and recommendations provided by CodingVisuals during the provision of services are for your business use only. Redistribution of any information provided by CodingVisuals is prohibited unless agreed upon in writing by CodingVisuals.
        </p>
        <div className="TermsOfServices-intellectualProperty">
        <h2 className="TermsOfServices-subheading">4. Intellectual Property Rights</h2>
        <p className="TermsOfServices-paragraph">
          4.1. All intellectual property rights in the Services, our website, and all materials provided in the course of 
          our service provision, including but not limited to software, documents, text, graphics, logos, images, and 
          design, are owned by CodingVisuals or our licensors.
        </p>
        <p className="TermsOfServices-paragraph">
          4.2. Subject to your compliance with these Terms, we grant you a non-exclusive, non-transferable, revocable 
          licence to use the Services for your internal business purposes. Any other use of the Content is strictly 
          prohibited.
        </p>
      </div>
      </div>
            </div>

            <div className="section" >
            <div className="TermsOfServices-termination TermsOfServices-section">
        <h2 className="TermsOfServices-subheading">5. Termination</h2>
        <p className="TermsOfServices-paragraph">
          5.1. We may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately, for any breach of these Terms.
        </p>
        <p className="TermsOfServices-paragraph">
          5.2. Upon termination of your access to the Services, your right to use the Services will immediately cease. All provisions of these Terms which, by their nature, should survive termination, shall survive termination, including ownership provisions, warranty disclaimers, and limitations of liability.
        </p>
      </div>
      <div className="TermsOfServices-disclaimers TermsOfServices-section">
        <h2 className="TermsOfServices-subheading">6. Disclaimers and Limitation of Liability</h2>
        <p className="TermsOfServices-paragraph">
          6.1. CodingVisuals provides the Services on an "as is" basis without any warranties of any kind, either express 
          or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose, or 
          non-infringement.
        </p>
        <p className="TermsOfServices-paragraph">
          6.2. To the fullest extent permitted by applicable law, CodingVisuals shall not be liable for any indirect, 
          incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred 
          directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (a) your 
          access to, use of, inability to access, or inability to use the Services; or (b) any third-party conduct or 
          content on the Services, including any defamatory, offensive, or illegal conduct of third parties.
        </p>
      </div>
      <div className="TermsOfServices-generalTerms TermsOfServices-section">
        <h2 className="TermsOfServices-subheading">7. General Terms</h2>
        <p className="TermsOfServices-paragraph">
          7.1. CodingVisuals reserves the right to modify or replace any part of these Terms, and will provide notice of such changes in a manner we deem appropriate. Your continued use of the Services following the posting of any changes to the Terms constitutes acceptance of those changes.
        </p>
        <p className="TermsOfServices-paragraph">
          7.2. These Terms are governed by the laws of the Republic of India without regard to its conflict of laws provisions. You and CodingVisuals agree to submit to the personal and exclusive jurisdiction of the courts located in Delhi, India.
        </p>
        <p className="TermsOfServices-paragraph">
          7.3. These Terms, along with the Privacy Policy and any amendments and any additional agreements you may enter into with CodingVisuals, shall constitute the entire agreement between you and CodingVisuals concerning the Services.
        </p>
        <p className="TermsOfServices-paragraph">
          7.4. If you have any questions about these Terms or the Services, please contact CodingVisuals at our company address or via our designated contact methods on our website.
        </p>
      </div>
            </div>

            <div className="section"  >


<div className="Solution-footer-container">
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
               <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
           </svg>
           <a href="tel:+919990994592">+919990994592</a>
       </div>
       <div className="Solution-footer-item Ghazibadadresfootersolution">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z"/></svg>
           <a href="https://maps.app.goo.gl/c7aDLXXXbeVLsPRM7?g_st=iw" target="_blank">Delhi NCR </a>
       </div>
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
           <a href="mailto:support@codingvisuals.com">support@codingvisuals.com</a>
       </div>
   </div>
   <div className="Solution-secondary-container">
       <div className="Solution-mission">
           <h3>Our Mission</h3>
           <p className="OurMission-text">At CODING VISUALS, we believe that every individual and business has untapped potential waiting to be unleashed in the global realm. Our mission is to empower you to not only establish a strong digital presence but also to grow and thrive in the ever-expanding modern landscape. Let us be your trusted partner, guiding you towards success.</p>
       </div>
       
       <div className="SolutionFooterSocialMediaLinks">
       <h3>Connect With Us</h3>
       
   <Link to='https://twitter.com/Coding_Visuals' target="_blank" className="SolutionTwitter">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg> <span>@Coding_Visuals</span>
   </Link>
   <Link to='https://www.instagram.com/coding_visuals/' target="_blank" className="SolutionInstagram">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg> <span>@coding_visuals</span>


   </Link>
   <Link to='https://www.youtube.com/@CodingVisuals' target="_blank" className="SolutionYoutube">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
     <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg> <span>@CodingVisuals</span>
   </Link>
   <Link to='https://www.linkedin.com/company/99880813/admin/feed/posts/' target="_blank" className="SolutionLinkedin">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg> <span></span><span>@Coding Visuals</span>
   </Link>
   <Link to='https://www.facebook.com/people/Coding-Visuals/61550245559154/' target="_blank" className="SolutionFB">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
     <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg> <span>@Coding-Visuals</span>
   </Link>
   </div>
       
   </div>
  <div className="ThoughtoFTHEDAY">
   <ThoughtOfTheDay/>
  </div>
 
  <div className="Solution-copyright-section">
   <p>Copyright © 2023 CodingVisuals | AI Powered |
     <Link to="/TermsOfServices">Terms</Link> |
     <Link to="/PrivacyPolicy">Privacy</Link> |
     <Link to="/CancellationPolicy">Cancellation</Link>
   </p>
 </div>
</div>


            <div className="pagination">
                {Array(sectionsCount)
                    .fill()
                    .map((_, idx) => (
                        <span
                            key={idx}
                            className={`pagination-dot ${
                                idx === currentSection ? "active" : ""
                            }`}
                        ></span>
                    ))}
            </div>
        </div>
    );
}

export default TermsOfServices;