import React, { useRef, useEffect } from 'react';
import './FifthBlog.css';
import ConsumerBehaviourFifth from './images/ConsumerBehaviourFifth.png'
import { useParams} from 'react-router-dom'
const FifthBlog = () => {
    const contentRef = useRef(null);
    const scrollThumbFifthBlogRef = useRef(null);
    console.log(useParams())
    const handleScrollFifthBlog = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbFifthBlogRef.current.style.height = `${thumbHeight}px`;
        scrollThumbFifthBlogRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * (clientHeight - thumbHeight)}px)`;
    };

    // Set the initial height of the scroll thumb when the component mounts
    useEffect(() => {
        handleScrollFifthBlog();
    }, []);

    return (
        <div className="FifthBlog-wrapper">
            <div className="FifthBlog-container" onScroll={handleScrollFifthBlog} ref={contentRef}>
            <h1>Unraveling Consumer Behavior: The Deep Dive into Purchase Decisions</h1>
            <h5 className='Fifth-Blog-Date-Time'> Written on 05 October,2023   |      Reading Time : 2min </h5>
            <img src={ConsumerBehaviourFifth} alt="Description of Aivshuman" />
            <h2>Introduction:</h2>
            <p className='FifthblogContext'> <strong> Behind every purchase,</strong>  from the tiniest trinket to the grandest gadget, lies a complex web of decisions, emotions, and influences. Welcome to the multifaceted world of consumer behavior. Let's delve deeper to unravel the intricate nuances that drive our buying choices and shape market trends.
</p>

            <h2>What is Consumer Behavior, Really?</h2>
            <p className='FifthblogContext'>Beyond its textbook definition, consumer behavior is a rich tapestry woven from threads of human desires, external influences, personal histories, and societal factors. It’s an evolving discipline that tracks not just what people buy, but the ‘why’, ‘how’, and ‘when’ of their buying journeys.</p>
            

            <h2>Delving into the Layers of Consumer Behavior:
</h2>   

            <ul>
            <li><strong>➡ Cognitive Processes:    </strong> Every decision is a result of a cognitive dance of perception, attention, memory, and problem-solving. Brands often tap into these processes through persuasive advertising, shaping consumer perceptions and desires.


</li>
<li><strong>➡ Emotional Undercurrents: </strong>  An emotional connection can override logic. The joy of a luxury item or the security a brand provides can evoke strong emotions and sway decisions.


</li>
<li ><strong>➡ Behavioral Economics:  </strong>  This field dissects the irrationalities in consumer decisions. Why do we splurge on sales? Why does 'free shipping' allure us? It's all anchored in our psychological makeup.



</li>
<li className='FifthblogContext'><strong>➡ Decision-making Environments: </strong>  From the ambiance of a store to the layout of an e-commerce site, environments shape choices. The easier and more pleasant the buying process, the more likely a purchase will occur.



</li>

            </ul>

            <h2>The Power of External Influences:</h2>
              
              <ul>
            <li><strong>➡ Societal Pressures:   </strong>  Society, knowingly or unknowingly, sets standards. The car you drive, the fashion you adopt—all might be reflections of societal expectations.


</li>
<li><strong>➡ Influence of Technology: </strong>  In today's digital era, technology plays a pivotal role. Algorithms predict what we might like, while virtual reality enables 'try before you buy' experiences.


</li>
<li className='FifthblogContext'><strong>➡ Peer Influences & FOMO:    </strong> The Fear of Missing Out drives many a purchase, especially with social media flaunting the latest trends, gadgets, or experiences.



</li>

            </ul>
            <h2>Navigating the Complex Landscape of Modern Consumerism:</h2>
              
              <ul>
            <li><strong>➡ Informed Choices: </strong>  Today's consumer is informed, often armed with extensive research. Brands need to be transparent, genuine, and value-driven.




</li>
<li><strong>➡ Ethical & Sustainable Choices: </strong>  Modern consumers are environmentally and ethically conscious. Brands with sustainable practices and ethical frameworks resonate deeper.


</li>
<li className='FifthblogContext'><strong>➡ Experience over Possession:   </strong> More consumers today value experiences over material possessions. Hence, brands focusing on the 'experience' aspect often find more traction.
</li>

            </ul>
            <h2>Data & Consumer Behavior:</h2>
            <p className='FifthblogContext'> Harnessing data analytics offers insights into patterns, predicting future behaviors, and tailoring marketing efforts. However, with data comes the responsibility of ethical usage and ensuring privacy.

</p>
            <h2>Conclusion:</h2>
            <p>Consumer behavior is not just a business term; it's a mirror reflecting societal trends, human desires, and evolving values. As we stand at the cusp of technological innovations and societal shifts, understanding the depth and breadth of consumer behavior becomes paramount for any business. In this intricate dance of choices and influences, the brands that truly 'listen' to their consumers are the ones that stand tall. For those eager to decode the mysteries of the market, consumer behavior is your Rosetta Stone. Embrace it, study it, and let it guide you to unparalleled success.

</p>
            </div>
            <div className="faux-scrollbar">
                <div className="faux-thumb" ref={scrollThumbFifthBlogRef}></div>
            </div>
        </div>
    );
}

export default FifthBlog;
