import React, { useState, useEffect, useRef } from "react";
import "./OurPlans.css";
import { Link } from "react-router-dom";
import ThoughtOfTheDay from "./ThoughtOfTheDay";
import BharatComponent from "./BharatComponent";




function OurPlans() {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
      setIsHovering(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovering(false);
    };
    const [currentSection, setCurrentSection] = useState(0);
    const sectionsCount = 3;
    const containerRef = useRef(null);
  

    

    const handleScroll = () => {
        const sectionHeight = window.innerHeight;
        const current = Math.round(containerRef.current.scrollTop / sectionHeight);
        setCurrentSection(current);
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
    }, []);

    
  

    

    return (
        <div className="scroll-container" ref={containerRef}>
            <div className="section">
            <div className="OurPlans-container">
      <h1 className="OurPlans-heading">Consultancy Plans</h1>
      <div className="OurPlans-cardContainer">
        <div className="OurPlans-card">
        
          <h1 className="OurPlans-cardTitle">Business Clearity</h1>
          <h3 className="OurPlans-feature">➥ 1 Session/week by certified business consultant.</h3>
          <h3 className="OurPlans-feature">+ Data-Driven Insights on your Industry from Our AI/ML Software.</h3>
          <h3 className="OurPlans-feature">+ Your Business's Yearly Report.<br/>(After 1 year of Service)</h3>
          <h3 className="OurPlans-feature">+ Applying AI Tools in Your Business for increased effeciency.</h3>
          <a href="https://rzp.io/l/QoWwfTgC" target="_blank" rel="noreferrer" ><div
          className="OurPlans-price OurPlans-price-top"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
                ₹ 20,000/-
          {isHovering && 
            <span className="OurPlans-hoverMessage">
              Click and start your Business Consultancy service right now!
            </span>
          }
        </div></a>
          
        </div>
        <div className="OurPlans-card">
        <div className="OurPlans-overlay">
    <span className="OurPlans-overlayText">Most Valuable </span>
  </div>
          <h2 className="OurPlans-cardTitle">Marketing Success</h2>
          <h3 className="OurPlans-feature">➥ 2 Sessions/week by industry experts.</h3>
  <h3 className="OurPlans-feature">+ Everything in " BUSINESS CLEARITY ".</h3>
  <h3 className="OurPlans-feature">+ Employee Coaching </h3>
  <h3 className="OurPlans-feature">+ Sales Team Training. </h3>
  <h3 className="OurPlans-feature">+ Complete Digital Marketing Roadmap.</h3>
  <h3 className="OurPlans-feature">+ Help you to select  the best Influencer for your next marketing campaign.</h3>
  <a href="https://rzp.io/l/pfL8JzPr" target="_blank" rel="noreferrer" >
  <div className="OurPlans-price OurPlans-price-top"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
                ₹ 65,000/-
          {isHovering && 
            <span className="OurPlans-hoverMessage">
              Click and start your Business Consultancy service right now!
            </span>
          }
        </div>
  </a>
 
        </div>
        <div className="OurPlans-card">
       
          <h2 className="OurPlans-cardTitle">Profit Supercharged</h2>
          
          <h3 className="OurPlans-feature">➥ 2 Sessions/week by industry experts.</h3>
          <h3 className="OurPlans-feature">+ Everything in " BUSINESS CLEARITY " and  <br/>  " MARKETING SUCCESS " .</h3>
  <h3 className="OurPlans-feature">+ Finance Consulting and Resource Allocation Strategies.</h3>
  <h3 className="OurPlans-feature">+ Applying latest softwares  and tech-powered hardwares thus making your business future proof. </h3>
  <a href="https://rzp.io/l/cyRFSVWP" target="_blank" rel="noreferrer" className="PaymentLink-Plans">
  <div className="OurPlans-price"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
                ₹ 1,50,000/-
          {isHovering && 
            <span className="OurPlans-hoverMessage">
              Click and start your Business Consultancy service right now!
            </span>
          }
        </div>
  </a>
  
          {/* Plan details here */}
        </div>
      </div>
    </div>
    <div className="Bharat"><BharatComponent/></div>
            </div>
            <div className="section">
                <div className="OurPlans-gradient-bg">
                    <h2 className="OurPlans-heading Ourplans-2nd-page">Empower Your Business: Tailored Consultancy Solutions</h2>
                    <div className="OurPlans-service">
                        <p>EXCLUSIVE CONSULTANCY<br/>1 session per week - ₹ 2000 / session<br/>2 sessions per week - ₹ 2000 / session</p>
                        <a href="https://wa.me/+919990994592?text=I'm%20interested%20in%20Exclusive%20Consultancy" target="_blank" className="OurPlans-service-button">Get Started</a>
                    </div>
                    <div className="OurPlans-service">
                        <p>DATA-DRIVEN STRATEGIES<br/>Comprehensive analysis and actionable insights - ₹ 12,000</p>
                        <a href="https://wa.me/+919990994592?text=I'm%20interested%20in%20Data-Driven%20Strategies" target="_blank" className="OurPlans-service-button">Learn More</a>
                    </div>
                    <div className="OurPlans-service">
                        <p>LEVERAGE AI IN YOUR BUSINESS<br/>Integration and application of AI tools - ₹ 10,000</p>
                        <a href="https://wa.me/+919990994592?text=I'm%20interested%20in%20Leveraging%20AI%20in%20my%20Business" target="_blank" className="OurPlans-service-button">Explore AI</a>
                    </div>
                    <div className="OurPlans-service">
                        <p> SOCIAL MEDIA MANAGEMENT(Meta + LinkedIn) + <br/> DIGITAL MARKETING ROADMAP  - ₹ 20,000 (₹ 15,000 + ₹ 5,000)</p>
                        <a href="https://wa.me/+919990994592?text=I'm%20interested%20in%20Digital%20Marketing%20and%20Social%20Media%20Management" target="_blank" className="OurPlans-service-button">Start Now</a>
                    </div>
                    <div className="OurPlans-service">
                        <p>INFLUENCER MARKETING<br/>Help you to select the best influencer for your marketing campaign - ₹ 5,000</p>
                        <a href="https://wa.me/+919990994592?text=I'm%20interested%20in%20Influencer%20Marketing" target="_blank" className="OurPlans-service-button">Find Influencers</a>
                    </div>
                    {/* Add more services and buttons as needed */}
                </div>
            </div>



            <div className="section"  >


<div className="Solution-footer-container">
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
               <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
           </svg>
           <a href="tel:+919990994592">+919990994592</a>
       </div>
       <div className="Solution-footer-item Ghazibadadresfootersolution">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z"/></svg>
           <a href="https://maps.app.goo.gl/c7aDLXXXbeVLsPRM7?g_st=iw" target="_blank">B-Block, Swaran Jyanti Puram, Govindpuram, Ghaziabad, UP - 201013, IN</a>
       </div>
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
           <a href="mailto:support@codingvisuals.com">support@codingvisuals.com</a>
       </div>
   </div>
   <div className="Solution-secondary-container">
       <div className="Solution-mission">
           <h3>Our Mission</h3>
           <p className="OurMission-text">At CODING VISUALS, we believe that every individual and business has untapped potential waiting to be unleashed in the global realm. Our mission is to empower you to not only establish a strong digital presence but also to grow and thrive in the ever-expanding modern landscape. Let us be your trusted partner, guiding you towards success.</p>
       </div>
       
       <div className="SolutionFooterSocialMediaLinks">
       <h3>Connect With Us</h3>
       
   <Link to='https://twitter.com/Coding_Visuals' target="_blank"  className="SolutionTwitter">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg> <span>@Coding_Visuals</span>
   </Link>
   <Link to='https://www.instagram.com/coding_visuals/' target="_blank" className="SolutionInstagram">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg> <span>@coding_visuals</span>


   </Link>
   <Link to='https://www.youtube.com/@CodingVisuals' target="_blank" className="SolutionYoutube">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
     <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg> <span>@CodingVisuals</span>
   </Link>
   <Link to='https://www.linkedin.com/company/99880813/admin/feed/posts/' target="_blank" className="SolutionLinkedin">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg> <span></span><span>@Coding Visuals</span>
   </Link>
   <Link to='https://www.facebook.com/people/Coding-Visuals/61550245559154/' target="_blank" className="SolutionFB">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
     <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg> <span>@Coding-Visuals</span>
   </Link>
   </div>
       
   </div>
  <div className="ThoughtoFTHEDAY">
   <ThoughtOfTheDay/>
  </div>
 
  <div className="Solution-copyright-section">
   <p>Copyright © 2023 CodingVisuals | AI Powered |
     <Link to="/Home/TermsOfServices">Terms</Link> |
     <Link to="/Home/PrivacyPolicy">Privacy</Link> |
     <Link to="/Home/Shipping-Delivery">Shipping & Delivery</Link> |
     <Link to="/Home/CancellationPolicy">Cancellation</Link>| Image by rawpixel.com on Freepik
   </p>
 </div>
</div>

           


            <div className="pagination">
                {Array(sectionsCount)
                    .fill()
                    .map((_, idx) => (
                        <span
                            key={idx}
                            className={`pagination-dot ${
                                idx === currentSection ? "active" : ""
                            }`}
                        ></span>
                    ))}
            </div>
        </div>
    );
}

export default OurPlans;