import React, { useRef, useEffect } from 'react';
import './ContentMarketingTravelCaseStudy.css';
import ContentMarketingTravelCaseStudyImage from './images/SEO2.png';
import EcomCaseStudyImage from './images/SEO2ndcasestudy.png'; // Make sure to use the correct path and file name

const ContentMarketingTravelCaseStudy = () => {
    const contentRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const ContentMarketingTravelCaseStudysolutionRef= useRef(null);
    const ContentMarketingTravelCaseStudychallengesRef= useRef(null);
    const ContentMarketingTravelCaseStudybrandRef= useRef(null);
    const ContentMarketingTravelCaseStudyobjectiveRef= useRef(null);
    const ContentMarketingTravelCaseStudydetailsRef= useRef(null);
    const EcomCaseStudyImageRef= useRef(null);
    const headingRef = useRef(null); 
    const solutionImageRef = useRef(null);
    const  ContentMarketingTravelCaseStudyintroRef= useRef(null);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        const thumbHeight = (clientHeight * clientHeight) / scrollHeight;
        scrollThumbRef.current.style.height = `${thumbHeight}px`;
        scrollThumbRef.current.style.transform = `translateY(${(scrollTop / (scrollHeight - clientHeight)) * 100}%)`;
    };
    useEffect(() => {
        // ... other useEffect contents

        // Intersection Observer for scroll animations
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is in the viewport
            }
        );

        const headingEl = headingRef.current;
        if (headingEl) {
            observer.observe(headingEl);
        }
        const EcomCaseStudyImage = EcomCaseStudyImageRef.current;
        if (EcomCaseStudyImage) {
            observer.observe(EcomCaseStudyImage);
        }
        const ContentMarketingTravelCaseStudyintro = ContentMarketingTravelCaseStudyintroRef.current;
        if (ContentMarketingTravelCaseStudyintro) {
            observer.observe(ContentMarketingTravelCaseStudyintro);
        }
        const ContentMarketingTravelCaseStudydetails = ContentMarketingTravelCaseStudydetailsRef.current;
        if (ContentMarketingTravelCaseStudydetails) {
            observer.observe(ContentMarketingTravelCaseStudydetails);
        }
        const ContentMarketingTravelCaseStudybrand = ContentMarketingTravelCaseStudybrandRef.current;
        if (ContentMarketingTravelCaseStudybrand) {
            observer.observe(ContentMarketingTravelCaseStudybrand);
        }
        const ContentMarketingTravelCaseStudyobjective = ContentMarketingTravelCaseStudyobjectiveRef.current;
        if (ContentMarketingTravelCaseStudyobjective) {
            observer.observe(ContentMarketingTravelCaseStudyobjective);
        }
        const ContentMarketingTravelCaseStudychallenges = ContentMarketingTravelCaseStudychallengesRef.current;
        if (ContentMarketingTravelCaseStudychallenges) {
            observer.observe(ContentMarketingTravelCaseStudychallenges);
        }
        const ContentMarketingTravelCaseStudysolution = ContentMarketingTravelCaseStudysolutionRef.current;
        if (ContentMarketingTravelCaseStudysolution) {
            observer.observe(ContentMarketingTravelCaseStudysolution);
        }
        const solutionImg = solutionImageRef.current;
        if (solutionImg) {
            observer.observe(solutionImg);
        }

        // Cleanup observer on component unmount
        return () => {
            if (headingEl) {
                observer.unobserve(headingEl);
            }
            if (EcomCaseStudyImage) {
                observer.unobserve(EcomCaseStudyImage);
            }
            if (ContentMarketingTravelCaseStudyintro) {
                observer.unobserve(ContentMarketingTravelCaseStudyintro);
            }
            if (ContentMarketingTravelCaseStudydetails) {
                observer.unobserve(ContentMarketingTravelCaseStudydetails);
            }
            if (ContentMarketingTravelCaseStudybrand) {
                observer.unobserve(ContentMarketingTravelCaseStudybrand);
            }
            if (ContentMarketingTravelCaseStudyobjective) {
                observer.unobserve(ContentMarketingTravelCaseStudyobjective);
            }
            if (ContentMarketingTravelCaseStudychallenges) {
                observer.unobserve(ContentMarketingTravelCaseStudychallenges);
            }
            if (ContentMarketingTravelCaseStudysolution) {
                observer.unobserve(ContentMarketingTravelCaseStudysolution);
            }
            if (solutionImg) {
                observer.unobserve(solutionImg);
            }
        };
    }, []);
   
    useEffect(() => {
        handleScroll();
        // Adding event listener for the cleanup
        return () => contentRef.current?.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="ContentMarketingTravelCaseStudy-wrapper">
      
            <div className="ContentMarketingTravelCaseStudy-container"  onScroll={handleScroll} ref={contentRef}>
            <h1 className="ContentMarketingTravelCaseStudy-heading"  ref={headingRef} >Content Marketing Case Study: UK-based Travel Company</h1>
            <img src={EcomCaseStudyImage} alt="E-Commerce Case Study" ref={EcomCaseStudyImageRef} className="ContentMarketingTravelCaseStudy-image"  data-aos="fade-up"/>
            <p className="ContentMarketingTravelCaseStudy-intro" ref={ContentMarketingTravelCaseStudyintroRef}>
           Our team SEO Strategy helped Travel Company generate leads and seal more deals.
            </p>
              {/* Project Details Section */}
            <div className="ContentMarketingTravelCaseStudy-details" ref={ContentMarketingTravelCaseStudydetailsRef}>
                <h2 className="ContentMarketingTravelCaseStudy-subheading">Project Overview</h2>
                <p><strong>Industry:</strong> Travel</p>
                <p><strong>Services:</strong> SEO content writing, copywriting, blog writing, content optimization
</p>
                <p><strong>Platforms:</strong> Content Writer, SEO Specialist</p>
                <p><strong>Country:</strong> United Kingdom</p>
                <p><strong>Duration:</strong> 4.5 Months + Ongoing</p>
            </div>
            {/* About the Brand Section */}
            <div className="ContentMarketingTravelCaseStudy-brand" ref={ContentMarketingTravelCaseStudybrandRef}>
                <h2 className="ContentMarketingTravelCaseStudy-subheading">About the Brand</h2>
                <p>
                Our travel client which is situated in the United Kingdom and operational all across the globe has been a very successful travel agency for almost 25 years. They cater to their audience with premium services like tours, hotel bookings, transportation, travel packages, etc. Besides, they have their own online portal where users can search and book travel services online with zero hassle.
                </p> 
            </div>
            {/* Business Objective/Project Background Section */}
            <div className="ContentMarketingTravelCaseStudy-objective" ref={ContentMarketingTravelCaseStudyobjectiveRef}>
                <h2 className="ContentMarketingTravelCaseStudy-subheading">Business Objective/Project Background</h2>
                <ul>
                The client approached our team to help them with their brand’s content strategy. They wanted to increase customer engagement on their website as well as boost brand awareness. The client required a team of skilled and creative SEO content writers who could create a copy that resonates with the audience. <br/><br/>

                    <li>Devise a content strategy plan.</li>
                    <li>Deliver SEO-optimised content and blogs.</li>
                    <li>Create viral social media posts.</li>
                    <li>Increase organic traffic on the website.</li>
                    <li>Optimise the website content.</li>
                    
                </ul>
            </div>
            {/* Challenges Section */}
            <div className="ContentMarketingTravelCaseStudy-challenges" ref={ContentMarketingTravelCaseStudychallengesRef}>
                <h2 className="ContentMarketingTravelCaseStudy-subheading">Challenges</h2>
                <ul>
                    <li>As the client catered to global customers, the website’s pages should include tailored content for the target audience.</li>
                    <li>The client was up against the biggest travel companies in the UK which eventually increased the research work for our team.</li>
                    <li>Deliver high-quality content to increase website users and increase sales.</li>
                    <li>Every day we need to deliver unique and viral content for social media.</li>
                    
                </ul>
            </div>
            {/* Our Solution Section */}
            <div className="ContentMarketingTravelCaseStudy-solution" ref={ContentMarketingTravelCaseStudysolutionRef} >
                <h2 className="ContentMarketingTravelCaseStudy-subheading">Our Solution</h2>
                <p>We began the work by setting up a few calls with the client’s marketing team and our content lead. After having a complete understanding of the project requirements we allocated a team of content writers and SEO specialists. Over the course of 4.5 months, we delivered 50 blogs and content for 25 web pages.</p>
                <ul>
                    <li>We handpicked a team of content writers, editors, and SEO specialists who had experience with the travel industry.
</li>
                    <li>Our SEO experts carried out a complete analysis of the website, competitors, target audience, and keyword research.</li>
                    <li>We set up weekly scrum meetings with the team to give them the project status and get feedback.</li>
                    <li>We created custom content for the client including regular blogs, long-form content, web page content, and social media posts.</li>
                    <li>We also optimised the existing website content with updated keywords and fresh content.</li>
                    
                </ul>
            </div>
            {/* Image after Our Solution Section */}
            <img 
                src={ContentMarketingTravelCaseStudyImage} 
                alt="Detailed view of the solution" 
                className="ContentMarketingTravelCaseStudy-solutionImage"
                ref={solutionImageRef}
            />
            </div>
            <div className="ContentMarketingTravelCaseStudy-custom-scrollbar">
                <div className="ContentMarketingTravelCaseStudy-custom-thumb" ref={scrollThumbRef}></div>
            </div>
        </div>
    );
}

export default ContentMarketingTravelCaseStudy;
