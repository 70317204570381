import React, { useState } from 'react';
import './BharatComponent.css'; // Ensure this path is correct

const BharatComponent = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const plans = [
        { title: "BUSINESS CLARITY", features: ["→ 1 Session/week by certified business consultant.", "+ Data-Driven Insights on your Industry from Our AI/ML Software.", "+ Your Business's Yearly Report.", "+ Applying AI Tools in Your Business for increased efficiency."], price: "Pay ₹20,000/-",  url: "https://rzp.io/l/QoWwfTgC" },
        { title: "MARKETING SUCCESS", features: ["→ 2 Sessions/week by industry experts.", "+ Everything in BUSINESS CLARITY.", "+ Employee Coaching + Sales Team Training.", "+ Complete Digital Marketing Roadmap."], price: " Pay ₹65,000/-", url: "https://rzp.io/l/pfL8JzPr" },
        { title: "PROFIT SUPERCHARGED", features: ["→ 2 Sessions/week by industry experts.", "+ Everything in BUSINESS CLARITY and MARKETING SUCCESS.", "+ Finance Consulting + Resource Allocation Strategies." , "+ Applying latest software and tech-powered hardware thus making your business future-proof."], price: "Pay ₹1,50,000/-", url: "https://rzp.io/l/cyRFSVWP" },
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % plans.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + plans.length) % plans.length);
    };

    const { title, features, price, url } = plans[currentSlide];

    return (
        <div className="BharatComponent-container">
            <h1 className="BharatComponent-heading">CONSULTANCY PLANS</h1>
            <div className="BharatComponent-card">
                <h2>{title}</h2>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
                <p>
                    <a 
                      href={url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="BharatComponent-price"
                    >
                      {price}
                    </a>
                </p>
            </div>
            {currentSlide > 0 && (
                <button className="BharatComponent-button" onClick={prevSlide}>Previous</button>
            )}
            {currentSlide < plans.length - 1 && (
                <button className="BharatComponent-button" onClick={nextSlide}>Next</button>
            )}
        </div>
    );
};

export default BharatComponent;
