import React, { useState, useEffect, useRef } from "react";
import "./BusinessConsultancy.css";
import './BusinessAnimation.css';
import ONLINEWEBINAR from './images/OnlineWebinar.png'
import OFFLINEWORKSHOP from './images/OfflineWorkshop.png'
import {Link} from 'react-router-dom'
import ThoughtOfTheDay from "./ThoughtOfTheDay";
import './BusinessConsultancyMobile.css'
// import NATUREBUSINESS from './Videos/BusinessOnetoOne.MP4'
// import BusinessThumbnail from './images/EXPERIENCEEXPONENTIAL.PNG'
function BusinessConsultancy() {
  
    const [currentSection, setCurrentSection] = useState(0);
    const sectionsCount = 4;
    const containerRef = useRef(null);
    
    const frameRef = useRef(null);
    const salesCircleRef = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            entry.target.classList.add('bounce-in');
          }
        },
        {
          threshold: 0,
          rootMargin: '-50% 0px 0px 0px' // It will trigger when the top half of the viewport is crossed
        }
      );
  
      if (salesCircleRef.current) {
        observer.observe(salesCircleRef.current);
      }
  
      return () => {
        if (salesCircleRef.current) {
          observer.unobserve(salesCircleRef.current);
        }
      };
    }, []);
    useEffect(() => {
      const animateElements = (entries, observer) => {
        entries.forEach(entry => {
          const img1 = entry.target.querySelector('.BusinessConsultancy-Onlinewebinar-2ndframe:first-child');
          const img2 = entry.target.querySelector('.BusinessConsultancy-Onlinewebinar-2ndframe:last-child');
          const h2 = entry.target.querySelector('.BusinessConsultancy-subheading-2ndframe');
  
          if (entry.isIntersecting) {
            img1.style.transform = "translateX(0)";
            img2.style.transform = "translateX(0)";
            h2.style.transform = "translateY(0)";
  
            // Stop observing the element after animations are triggered
            observer.unobserve(entry.target);
          }
        });
      };
  
      const options = {
        threshold: 0.1
      };
  
      const observer = new IntersectionObserver(animateElements, options);
      if (frameRef.current) {
        observer.observe(frameRef.current);
      }
  
      return () => {
        if (frameRef.current) {
          observer.unobserve(frameRef.current);
        }
      };
    }, []);

    const handleScroll = () => {
        const sectionHeight = window.innerHeight;
        const current = Math.round(containerRef.current.scrollTop / sectionHeight);
        setCurrentSection(current);
    };
    
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
    }, []);

    

    return (
        <div className="scroll-container" ref={containerRef}>
            <div className="section" >
            <h1 className="BusinessConsultancy-heading">Let’s make your vision come true</h1>
            <div className="BusinessConsultancy-all-flex">
            <div className="BusinessConsultancy-contentFlex">
        
        
        <svg  height='3rem' id="Layer_2" data-name="Layer 2"  viewBox="0 0 267.47 40.74">
  
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <path class="BusinessAchievesvgPath" d="m23.22,39.84v-2.22c3.78-.54,3.78-1.2,2.94-3.78-.6-1.8-1.56-4.5-2.46-6.9h-11.7c-.66,1.86-1.5,4.14-2.1,6-1.26,3.78.18,4.26,4.92,4.68v2.22H0v-2.22c3.78-.48,4.74-1.02,6.78-6.06L19.26.54l2.88-.54c3.84,10.44,7.92,21.12,11.82,31.14,2.22,5.58,2.76,6,6.66,6.48v2.22h-17.4Zm-5.28-28.68c-1.74,4.32-3.42,8.34-4.92,12.72h9.54l-4.62-12.72Z"/>
      <path class="BusinessAchievesvgPath" d="m79.56,29.52c-.84,3-2.4,7.56-3.36,9.66-1.56.36-6.48,1.56-11.04,1.56-16.14,0-22.8-10.38-22.8-19.92,0-12.42,9.48-20.82,23.88-20.82,5.22,0,9.84,1.26,11.34,1.44.3,2.82.72,7.26,1.14,9.9l-2.4.42c-1.26-4.68-3.06-6.9-6.24-8.16-1.44-.6-3.6-.84-5.22-.84-8.7,0-13.08,7.26-13.08,16.68,0,10.98,5.64,18.42,13.86,18.42,5.04,0,8.4-2.64,11.64-9.24l2.28.9Z"/>
      <path class="BusinessAchievesvgPath" d="m127.74,3.06c-4.68.48-5.1.84-5.1,5.82v22.98c0,4.98.48,5.34,5.1,5.76v2.22h-18.54v-2.22c4.74-.6,5.1-.78,5.1-5.76v-11.28h-16.2v11.28c0,4.98.54,5.28,5.04,5.76v2.22h-18.48v-2.22c4.44-.48,5.1-.78,5.1-5.76V8.88c0-4.98-.54-5.46-5.1-5.82V.84h18.48v2.22c-4.56.42-5.04.84-5.04,5.82v8.58h16.2v-8.58c0-4.98-.6-5.34-5.1-5.82V.84h18.54v2.22Z"/>
      <path class="BusinessAchievesvgPath" d="m132.84,39.84v-2.22c4.56-.42,5.1-.78,5.1-5.82V8.94c0-5.04-.6-5.52-5.1-5.88V.84h18.54v2.22c-4.5.42-5.1.84-5.1,5.88v22.86c0,4.98.54,5.34,5.1,5.82v2.22h-18.54Z"/>
      <path class="BusinessAchievesvgPath" d="m189.42,29.64c-.3,1.62-1.98,8.52-2.52,10.2h-31.14v-2.16c5.46-.54,5.82-.84,5.82-5.88V8.88c0-5.1-.72-5.46-4.8-5.82V.84h19.5c6.3,0,8.4-.06,9.24-.12.06,1.08.36,6,.6,9.72l-2.34.3c-.54-2.04-1.56-4.5-2.58-5.58-1.08-1.2-1.86-1.56-4.92-1.56h-3.9c-2.28,0-2.52.12-2.52,2.16v12.54h5.88c3.36,0,3.96-.9,4.86-5.16h2.34v13.38h-2.34c-.96-4.44-1.44-5.16-4.92-5.16h-5.82v10.32c0,4.14.12,5.4,3.84,5.46h4.26c3.36,0,4.26-.42,5.58-2.04,1.5-1.74,2.7-3.96,3.66-6l2.22.54Z"/>
      <path class="BusinessAchievesvgPath" d="m232.01,3.06c-3.96.42-5.16.96-7.44,6.66-1.86,4.62-8.04,19.5-12.48,30.78h-3.3c-1.68-4.68-9.48-25.26-12-31.62-1.86-4.5-2.64-5.46-6.54-5.82V.84h18.3l-.06,2.22c-4.38.6-4.38,1.02-3.42,3.54,1.86,5.04,6.36,17.4,8.4,22.68,3.6-8.58,6.84-16.74,8.22-20.64,1.5-4.14,1.08-5.04-3.96-5.58V.84h14.28v2.22Z"/>
      <path class="BusinessAchievesvgPath" d="m267.47,29.64c-.3,1.62-1.98,8.52-2.52,10.2h-31.14v-2.16c5.46-.54,5.82-.84,5.82-5.88V8.88c0-5.1-.72-5.46-4.8-5.82V.84h19.5c6.3,0,8.4-.06,9.24-.12.06,1.08.36,6,.6,9.72l-2.34.3c-.54-2.04-1.56-4.5-2.58-5.58-1.08-1.2-1.86-1.56-4.92-1.56h-3.9c-2.28,0-2.52.12-2.52,2.16v12.54h5.88c3.36,0,3.96-.9,4.86-5.16h2.34v13.38h-2.34c-.96-4.44-1.44-5.16-4.92-5.16h-5.82v10.32c0,4.14.12,5.4,3.84,5.46h4.26c3.36,0,4.26-.42,5.58-2.04,1.5-1.74,2.7-3.96,3.66-6l2.22.54Z"/>
    </g>
  </g>
</svg>
        <svg id="Layer_2" data-name="Layer 2" height='3rem' viewBox="0 0 193.98 40.74">
 
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <path class="BusinessMoresvgPath" d="m32.94,39.84v-2.22c4.56-.48,4.86-.9,4.86-6.3l-.06-23.4c-4.32,10.32-9.06,21.72-13.38,31.92h-1.98c-3.9-9.78-8.16-20.94-12.48-31.44-.78,18.84-.78,22.56-.78,24.72.06,3.24,1.26,4.08,5.76,4.5v2.22H0v-2.22c3.42-.3,4.98-.96,5.46-4.56.24-1.8.48-5.22,1.86-25.02.18-2.88-.24-4.5-5.4-4.98V.84h12.96l11.58,26.46L38.1.84h12.66v2.22c-4.8.48-5.34.96-5.22,5.76l.48,22.5c.06,5.4.3,5.64,5.16,6.3v2.22h-18.24Z"/>
      <path class="BusinessMoresvgPath" d="m77.1,0c11.1,0,20.16,8.1,20.16,19.92,0,12.78-9.12,20.82-20.46,20.82-12,0-20.7-8.76-20.7-20.34,0-10.26,7.32-20.4,21-20.4Zm-.9,2.64c-6.12,0-10.62,6-10.62,16.62s4.02,18.84,12.12,18.84c5.7,0,10.14-5.52,10.14-16.5,0-11.88-4.68-18.96-11.64-18.96Z"/>
      <path class="BusinessMoresvgPath" d="m140.22,40.2c-.36,0-1.08,0-1.8-.06-7.62-.36-9.84-1.98-11.64-4.5-2.4-3.3-4.14-6.96-6.18-10.56-.9-1.62-1.74-1.98-3.66-1.98l-1.2-.06v8.76c0,4.86.6,5.28,5.1,5.82v2.22h-18.36v-2.22c4.56-.48,5.1-.96,5.1-5.82V8.82c0-4.74-.6-5.4-5.1-5.76V.84h17.4c5.34,0,8.4.6,10.86,2.28,2.4,1.62,4.02,4.26,4.02,7.98,0,5.34-3.54,8.16-7.5,9.72.9,1.8,3.24,5.7,5.04,8.46,2.1,3.3,3.42,4.92,4.56,6.24,1.38,1.56,2.04,2.04,3.78,2.64l-.42,2.04Zm-22.86-19.62c2.58,0,4.56-.42,5.88-1.56,1.98-1.68,2.82-3.96,2.82-7.02,0-6.36-3.66-8.64-7.02-8.64-1.62,0-2.4.24-2.76.6-.42.36-.54,1.08-.54,2.28v14.34h1.62Z"/>
      <path class="BusinessMoresvgPath" d="m175.68,29.64c-.3,1.62-1.98,8.52-2.52,10.2h-31.14v-2.16c5.46-.54,5.82-.84,5.82-5.88V8.88c0-5.1-.72-5.46-4.8-5.82V.84h19.5c6.3,0,8.4-.06,9.24-.12.06,1.08.36,6,.6,9.72l-2.34.3c-.54-2.04-1.56-4.5-2.58-5.58-1.08-1.2-1.86-1.56-4.92-1.56h-3.9c-2.28,0-2.52.12-2.52,2.16v12.54h5.88c3.36,0,3.96-.9,4.86-5.16h2.34v13.38h-2.34c-.96-4.44-1.44-5.16-4.92-5.16h-5.82v10.32c0,4.14.12,5.4,3.84,5.46h4.26c3.36,0,4.26-.42,5.58-2.04,1.5-1.74,2.7-3.96,3.66-6l2.22.54Z"/>
      <path class="BusinessMoresvgPath" d="m188.94,0c2.04,0,4.98.36,5.04,2.64,0,.72-.12,1.8-.24,2.64-.6,3.84-2.34,13.26-3.36,20.82h-2.88c-.84-6.96-2.58-16.5-3.3-20.64-.18-.72-.42-2.1-.42-2.82,0-2.1,3-2.64,5.16-2.64Zm.06,40.56c-2.82,0-4.86-2.04-4.86-5.04s2.04-4.98,4.86-4.98,4.92,2.04,4.92,4.98-2.04,5.04-4.92,5.04Z"/>
    </g>
  </g>
</svg>
        
        
    </div>
    {/* <video className="BusinessConsultancy-video"   controls playsInline poster={BusinessThumbnail}>
            <source src={NATUREBUSINESS}    type="video/mp4"/>
            Your browser does not support the video tag.
        </video> */}
        <iframe  src="https://www.youtube-nocookie.com/embed/lrMVa2bSKAw?si=kHfJB5jtdmZMsBnb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="IFRAME_VIDEO_BUSINESS_CONSULTANCY"></iframe>
        </div>
            </div>
            <div className="section">
            <div className="frame2nd" ref={frameRef}>
        <img src={ONLINEWEBINAR} alt="Description of Image" className="BusinessConsultancy-Onlinewebinar-2ndframe" />
        <h2 className="BusinessConsultancy-subheading-2ndframe">
        We coach and consult <br/> your team via both online webinars <br/> and offline workshops
    </h2>
    <img src={OFFLINEWORKSHOP} alt="Description of Image" className="BusinessConsultancy-Onlinewebinar-2ndframe" />
    </div>
            </div>
            <div className="section">
            <div className="BusinessConsultancyCircleContainer" >
            <div className="BusinessConsultancyCirclesales " ref={salesCircleRef}>
                <span className="BusinessConsultancyTextsales">Sales coaching & employee coaching</span>
                
            </div>
            <div className="BusinessConsultancyCircleBrand" >
                <span className="BusinessConsultancyTextBrand">Business strategy & brand building</span>
                
            </div>
            <div className="BusinessConsultancyCircleFinance">
                <span className="BusinessConsultancyTextFinance">Finance and technology consulting</span>
                
            </div>
            <div className="BusinessConsultancyCircle">
            
                <span className="BusinessConsultancyText">SOLUTIONS</span>
                <a href="/Solution" className="BusinessConsultancyLink">Know More</a>
            </div>
            <div className="BusinessConsultancyCircleMarketing">
                <span className="BusinessConsultancyTextMarketing">Marketing & Development Services</span>
                
            </div>
            <div className="BusinessConsultancyCircleAI">
                <span className="BusinessConsultancyTextAI">AI in your <br/> business</span>
                
            </div>
        </div>
</div>
<div className="section"  >


<div className="Solution-footer-container">
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
               <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
           </svg>
           <a href="tel:+919990994592">+919990994592</a>
       </div>
       <div className="Solution-footer-item Ghazibadadresfootersolution">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z"/></svg>
           <a href="https://maps.app.goo.gl/c7aDLXXXbeVLsPRM7?g_st=iw" target="_blank">B-Block, Swaran Jyanti Puram, Govindpuram, Ghaziabad, UP - 201013, IN</a>
       </div>
       <div className="Solution-footer-item">
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
           <a href="mailto:support@codingvisuals.com">support@codingvisuals.com</a>
       </div>
   </div>
   <div className="Solution-secondary-container">
       <div className="Solution-mission">
           <h3>Our Mission</h3>
           <p className="OurMission-text">At CODING VISUALS, we believe that every individual and business has untapped potential waiting to be unleashed in the global realm. Our mission is to empower you to not only establish a strong digital presence but also to grow and thrive in the ever-expanding modern landscape. Let us be your trusted partner, guiding you towards success.</p>
       </div>
       
       <div className="SolutionFooterSocialMediaLinks">
       <h3>Connect With Us</h3>
       
   <Link to='https://twitter.com/Coding_Visuals' target="_blank"  className="SolutionTwitter">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg> <span>@Coding_Visuals</span>
   </Link>
   <Link to='https://www.instagram.com/coding_visuals/' target="_blank" className="SolutionInstagram">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg> <span>@coding_visuals</span>


   </Link>
   <Link to='https://www.youtube.com/@CodingVisuals' target="_blank" className="SolutionYoutube">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
     <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg> <span>@CodingVisuals</span>
   </Link>
   <Link to='https://www.linkedin.com/company/99880813/admin/feed/posts/' target="_blank" className="SolutionLinkedin">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
     <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg> <span></span><span>@Coding Visuals</span>
   </Link>
   <Link to='https://www.facebook.com/people/Coding-Visuals/61550245559154/' target="_blank" className="SolutionFB">
   <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
     <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg> <span>@Coding-Visuals</span>
   </Link>
   </div>
       
   </div>
  <div className="ThoughtoFTHEDAY">
   <ThoughtOfTheDay/>
  </div>
 
  <div className="Solution-copyright-section">
   <p>Copyright © 2023 CodingVisuals | AI Powered |
     <Link to="/Home/TermsOfServices">Terms</Link> |
     <Link to="/Home/PrivacyPolicy">Privacy</Link> |
     <Link to="/Home/Shipping-Delivery">Shipping & Delivery</Link> |
     <Link to="/Home/CancellationPolicy">Cancellation</Link>| Image by rawpixel.com on Freepik
   </p>
 </div>
</div>
            <div className="pagination">
                {Array(sectionsCount)
                    .fill()
                    .map((_, idx) => (
                        <span
                            key={idx}
                            className={`pagination-dot ${idx === currentSection ? "active" : ""}`}
                        ></span>
                    ))}
            </div>
        </div>
    );
}

export default BusinessConsultancy;
